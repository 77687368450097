import { Injectable } from '@angular/core';

import { PresetRanges } from 'ng-zorro-antd/date-picker';
import { subDays, startOfDay, endOfDay, startOfMonth, endOfMonth, subMonths } from 'date-fns';


@Injectable({
  providedIn: 'root'
})
export class DatesService {

  constructor() { }

  public get fullDateRanges(): PresetRanges {
    return {
      Today: [new Date(), new Date()],
      Yesterday: [subDays(new Date(), 1), subDays(new Date(), 1)],
      'Last 7 Days': [subDays(new Date(), 6), new Date()],
      'Last 30 Days': [subDays(new Date(), 29), new Date()],
      'This Month': [startOfMonth(new Date()), new Date()],
      'Last Month': [startOfMonth(subMonths(new Date(), 1)), endOfMonth(subMonths(new Date(), 1))],
    };
  }

  public get dateRangesForTrafficAnalyzer(): {
    Today: Date[];
    Yesterday: Date[];
    'Last 7 Days': Date[];
  } {
    return {
      'Today': [new Date(), new Date()],
      'Yesterday': [subDays(new Date(), 1), subDays(new Date(), 1)],
      'Last 7 Days': [subDays(new Date(), 6), new Date()],
    };
  }

  public get dateRangesForAlerts(): {
    Today: Date[];
    Yesterday: Date[];
    'Last 7 Days': Date[];
    'Last 30 Days': Date[];
  } {
    return {
      'Today': [startOfDay(new Date()), endOfDay(new Date())],
      'Yesterday': [startOfDay(subDays(new Date(), 1)), endOfDay(subDays(new Date(), 1))],
      'Last 7 Days': [startOfDay(subDays(new Date(), 6)), endOfDay(new Date())],
      'Last 30 Days': [startOfDay(subDays(new Date(), 29)), endOfDay(new Date())],
    };
  }
}
