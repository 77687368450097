import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { NzSelectModule } from 'ng-zorro-antd/select';

import { ReusableEditableTableComponent } from './reusable-editable-table.component';

@NgModule({
  declarations: [ReusableEditableTableComponent],
  exports: [ReusableEditableTableComponent],
  imports: [
    CommonModule,
    NzTableModule,
    NzButtonModule,
    NzIconModule,
    NzCheckboxModule,
    FormsModule,
    NzSelectModule,
    NzPopconfirmModule
  ]
})
export class ReusableEditableTableModule { }
