import { Component, Input } from '@angular/core';
import { ChartOptions } from '@core/interfaces/chart/chart.interface';
import { ApexAxisChartSeries } from 'ng-apexcharts';

@Component({
  selector: 'app-pie-chart',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.scss']
})
export class PieChartComponent {
  @Input() public set chartOptions(value: Partial<ChartOptions>) {
    this._chartOptions = value;
  }

  public get chartOptions(): Partial<ChartOptions> {
    return this._chartOptions;
  }

  @Input() public set series(value: ApexAxisChartSeries) {
    this._series = value;
  }

  public get series(): ApexAxisChartSeries {
    return this._series;
  }

  private _chartOptions: Partial<ChartOptions>;
  private _series: ApexAxisChartSeries;

}
