import { DspStatisticFilter, ResponsesStatisticFilter } from '../statistic-options';

export interface MainScreenState {
  companies: string[];
  options: string[];
  statistics: string[];
  cookieSync?: string[];
}
export interface StatisticScreenState {
  from: string;
  to: string;
  type: string;
  orderField: string;
  orderDir: string;
  page: number;
  rowsOnPage: number;
  filter: Array<object>;
  groupBy: Array<string>;
  // tableFields: Object;
  filterRaw: object;
}
export interface DSPStatisticScreenState {
  filter: DspStatisticFilter;
  orderField: string;
  orderDir: string;
  page: number;
  perPage: number;
  rowsOnPage: number;
  groupBy: string[];
}
export interface ResponsesStatisticScreenState {
  filter: ResponsesStatisticFilter;
  filterRaw: object;
  page: number;
  perPage: number;
  rowsOnPage: number;
  groupBy: string[];
  from: string;
  to: string;
  maxDate:  string;
  orderDir: string;
  orderField: string;
}
