<div class="alerts" [style.max-height]="drawerMaxHeight">
  <div class="alerts__column column-title">
    <div class="column-title__content content-title">
      <h2 class="content-title__title">{{alertsCount}} Unread Alerts</h2>
      <div class="content-title__button">
        <span (click)="readAllAlerts()">Read all</span>
      </div>
    </div>
  </div>
  <div class="alerts__column column-notification" [style.max-height]="drawerBodyMaxHeight">
    <div class="column-notification__row">
      <div class="column-notification__column column-items" [style.max-height]="drawerContentMaxHeight">
        <div class="column-items__row" *ngFor="let alert of alertsArray" [class.unread]="!alert.is_read">
          <div class="column-items__column column-header">
            <div class="column-header__left left-items">
              <div class="left-items__dot" *ngIf="!alert.is_read">&#x2022;</div>
              <div class="left-items__text">{{alert.type}}</div>
            </div>
            <div class="column-header__right right-items" *ngIf="!alert.is_read">
              <div class="right-items__button" (click)="readAlert(alert.id)">Mark as read</div>
            </div>
          </div>
          <div class="column-items__column column-description">
            <div class="column-description__text">{{alert.name}}</div>
          </div>
        </div>
      </div>
      <div class="column-notification__column column-button">
        <div class="column-button__button">
          <button nz-button nzType="primary" (click)="showAllAlerts()">SHOW ALL</button>
        </div>
      </div>
    </div>
  </div>
</div>