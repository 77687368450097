import { IFilterListsItems, ITressSelectItem } from '../../interfaces/helpers/helpers.interface';

export function rearrangeFilterListItems(inputFilterListArray: IFilterListsItems[], inputIdsArray: number[]) {
  // Create a copy of the inputFilterListArray to avoid modifying the original array
  const outputFilterListArray = inputFilterListArray.map(function (group) {
    // Filter the items in each group to include only those with IDs in inputIdsArray
    const includedItems = group.items.filter(function (item) {
      return inputIdsArray.includes(item.id);
    });

    // Filter the items in each group to exclude those with IDs in inputIdsArray
    const excludedItems = group.items.filter(function (item) {
      return !inputIdsArray.includes(item.id);
    });

    // Concatenate the included items and excluded items to form the new items array
    const newItems = includedItems.concat(excludedItems);

    // Return the updated group with the new items array
    return { ...group, items: newItems };
  });

  return outputFilterListArray;
}

export function rearrangeCompaniesItems(companiesArray: ITressSelectItem[], companiesIdsArray: number[]) {
  const companiesIdsSet = new Set(companiesIdsArray);
  let modifiedCompaniesArray: ITressSelectItem[] = [];
  companiesArray.forEach(company => {
    company.children.sort((a, b) => {
      const aIdInCompaniesIds = companiesIdsSet.has(+a.key);
      const bIdInCompaniesIds = companiesIdsSet.has(+b.key);

      if (aIdInCompaniesIds && !bIdInCompaniesIds) {
        return -1;
      } else if (!aIdInCompaniesIds && bIdInCompaniesIds) {
        return 1;
      } else {
        const aIndex = companiesIdsArray.indexOf(+a.key);
        const bIndex = companiesIdsArray.indexOf(+b.key);

        return aIndex - bIndex;
      }
    });
  });

  modifiedCompaniesArray = companiesArray.sort((a, b) => {
    const aHasMatch = a.children.some((child) => companiesIdsSet.has(+child.key));
    const bHasMatch = b.children.some((child) => companiesIdsSet.has(+child.key));


    if (aHasMatch && !bHasMatch) {
      return -1;
    } else if (!aHasMatch && bHasMatch) {
      return 1;
    } else {
      return 0;
    }
  });

  return modifiedCompaniesArray;
}
