import { FilterListInterface } from '../../interfaces';

export function findDifferentBetweenCompaniesArrays(
  checkedArray: FilterListInterface.CompaniesDataGeneral[],
  initArray: FilterListInterface.CompaniesDataGeneral[]
): number[] {
  const result: number[] = [];

  for (let i = 0; i < checkedArray.length; i++) {
    const item1 = checkedArray[i];
    const item2 = initArray.find((el) => el.id === item1.id);

    if (!item2) {
      continue;
    }

    for (let j = 0; j < item1.endpoints.length; j++) {
      const setting1 = item1.endpoints[j];
      const setting2 = item2.endpoints.find((setting) => setting.id === setting1.id);

      if (setting2 &&
        (setting1.checked !== setting2.checked)) {
        result.push(setting1.id);
      }
    }
  }

  return result;
}

export function findDifferentBetweenEndpointsArrays(
  checkedArray: FilterListInterface.EndpointsGeneral[],
  initArray: FilterListInterface.EndpointsGeneral[]
): number[] {
  const result: number[] = [];

  for (let i = 0; i < checkedArray.length; i++) {
    const item1 = checkedArray[i];
    const item2 = initArray.find((el) => el.id === item1.id);

    if (!item2) {
      continue;
    }

    if (item2 && (item1.checked !== item2.checked)) {
      result.push(item1.id);
    }
  }

  return result;
}