export interface PixalateSettingsRes {
  link?: string;
  selected_macroses?: string[];
  types?: Type[];
}

export interface Type {
  id?: number;
  type?: string;
}

export interface PixalateMacros {
  status?: string;
  macros?: { [key: string]: string };
  keys?: { [key: string]: string };
}

export interface PixalateSettings {
  usePixalate?: number | boolean;
  pixalateSettings: {
    banner?: number | boolean;
    video?: number | boolean;
    daily_scan_imp?: number;
    month_scan_imp?: number;
    banner_tag?: string;
    banner_macros?: string[];
    video_tag?: string;
    video_macros?: string[];
    partnerId?: number;
  }
}


export interface PixalateResponse {
  data: {
    allowed_macroses: { key: string };
    pixalateSettings: {
      banner: number;
      banner_macros: string[];
      banner_tag: string;
      daily_scan_imp: number;
      month_scan_imp: number;
      video: number;
      video_macros: string[];
      video_tag: string;
    },
    usePixalate: boolean;
  }
}