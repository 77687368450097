import { CardBgColor } from '../../constants';

export function filterNormalize(filter: any) {
  const params = Object.assign({}, filter);

  for (const key in params) {
    if (typeof params[key] === 'string' && params[key].includes('All')) {
      delete params[key];
    } else if (params[key] === '' || params[key] === null || params[key] === -1) {
      delete params[key];
    }
  }

  return params;
}

export function padZero(num: number): string {
  return num.toString().padStart(2, '0');
}

export function formatDate(date: Date): string {
  const year = date.getFullYear();
  const month = padZero(date.getMonth() + 1);
  const day = padZero(date.getDate());
  const hours = padZero(date.getHours());
  const minutes = padZero(date.getMinutes());

  return `${year}-${month}-${day} ${hours}:${minutes}`;
}

export function generateCardColor(cardName: string): string {
  return CardBgColor[cardName];
}

export function calculateXAxisSteps(data: any[], intervalMinutes: number): number {
  if (!data || !data.length) {
    return 0;
  }

  const intervalMilliseconds = intervalMinutes * 60 * 1000;
  const dates = data.map(item => new Date(item.date));
  let minDate = new Date(Math.min(...dates.map(date => date.getTime())));
  let maxDate = new Date(Math.max(...dates.map(date => date.getTime())));

  minDate = new Date(Math.floor(minDate.getTime() / intervalMilliseconds) * intervalMilliseconds);
  maxDate = new Date(Math.floor(maxDate.getTime() / intervalMilliseconds) * intervalMilliseconds);

  const steps = Math.ceil((maxDate.getTime() - minDate.getTime()) / intervalMilliseconds);
  return steps;
}

export function calculatePercentages(array: number[]): number[] {
  const sumOfElements = array.reduce((acc, value) => acc + value, 0);

  const percentageArray = array.map((element) => Math.round((element / sumOfElements) * 100));

  return percentageArray;
}

export function updateColor(chartObj: { name: string; value: any; color: string; enabled: boolean; }, colorToChange: string) {
  chartObj.color = colorToChange;
}

export function updateSeriesArray(
  sortedChart: { name: string; value: any; color: string; enabled: boolean; }[],
  chart: { name: string; value: any; color: string; enabled: boolean; }[],
  key: string,
) {
  let outputArray: { name: string; value: any; color: string; enabled: boolean; }[] = [];
  outputArray = chart.map((item) => {
    const matchingObj = sortedChart.find((sortedItem) => item[key] === sortedItem[key]);

    if (matchingObj) {
      updateColor(item, matchingObj.color);
    }

    return item;
  });

  return outputArray;
}