import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MetricCardsComponent } from './metric-cards.component';



@NgModule({
  declarations: [MetricCardsComponent],
  exports: [MetricCardsComponent],
  imports: [
    CommonModule
  ]
})
export class MetricCardsModule { }
