export interface PaginationStateDropped<T> {
  count: number;
  rows: T;
  totals: DroppedRequestsTotal;
}

export interface DroppedRequestsTotal {
  dropped_percent: number;
  dropped_requests: number;
  requests: number;
  valid_requests: number;
}

export interface DroppedRequestResponse {
  adformat_id: number;
  country_id: number;
  dropped_percent: number;
  dropped_requests: string;
  dsp_id: number;
  dsp_name: string;
  endpoint_id: number;
  requests: string;
  ssp_name: string;
  traffic_id: number;
  ts: string;
  valid_requests: string;
  arrow?: string;
  date: string;
  expand?: boolean;
  reasons: FailureReason[];
}

export interface FailureReason {
  adformat_id: number;
  country_id: number;
  dropped_requests: string;
  dsp_id: number;
  dsp_name: string;
  endpoint_id: number;
  failure_reason: string;
  partner_id: number;
  reason_id: number;
  ssp_name: string;
  traffic_id: number;
  ts: string;
  tooltip?: string;
}

export interface DSPEndpointDropped {
  id: number;
  name: string;
  checked: boolean;
  tooltip?: string;
}
export interface SSPEndpointDropped {
  id: number;
  name: string;
  checked: boolean;
  tooltip?: string;
}
export interface DroppedRequestsData {
  ssp: SSPEndpointDropped[];
  dsp: DSPEndpointDropped[];
}

export interface ItemEndpointDropped {
  id: number;
  name: string;
  checked: boolean;
  tooltip?: string;
  active?: boolean;
}

export interface ItemListDropped {
  value: string;
  id: string;
}

export interface FiltersData {
  adFormats: AdFormats[];
  bidCodes: BidCodes;
  countries: Countries[];
  trafficTypes: TrafficTypes[];
}

export interface AdFormats {
  id: number;
  name: string;
}
export interface BidCodes {
  failureReasons: FailureReasons[];
  vastErrors: VastErrors[];
}

export interface FailureReasons {
  id: number;
  description?: string;
  name: string;
}

export interface VastErrors {
  id: number;
  description?: string;
  name: string;
}
export interface Countries {
  id: number;
  alpha_3?: string;
  name: string;
}

export interface TrafficTypes {
  id: number;
  name: string;
}

export interface ActiveColumns { attr: string, label: string, active: boolean, disabled?: boolean }
