export interface DashboardData {
  ssp_name: string;
  dsp_name: string;
  ad_format: string;
  traffic_type: string;
  event_name: string;
  event_requests: string;
  event_occurred: string,
  event_ratio: number;
  event: number;
}

export interface Endpoints {
  value: number;
  label: string;
  checked: boolean;
  tooltip?: string;
  active?: number;
  name?: string;
  id: number;
  disabled: boolean;
}

export interface Companies {
  id: number;
  name: string;
  value: number;
  label: string;
  checked: boolean;
  indeterminate: boolean;
  disabled: boolean;
  endpoints: {
    value: number;
    label: string;
    checked: boolean;
    tooltip?: string;
    active?: number;
    name?: string;
    id: number;
    disabled: boolean;
  }[]
}

export interface Rules {
  value: number;
  label: string;
  checked: boolean;
  tooltip?: string;
  active?: number;
  name?: string;
  id: number;
  disabled: boolean;
}

export interface Filters {
  adFormats: AdFormat[];
  trafficTypes: TrafficType[];
  events: Event[];
}

export interface DashboardReqData {
  period: {
    from: Period;
    to: Period;
  };
  sortField: string;
  sortBy: string;
  limit: number;
  offset: number;
  filter: Partial<Filter>;
}

export interface DashboardDownloadReqData {
  period: {
    from: Period;
    to: Period;
  };
  sortField: string;
  sortBy: string;
  filter: DashboardDownloadFilterData;
}

export interface DashboardDownloadFilterData {
  ssp_id: string;
  dsp_id: string;
  event: number;
  platform_id: number;
  traffic_id: number;
}

export interface DashboardResData {
  data: {
    original: {
      columns: TableColumns[];
      count: number;
      data: DashboardData[];
      pageCount: number;
      perPage: number;
    }
  }
}

export interface Filter {
  ssp_id: number;
  dsp_id: number;
  event: number;
  platform_id: number;
  traffic_id: number;
}

export interface RuleManagerRes {
  data: RuleManagerData[];
  links: RuleManagerResLinks;
  meta: RuleManagerResMeta;
}

export interface RuleManagerData {
  id: number;
  name: string;
  rules: number[];
  ssp: EndpointRes[];
  dsp: EndpointRes[],
  is_all_ssp: number;
  is_all_dsp: number;
  ssp_count: number,
  dsp_count: number;
  created_at: string;
  updated_at: string;
}

export interface RuleManagerReqData {
  ssp: number;
  dsp: number;
  event: number;
  search: string | number;
  page: number;
  rows: number;
  field: string;
  sortBy: string;
}

export interface NewRuleFormValue {
  name: string;
  rules: number[];
  ssp: number[];
  dsp: number[],
  is_all_ssp: boolean,
  is_all_dsp: boolean,
  has_grouped_dsp: number,
  has_grouped_ssp: number
}

export interface NewRuleReqData {
  name: string;
  rules: number[];
  ssp: number[];
  dsp: number[];
  is_all_ssp: number;
  is_all_dsp: number;
  has_grouped_dsp: number;
  has_grouped_ssp: number;
}

export interface RuleManagerResLinks {
  first: string;
  last: string;
  next: string;
  prev: string;
}
export interface RuleManagerResMeta {
  current_page: number;
  from: number;
  last_page: number;
  link: MetaLink[];
  path: string;
  per_page: number;
  to: number;
  total: number;
}

export interface RuleRes {
  data: Rule;
}

export interface Rule {
  id: number;
  name: string;
  rules: number[];
  ssp: number[] | EndpointRes[];
  dsp: number[] | EndpointRes[];
  is_all_ssp: number;
  is_all_dsp: number;
  ssp_count: number;
  dsp_count: number;
  created_at: number;
  updated_at: number;
  has_grouped_dsp: number;
  has_grouped_ssp: number;
}

export interface EmittedDataForDelete {
  ruleId: number;
  reqBody: ReqBodyForDelete;
}

export interface ReqBodyForDelete {
  name: string;
  is_all_dsp: number;
  is_all_ssp: number;
  rules: number[];
  ssp: number[];
  dsp: number[];
}

export type Period = string | Date | number;

export type TableColumns = { name: string; key: string };

export type SortData = { sortField: string, sortBy: string };

export type AdFormat = { id: number; name: string; };

export type TrafficType = { id: number; name: string; };

export type Event = { id: number; name: string; desc?: string };

export type EndpointRes = { id: number; name: string };

export type MetaLink = { url: string; label: string; active: boolean };