import { Pipe, PipeTransform } from '@angular/core';

import { FilterListInterface } from '@core/interfaces';

@Pipe({
  name: 'search',
})
export class SearchPipe implements PipeTransform {

  transform(endpoints: FilterListInterface.EndpointsGeneral[], search = ''): FilterListInterface.EndpointsGeneral[] {
    if (!search.trim()) {
      return endpoints;
    }
    return endpoints.filter(endpoint => {
      return (endpoint.name?.toLowerCase().includes(search.toLowerCase()) || endpoint.id.toString().toLowerCase().includes(search.toLowerCase()));
    });
  }
}
