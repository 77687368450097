import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  ChangeDetectorRef,
  EventEmitter,
  Output,
  ChangeDetectionStrategy,
  OnDestroy
} from '@angular/core';

import { DataManagerService, ErrorsHandlingService, NotificationService } from '@core/services';
import { NotificationComponent } from '../notification';
@Component({
  selector: 'app-check-endpoint-status',
  templateUrl: './check-endpoint-status.component.html',
  styleUrls: ['./check-endpoint-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckEndpointStatusComponent implements OnChanges, OnDestroy {
  @Input() apilink: string;
  @Input() side: string;
  @Input() endpointValidationStatuses: { status: string, label: string }[] = [];
  @Input() endpointId: number;
  @Output() hasError = new EventEmitter<boolean>();

  showEndpointValidationStatuses = false;
  showNotifySupport = true;

  constructor(
    private dataManager: DataManagerService,
    private cdr: ChangeDetectorRef,
    private notificationService: NotificationService,
    private notificationComponent: NotificationComponent,
    private readonly errorsHandlingService: ErrorsHandlingService,
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.apilink && !changes.apilink.firstChange && this.apilink.length > 0) {
      this.showEndpointValidationStatuses = true;
      this.validateLink(this.apilink);
    } else {
      this.showEndpointValidationStatuses = false;
      for (let i = 0; i < this.endpointValidationStatuses.length; i++) {
        this.endpointValidationStatuses[i].status = 'none';
      }
    }
    if (changes.endpointValidationStatuses) {
      this.showEndpointValidationStatuses = this.endpointValidationStatuses.some((item) => {
        return item.status === 'error';
      });
    }
  }

  validateLink(link: string): void {
    for (let i = 0; i < this.endpointValidationStatuses.length; i++) {
      this.endpointValidationStatuses[i].status = 'pending';
    }

    this.dataManager.validateApiLinkUnique(link, this.side, this.endpointId)
      .subscribe(() => {
        this.endpointValidationStatuses[0].status = 'done';
        this.cdr.markForCheck();
      },
        error => {
          this.errorsHandlingService.handleError(error);
          this.dataManager.apiLinkErrors.push(error.messages);
          this.endpointValidationStatuses[0].status = 'error';
          this.hasError.emit(true);
          this.cdr.markForCheck();
        });
    this.dataManager.validateApiLinkAsUrl(link)
      .subscribe(() => {
        this.endpointValidationStatuses[1].status = 'done';
        this.cdr.markForCheck();
      },
        error => {
          this.errorsHandlingService.handleError(error);
          this.dataManager.apiLinkErrors.push(error.messages);
          this.endpointValidationStatuses[1].status = 'error';
          this.hasError.emit(true);
          this.cdr.markForCheck();
        });
    this.dataManager.validateApiLinkContainsMacros(link)
      .subscribe(() => {
        this.endpointValidationStatuses[2].status = 'done';
        this.cdr.markForCheck();
      },
        error => {
          this.errorsHandlingService.handleError(error);
          this.dataManager.apiLinkErrors.push(error.messages);
          this.endpointValidationStatuses[2].status = 'error';
          this.hasError.emit(true);
          this.cdr.markForCheck();
        });
    this.dataManager.validateApiLinkStatus200(link)
      .subscribe(() => {
        this.endpointValidationStatuses[3].status = 'done';
        this.cdr.markForCheck();
      },
        error => {
          this.errorsHandlingService.handleError(error);
          this.dataManager.apiLinkErrors.push(error.messages);
          this.endpointValidationStatuses[3].status = 'error';
          this.hasError.emit(true);
          this.cdr.markForCheck();
        });
  }

  ngOnDestroy(): void {
    this.endpointValidationStatuses = [
      { status: 'none', label: 'Unique link' },
      { status: 'none', label: 'URL structure' },
      { status: 'none', label: 'Contains data Macro' },
      { status: 'none', label: 'Test response is correct ' }
    ];
  }

  notifySupport(link: string): void {
    this.dataManager.notifyAboutApiLink(link, this.side)
      .subscribe(result => {
        this.showNotification('success', 'success', 'success', result.message);
        this.showNotifySupport = false;
        this.cdr.markForCheck();
      },
        error => this.errorsHandlingService.handleError(error));
  }

  private showNotification(
    iconType: string,
    color: string,
    notificationType: string,
    description: string,
  ): void {
    const data = {
      iconType,
      color,
      notificationType,
      description,
    };

    this.notificationComponent.data = data;
    this.notificationService.showTemplate({ nzData: data });
  }
}
