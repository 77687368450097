/* eslint-disable max-len */
export const macrosListScriptWeb: { id: string, name: string, desc: string }[] = [
  { id: '[IP]', name: '[IP]', desc: 'IPv4 address closest to device.' },
  { id: '[UA]', name: '[UA]', desc: 'Browser user agent string.' },
  { id: '[DOMAIN]', name: '[DOMAIN]', desc: 'Domain of the site (e.g., ""). Mandatory for Web traffic.' },
  {
    id: '[GDPR_CONSENT]',
    name: '[GDPR_CONSENT]',
    desc: 'GDPR regulation user consent (optional) Highly recommended for GDPR region'
  },
  {
    id: '[CCPA_CONSENT]',
    name: '[CCPA_CONSENT]',
    desc: 'CCPA regulation user consent (optional) Highly recommended for California region'
  },
  {
    id: '[LOCATION_LAT]',
    name: '[LOCATION_LAT]',
    desc: 'Latitude from -90.0 to +90.0, where negative is south. If empty default - ip location'
  },
  {
    id: '[LOCATION_LON]',
    name: '[LOCATION_LON]',
    desc: 'Longitude from -180.0 to +180.0, where negative is west. If empty default - ip location'
  },
  { id: '[WIDTH]', name: '[WIDTH]', desc: 'ADM width' },
  { id: '[HEIGHT]', name: '[HEIGHT]', desc: 'ADM height' },
  { id: '[DEVICE_W]', name: '[DEVICE_W]', desc: 'Device width (optional)' },
  { id: '[DEVICE_H]', name: '[DEVICE_H]', desc: 'Device height (optional)' },
  { id: '[BIDFLOOR]', name: '[BIDFLOOR]', desc: 'Bidfloor (optional) if empty default - SSP config bidfloor' },
  {
    id: '[PAGE_URL]',
    name: '[PAGE_URL]',
    desc: 'URL of the page where the impression will be shown. Mandatory for Web traffic.'
  },
  { id: '[CATEGORY]', name: '[CATEGORY]', desc: 'IAB site content categories.' },
  {
    id: '[COPPA]',
    name: '[COPPA]',
    desc: 'A flag indicating traffic that is subject to the Children\'s Online Privacy Protection Act of the United States'
  },
  {
    id: '[GDPR]',
    name: '[GDPR]',
    desc: `The parameter states the fact of GDPR compliance of the source. 
    If the parameter gdpr is not present or the value is not valid the server will check the GDPR compliance nevertheless. 
    The server will do a lookup of the users IP address to see if user is from EU or not.`
  },
  { id: '[CB]', name: '[CB]', desc: 'Random number to prevent caching' },
  {
    id: '[DNT]',
    name: '[DNT]',
    desc: 'Standard "Do Not Track" flag as set in the header by the browser, where 0 = tracking is unrestricted, 1 = do not track'
  },
  { id: '[SCHAIN]', name: '[SCHAIN]', desc: 'Supply Chain' },
  { id: '[USER_ID]', name: '[USER_ID]', desc: 'The user id (cookie id) in the supplier\'s system' },
  {
    id: '[DESCRIPTION_URL]',
    name: '[DESCRIPTION_URL]',
    desc: 'This macro should be used for Google Ad Manager DSP and Web traffic only. This parameter states the page with the video player, but could also be a page with text that describes the video.e.g URL-encoded: description_url=http%3A%2F%2Fchapmanganato.com'
  },
  { id: '[APP_VERSION]', name: '[APP_VERSION]', desc: 'Application version' },
  { id: '[BUYER_UID]', name: '[BUYER_UID]', desc: 'Buyer-specific ID (cookie id) for the user as mapped by the exchange for the buyer.' },
  { id: '[IFA_TYPE]', name: '[IFA_TYPE]', desc: 'The types of IFA are Device, Publisher (including apps), SSP and Session.' },
  { id: '[LANGUAGE]', name: '[LANGUAGE]', desc: 'Content language using ISO-639-1-alpha-2.' },
  { id: '[PLACEMENT_ID]', name: '[PLACEMENT_ID]', desc: 'Identifier for specific ad placement or ad tag that was used to initiate the auction' },
  { id: '[TITLE]', name: '[TITLE]', desc: 'Content title.' },
  { id: '[GPP]', name: '[GPP]', desc: 'Global Privacy Platform String' },
  { id: '[MIMES]', name: '[MIMES]', desc: 'Content MIME types supported' },
];

export const macrosListApp: { id: string, name: string, desc: string }[] = [
  {
    id: '[APP_BUNDLE]',
    name: '[APP_BUNDLE]',
    desc: `A platform-specific application identifier intended to be unique to the app and independent of the exchange. 
    On Android, this should be a bundle or package name (e.g.,com.foo.mygame).
    On iOS, it is typically a numeric ID. Mandatory for InApp traffic.`
  },
  { id: '[APP_NAME]', name: '[APP_NAME]', desc: 'App name' },
  {
    id: '[IFA]',
    name: '[IFA]',
    desc: 'ID sanctioned for advertiser use in the clear (i.e., not hashed). Mandatory for InApp traffic.'
  },
  { id: '[IP]', name: '[IP]', desc: 'IPv4 address closest to device.' },
  { id: '[UA]', name: '[UA]', desc: 'Browser user agent string.' },
  {
    id: '[GDPR_CONSENT]',
    name: '[GDPR_CONSENT]',
    desc: 'GDPR regulation user consent (optional) Highly recommended for GDPR region'
  },
  {
    id: '[CCPA_CONSENT]',
    name: '[CCPA_CONSENT]',
    desc: 'CCPA regulation user consent (optional) Highly recommended for California region'
  },
  {
    id: '[LOCATION_LAT]',
    name: '[LOCATION_LAT]',
    desc: 'Latitude from -90.0 to +90.0, where negative is south. If empty default - ip location'
  },
  {
    id: '[LOCATION_LON]',
    name: '[LOCATION_LON]',
    desc: 'Longitude from -180.0 to +180.0, where negative is west. If empty default - ip location'
  },
  { id: '[WIDTH]', name: '[WIDTH]', desc: 'ADM width' },
  { id: '[HEIGHT]', name: '[HEIGHT]', desc: 'ADM height' },
  { id: '[DEVICE_W]', name: '[DEVICE_W]', desc: 'Device width (optional)' },
  { id: '[DEVICE_H]', name: '[DEVICE_H]', desc: 'Device height (optional)' },
  { id: '[BIDFLOOR]', name: '[BIDFLOOR]', desc: 'Bidfloor (optional) if empty default - SSP config bidfloor' },
  { id: '[STORE_URL]', name: '[STORE_URL]', desc: 'App store URL for an installed app' },
  { id: '[CATEGORY]', name: '[CATEGORY]', desc: 'IAB site content categories.' },
  {
    id: '[COPPA]',
    name: '[COPPA]',
    desc: 'A flag indicating traffic that is subject to the Children\'s Online Privacy Protection Act of the United States'
  },
  {
    id: '[GDPR]',
    name: '[GDPR]',
    desc: `The parameter states the fact of GDPR compliance of the source. 
    If the parameter gdpr is not present or the value is not valid the server will check the GDPR compliance nevertheless. 
    The server will do a lookup of the users IP address to see if user is from EU or not.` },
  { id: '[CB]', name: '[CB]', desc: 'Random number to prevent caching' },
  {
    id: '[DNT]',
    name: '[DNT]',
    desc: 'Standard "Do Not Track" flag as set in the header by the browser, where 0 = tracking is unrestricted, 1 = do not track'
  },
  { id: '[SCHAIN]', name: '[SCHAIN]', desc: 'Supply Chain' },
  { id: '[USER_ID]', name: '[USER_ID]', desc: 'The user id (cookie id) in the supplier\'s system' },
  {
    id: '[DESCRIPTION_URL]',
    name: '[DESCRIPTION_URL]',
    desc: 'This macro should be used for Google Ad Manager DSP and Web traffic only. This parameter states the page with the video player, but could also be a page with text that describes the video.e.g URL-encoded: description_url=http%3A%2F%2Fchapmanganato.com'
  },
  { id: '[APP_VERSION]', name: '[APP_VERSION]', desc: 'Application version' },
  { id: '[BUYER_UID]', name: '[BUYER_UID]', desc: 'Buyer-specific ID (cookie id) for the user as mapped by the exchange for the buyer.' },
  { id: '[IFA_TYPE]', name: '[IFA_TYPE]', desc: 'The types of IFA are Device, Publisher (including apps), SSP and Session.' },
  { id: '[LANGUAGE]', name: '[LANGUAGE]', desc: 'Content language using ISO-639-1-alpha-2.' },
  { id: '[PLACEMENT_ID]', name: '[PLACEMENT_ID]', desc: 'Identifier for specific ad placement or ad tag that was used to initiate the auction' },
  { id: '[TITLE]', name: '[TITLE]', desc: 'Content title.' },
  { id: '[GPP]', name: '[GPP]', desc: 'Global Privacy Platform String' },
  { id: '[MIMES]', name: '[MIMES]', desc: 'Content MIME types supported' },
];

export const macrosListServerWeb: { id: string, name: string, desc: string }[] = [
  { id: '[IP]', name: '[IP]', desc: 'IPv4 address closest to device.' },
  { id: '[UA]', name: '[UA]', desc: 'Browser user agent string.' },
  { id: '[DOMAIN]', name: '[DOMAIN]', desc: 'Domain of the site (e.g., ""). Mandatory for Web traffic.' },
  {
    id: '[GDPR_CONSENT]',
    name: '[GDPR_CONSENT]',
    desc: 'GDPR regulation user consent (optional) Highly recommended for GDPR region'
  },
  {
    id: '[CCPA_CONSENT]',
    name: '[CCPA_CONSENT]',
    desc: 'CCPA regulation user consent (optional) Highly recommended for California region'
  },
  {
    id: '[LOCATION_LAT]',
    name: '[LOCATION_LAT]',
    desc: 'Latitude from -90.0 to +90.0, where negative is south. If empty default - ip location'
  },
  {
    id: '[LOCATION_LON]',
    name: '[LOCATION_LON]',
    desc: 'Longitude from -180.0 to +180.0, where negative is west. If empty default - ip location'
  },
  { id: '[WIDTH]', name: '[WIDTH]', desc: 'ADM width' },
  { id: '[HEIGHT]', name: '[HEIGHT]', desc: 'ADM height' },
  { id: '[DEVICE_W]', name: '[DEVICE_W]', desc: 'Device width (optional)' },
  { id: '[DEVICE_H]', name: '[DEVICE_H]', desc: 'Device height (optional)' },
  { id: '[BIDFLOOR]', name: '[BIDFLOOR]', desc: 'Bidfloor (optional) if empty default - SSP config bidfloor' },
  {
    id: '[USER_ID]',
    name: '[USER_ID]',
    desc: 'The user id (cookie id) in the supplier\'s system'
  },
  {
    id: '[PAGE_URL]',
    name: '[PAGE_URL]',
    desc: 'URL of the page where the impression will be shown. Mandatory for Web traffic.'
  },
  { id: '[CATEGORY]', name: '[CATEGORY]', desc: 'IAB site content categories.' },
  {
    id: '[COPPA]',
    name: '[COPPA]',
    desc: 'A flag indicating traffic that is subject to the Children\'s Online Privacy Protection Act of the United States'
  },
  {
    id: '[GDPR]',
    name: '[GDPR]',
    desc: `The parameter states the fact of GDPR compliance of the source. 
    If the parameter gdpr is not present or the value is not valid the server will check the GDPR compliance nevertheless. 
    The server will do a lookup of the users IP address to see if user is from EU or not.` },
  { id: '[CB]', name: '[CB]', desc: 'Random number to prevent caching' },
  {
    id: '[DNT]',
    name: '[DNT]',
    desc: 'Standard "Do Not Track" flag as set in the header by the browser, where 0 = tracking is unrestricted, 1 = do not track'
  },
  { id: '[SCHAIN]', name: '[SCHAIN]', desc: 'Supply Chain' },
  { id: '[USER_ID]', name: '[USER_ID]', desc: 'The user id (cookie id) in the supplier\'s system' },
  {
    id: '[DESCRIPTION_URL]',
    name: '[DESCRIPTION_URL]',
    desc: 'This macro should be used for Google Ad Manager DSP and Web traffic only. This parameter states the page with the video player, but could also be a page with text that describes the video.e.g URL-encoded: description_url=http%3A%2F%2Fchapmanganato.com'
  },
  { id: '[APP_VERSION]', name: '[APP_VERSION]', desc: 'Application version' },
  { id: '[BUYER_UID]', name: '[BUYER_UID]', desc: 'Buyer-specific ID (cookie id) for the user as mapped by the exchange for the buyer.' },
  { id: '[IFA_TYPE]', name: '[IFA_TYPE]', desc: 'The types of IFA are Device, Publisher (including apps), SSP and Session.' },
  { id: '[LANGUAGE]', name: '[LANGUAGE]', desc: 'Content language using ISO-639-1-alpha-2.' },
  { id: '[PLACEMENT_ID]', name: '[PLACEMENT_ID]', desc: 'Identifier for specific ad placement or ad tag that was used to initiate the auction' },
  { id: '[TITLE]', name: '[TITLE]', desc: 'Content title.' },
  { id: '[GPP]', name: '[GPP]', desc: 'Global Privacy Platform String' },
  { id: '[MIMES]', name: '[MIMES]', desc: 'Content MIME types supported' },
];

export const macrosListGoogleAds: { id: string, name: string, desc: string }[] = [
  { id: '%%SITE%%', name: '%%SITE%%', desc: '' },
  { id: '%%PATTERN:url%%', name: '%%PATTERN:url%%', desc: '' },
  { id: '%%CLICK_URL_ESC%%', name: '%%CLICK_URL_ESC%%', desc: '' },
  { id: '%%WIDTH%%', name: '%%WIDTH%%', desc: '' },
  { id: '%%HEIGHT%%', name: '%%HEIGHT%%', desc: '' },
];

export const macrosListMobup: { id: string, name: string, desc: string }[] = [
  { id: '%%SIZES%%', name: '%%SIZES%%', desc: '' },
  { id: '%%BUNDLE%%', name: '%%BUNDLE%%', desc: '' },
  { id: '%%ADVERTISING_ID_IFA%%', name: '%%ADVERTISING_ID_IFA%%', desc: '' },
  { id: '%%ADVERTISING_ID_IFV%%', name: '%%ADVERTISING_ID_IFV%%', desc: '' },
  { id: '%%LATITUDE%%', name: '%%LATITUDE%%', desc: '' },
  { id: '%%LONGITUDE%%', name: '%%LONGITUDE%%', desc: '' },
  { id: '2', name: '2', desc: '' },
  { id: '%%DNT%%', name: '%%DNT%%', desc: '' },
  { id: '%%CACHEBUSTER%%', name: '%%CACHEBUSTER%%', desc: '' },
];

export const jsTagTypes = [
  { id: 'web', name: 'Server&Client HTML Web' },
  { id: 'app', name: 'Server&Client HTML InApp' },
  { id: 'direct', name: 'Direct Web Page' },
  { id: 'serverWeb', name: 'Server&Server Web' },
  { id: 'serverApp', name: 'Server&Server InApp' },
  { id: 'googleAd', name: 'Google Ad Manager(DFP)' },
  { id: 'mobup', name: 'Mopub (Applovin)' }
];

export const urlAttrsSSP = {
  '[APP_BUNDLE]': 'bundle',
  '[APP_NAME]': 'app_name',
  '[IFA]': 'ifa',
  '[IP]': 'ip',
  '[UA]': 'ua',
  '[DOMAIN]': 'domain',
  '[GDPR_CONSENT]': 'gdpr_consent',
  '[CCPA_CONSENT]': 'CCPA',
  '[LOCATION_LAT]': 'lat',
  '[LOCATION_LON]': 'lon',
  '[WIDTH]': 'width',
  '[HEIGHT]': 'height',
  '[DEVICE_W]': 'dw',
  '[DEVICE_H]': 'dh',
  '[BIDFLOOR]': 'bidfloor',
  '[STORE_URL]': 'store_url',
  '[PAGE_URL]': 'page_url',
  '[CATEGORY]': 'cat',
  '[COPPA]': 'coppa',
  '[GDPR]': 'gdpr',
  '[CB]': 'cb',
  '[DNT]': 'dnt',
  '[SCHAIN]': 'schain',
  '[USER_ID]': 'user_id',
  '[DESCRIPTION_URL]': 'description_url',
  '[APP_VERSION]': 'app_version',
  '[BUYER_UID]': 'buyer_uid',
  '[IFA_TYPE]': 'ifa_type',
  '[LANGUAGE]': 'language',
  '[PLACEMENT_ID]': 'placement_id',
  '[TITLE]': 'title',
  '[GPP]': 'gpp',
  '[MIMES]': 'mimes',
  '%%SITE%%': '&domain',
  '%%PATTERN:url%%': '&page',
  '%%CLICK_URL_ESC%%': '&clickurl',
  '%%WIDTH%%': '&w',
  '%%HEIGHT%%': '&h',
  '%%SIZES%%': 'sizes',
  '%%BUNDLE%%': 'bundle',
  '%%ADVERTISING_ID_IFA%%': 'ifa',
  '%%ADVERTISING_ID_IFV%%': 'ifv',
  '%%LATITUDE%%': 'lat',
  '%%LONGITUDE%%': 'lon',
  '2': 'lltype',
  '%%DNT%%': 'dnt',
  '%%CACHEBUSTER%%': 'cb',
};