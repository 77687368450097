import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'bts',
})
export class BtsPipe implements PipeTransform {

  transform(value: any): string {

    if (!!value) {
      return '+';
    }
    return '-';
  }

}
