import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { ReportTableData } from '../../interfaces';

@Injectable({
  providedIn: 'root',
})
export class ReportsPresetsService {

  constructor() { }


  getReportData(): Observable<{ data: ReportTableData[] }> {
    return of({
      data: [
        {
          date: '2018/04/27',
          name: 'Company2',
          endpoint_name: 'a4g.com',
          impressions: '658 734 325',
          app_bundle: 1149921086,
          domain: 'domain.com',
          country: 'USA',
          ssp: 'wazimo_US_EAST_wazimo_native',
          ssp_spend: 'wazimo_US_EAST_wazimo_native',
        },
        {
          date: '2018/04/27',
          name: 'Company2',
          endpoint_name: 'a4g.com',
          impressions: '658 734 325',
          app_bundle: 1149921086,
          domain: 'domain.com',
          country: 'USA',
          ssp: 'wazimo_US_EAST_wazimo_native',
          ssp_spend: 'wazimo_US_EAST_wazimo_native',
        },
        {
          date: '2018/04/27',
          name: 'Company2',
          endpoint_name: 'a4g.com',
          impressions: '658 734 325',
          app_bundle: 1149921086,
          domain: 'domain.com',
          country: 'USA',
          ssp: 'wazimo_US_EAST_wazimo_native',
          ssp_spend: 'wazimo_US_EAST_wazimo_native',
        },
        {
          date: '2018/04/27',
          name: 'Company2',
          endpoint_name: 'a4g.com',
          impressions: '658 734 325',
          app_bundle: 1149921086,
          domain: 'domain.com',
          country: 'USA',
          ssp: 'wazimo_US_EAST_wazimo_native',
          ssp_spend: 'wazimo_US_EAST_wazimo_native',
        },
        {
          date: '2018/04/27',
          name: 'Company2',
          endpoint_name: 'a4g.com',
          impressions: '658 734 325',
          app_bundle: 1149921086,
          domain: 'domain.com',
          country: 'USA',
          ssp: 'wazimo_US_EAST_wazimo_native',
          ssp_spend: 'wazimo_US_EAST_wazimo_native',
        },
        {
          date: '2018/04/27',
          name: 'Company2',
          endpoint_name: 'a4g.com',
          impressions: '658 734 325',
          app_bundle: 1149921086,
          domain: 'domain.com',
          country: 'USA',
          ssp: 'wazimo_US_EAST_wazimo_native',
          ssp_spend: 'wazimo_US_EAST_wazimo_native',
        },
      ],
    });
  }
}
