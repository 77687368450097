import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NgScrollbarModule } from 'ngx-scrollbar';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzIconModule } from 'ng-zorro-antd/icon';

import { PieChartLegendComponent } from './pie-chart-legend.component';

@NgModule({
  declarations: [PieChartLegendComponent],
  exports: [PieChartLegendComponent],
  imports: [
    CommonModule,
    NgScrollbarModule,
    NzTableModule,
    NzIconModule,
  ]
})
export class PieChartLegendModule { }
