import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DomainValidDirective } from './domain-valid.directive';

@NgModule({
  declarations: [DomainValidDirective],
  exports: [DomainValidDirective],
  imports: [
    CommonModule
  ]
})
export class DomainValidModule { }
