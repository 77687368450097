export class XmlReportModel {
  date: number;
  sspName: number;
  dspName: number;
  sspSpend: number;
  dspSpend: number;
  geo: string;
  requestSentToDSP: number;
  responses: number;
  all_impression: number;
  iconImpCount: number;
  imageImpCount: number;
  all_clicks: number;
  iconClickCount: number;
  imageClickCount: number;
  ctr: number;
  profit: number;
  errorCount: any;
  sspId: number;
  dspResCount: number;
  requestsCount: number;
}
