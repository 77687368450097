export interface AppMainActivityOptions {
  ad_formats: { id: string, name: string }[];
  ad_formats_dsp: { id: string, name: string }[];
  ssp: { id: string, name: string }[];
  dsp: { id: string, name: string }[];
  sspConnections: { id: number, name: string }[];
  dspConnections: { id: number, name: string }[];
  sspSelected: string;
  dspSelected: string;
  sspSelectedFormats: string;
  dspSelectedFormats: string;
  sspSelectedConnection: number;
  dspSelectedConnection: number;
}
