import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzTableModule } from 'ng-zorro-antd/table';

import { AccessLimitationDialogComponent } from './access-limitation-dialog.component';
import { NzModalModule } from "ng-zorro-antd/modal";

@NgModule({
  declarations: [AccessLimitationDialogComponent],
  exports: [AccessLimitationDialogComponent],
  imports: [
    CommonModule,
    NzButtonModule,
    NzTableModule,
    NzModalModule,
  ]
})
export class AccessLimitationDialogModule { }
