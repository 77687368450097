export class BlacklistItemModel {
  resource: string;
}

export class BacklistResponse {
  current_page: number;
  data: Array<BlacklistItemModel>;
  first_page_url: string | null;
  from: number;
  last_page: number;
  last_page_url: string | null;
  next_page_url: string | null;
  path: string | null;
  per_page: number;
  prev_page_url: string | null;
  to: number;
  total = 0;
}
