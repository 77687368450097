import { subDays, startOfMonth, subMonths, endOfMonth } from 'date-fns';

import { PickerInterface } from '../models';
import { DatePickerRanges } from '../enum';

export const PICKER_DATE_RANGES: PickerInterface.DateRanges = {
  Today: [new Date(), new Date()],
  Yesterday: [subDays(new Date(), 1), subDays(new Date(), 1)],
  'Last 7 Days': [subDays(new Date(), 6), new Date()],
  'Last 30 Days': [subDays(new Date(), 29), new Date()],
  'This Month': [startOfMonth(new Date()), new Date()],
  'Last Month': [startOfMonth(subMonths(new Date(), 1)), endOfMonth(subMonths(new Date(), 1))],
};

export const DATES_LABELS: Record<DatePickerRanges, string> = {
  today: 'Today',
  yesterday: 'Yesterday',
  last_7_days: 'Last 7 Days',
  last_30_days: 'Last 30 Days',
  this_month: 'This Month',
  last_month: 'Last Month',
};
