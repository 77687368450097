import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { AppOptions, MainOptionActivity, StatisticOptions, MainLists } from '../../interfaces';
import { environment } from '@environment/environment';

@Injectable(
  { providedIn: 'root' }
)
export class AppOptionsService {
  private storageName = 'app-options';
  private storageMainActivity = 'app-main-activity-options';
  private statOptionsStorageName = 'app-stat-options';
  private DSPSSPStatOptionsStorageName = 'dsp-statistic-screen-storage';
  private optionsSource = new BehaviorSubject<AppOptions>(this.getOptions());
  currentOptions = this.optionsSource.asObservable();

  constructor() {
  }

  getNurlOptions(): any[] {
    return [
      { id: 0, name: 'On win' },
      { id: 1, name: 'On impression' },
    ];
  }

  getOptionsMainActivity() {
    const defaultOptions = {
      ssp: [
        { id: '-1', name: 'Show All' },
        { id: '1', name: 'Enabled' },
        { id: '0', name: 'Disabled' },
        { id: '2', name: 'Deleted' },
      ],
      dsp: [
        { id: '-1', name: 'Show All' },
        { id: '1', name: 'Enabled' },
        { id: '0', name: 'Disabled' },
        { id: '2', name: 'Deleted' },
      ],
      sspConnections: [
        { id: -1, name: 'All Types' },
        { id: 0, name: 'RTB' },
        { id: 1, name: 'VAST' },
        { id: 2, name: 'XML' },
      ],
      dspConnections: [
        { id: -1, name: 'All Types' },
        { id: 0, name: 'RTB' },
        { id: 1, name: 'VAST' },
        { id: 2, name: 'XML' },
      ],
      ad_formats: [
        { id: '-1', name: 'All Ad formats' },
        { id: 'isBanner', name: 'Banner' },
        { id: 'isNative', name: 'Native' },
        { id: 'isPop', name: 'Pop' },
        { id: 'isVideo', name: 'Video' },
        { id: 'isPush', name: 'Push' },
        { id: 'isAudio', name: 'Audio' }
      ],
      ad_formats_dsp: [
        { id: '-1', name: 'All Ad formats' },
        { id: 'banner', name: 'Banner' },
        { id: 'native', name: 'Native' },
        { id: 'pop', name: 'Pop' },
        { id: 'video', name: 'Video' },
        { id: 'push', name: 'Push' },
        { id: 'audio', name: 'Audio' }
      ],
      sspSelected: '1',
      dspSelected: '1',
      sspSelectedConnection: -1,
      dspSelectedConnection: -1,
      sspSelectedFormats: '-1',
      dspSelectedFormats: '-1',
    };

    const options = localStorage.getItem(this.storageMainActivity);
    if (!options) {
      localStorage.setItem(this.storageMainActivity, JSON.stringify(defaultOptions));
      return defaultOptions;
    }

    try {
      return JSON.parse(options);
    } catch (e) {
      return defaultOptions;
    }
  }

  getOptions(): any {
    const defaultOptions: any = {
      ssp: [
        { attr: 'type', label: 'Type', tooltip: 'Endpoint type: RTB endpoint, or VAST, or XML.', active: true },
        { attr: 'formats', label: 'Ad formats', tooltip: 'Ad formats', active: true },
        {
          attr: 'incoming_qps', label: 'Incoming QPS',
          tooltip: 'Number of requests (queries per second) received from SSP', active: true,
        },
        {
          attr: 'qps', label: 'Valid QPS',
          tooltip: 'Number of requests (queries per second) received from SSP after all validations, scanners blocking, filter lists', active: true,
        },
        { attr: 'ssp_rcpm', label: 'SSP RCPM, %', tooltip: 'SSP Spend / SSP Requests * 1000000', active: true },
        {
          attr: 'bidqps', label: 'Bid QPS',
          tooltip: 'Real time number of responses (queries per second) that you are sending to the SSP.', active: true,
        },
        { attr: 'daylySpend', label: 'Daily Spend', tooltip: 'Daily spend (in $) on this endpoint (in UTC time zone).', active: true },
        { attr: 'valid_bid_rate', label: 'Valid Bid Rate, %', tooltip: 'Valid Bid responses / Bid requests * 100', active: true },
        { attr: 'win_rate', label: 'Win Rate, %', tooltip: 'SSP wins / DSP wins * 100', active: true },
        { attr: 'render_rate', label: 'Render Rate, %', tooltip: 'Impressions / SSP win * 100', active: true },
        { attr: 'fill_rate', label: 'Fill rate, %', tooltip: 'Impressions / Bid Requests * 100', active: true },
        { attr: 'spendLimit', label: 'Spend Limit', tooltip: 'Daily spend limit of the endpoint (in US dollars).', active: true },
        { attr: 'margin', label: 'Margin, %', tooltip: 'Percent that the platform charges from the endpoint revenue.', active: true },
        { attr: 'region', label: 'Region', tooltip: 'Data center location.', active: true },
        { attr: 'email', label: 'Email', tooltip: 'Contact email.', active: true },
        { attr: 'comments', label: 'Comments', tooltip: 'Additional information about the endpoint.', active: true },
      ],
      dsp: [
        { attr: 'type', label: 'Type', tooltip: 'The endpoint connection type: RTB, VAST, or XML.', active: true },
        { attr: 'formats', label: 'Ad formats', tooltip: 'Ad formats', active: true },
        { attr: 'limitQPS', label: 'Limit QPS', tooltip: 'Max QPS allowed for the endpoint.', active: true },
        { attr: 'dsp_rcpm', label: 'DSP RCPM, %', tooltip: 'DSP Spend / Bid Requests * 1000000', active: true },
        {
          attr: 'realQPS', label: 'Real QPS',
          tooltip: 'Real time number of requests (queries per second) received by specific DSP from all linked SSPs.', active: true,
        },
        {
          attr: 'bidQPS', label: 'Bid QPS',
          tooltip: 'Real time number of bids (queries per second) received from the DSP.', active: true,
        },
        { attr: 'daylySpend', label: 'Daily Spend', tooltip: 'Actual daily spend on the endpoint (in US dollars).', active: true },
        { attr: 'total_bid_rate', label: 'Total Bid Rate, %', tooltip: 'Total Bid responses / Bid requests * 100', active: true },
        { attr: 'win_rate', label: 'Win Rate, %', tooltip: 'DSP wins / Valid DSP responses * 100', active: true },
        { attr: 'fill_rate', label: 'Fill rate, %', tooltip: 'Impressions / Bid Requests * 100', active: true },
        { attr: 'spendLimit', label: 'Spend Limit', tooltip: 'Daily spend limit of the endpoint (in US dollars).', active: true },
        { attr: 'margin', label: 'Margin, %', tooltip: 'Percent that the platform charges from the endpoint revenue.', active: true },
        { attr: 'region', label: 'Region', tooltip: 'Region of the data center.', active: true },
        {
          attr: 'nurl', label: 'Nurl',
          tooltip: 'This setting indicates if you will call Nurls (win notifications) of DSPs.', active: true,
        },
        { attr: 'email', label: 'Email', tooltip: 'Contact email.', active: true },
        { attr: 'comments', label: 'Comments', tooltip: 'Additional information about the endpoint.', active: true },
      ],
      screen: ''
    };
    if (localStorage.getItem('version') !== '3.0.0') {
      localStorage.removeItem(this.storageName);
      localStorage.removeItem(this.statOptionsStorageName);
      localStorage.removeItem(this.storageMainActivity);
      localStorage.removeItem(this.DSPSSPStatOptionsStorageName);
      localStorage.setItem('version', '3.0.0');
    }

    const options = localStorage.getItem(this.storageName);
    if (!options) {
      localStorage.setItem(this.storageName, JSON.stringify(defaultOptions));
      return defaultOptions;
    }

    try {
      return JSON.parse(options);
    } catch (e) {
      return defaultOptions;
    }
  }

  getStatisticOptions(): StatisticOptions {
    const defaultOptions: StatisticOptions = {
      ssp: [
        { attr: 'date', label: 'Date', active: true },
        { attr: 'name', label: 'Company Name', active: true },
        { attr: 'endpoint', label: 'Endpoint Name', active: true },
        { attr: 'bundle', label: 'App Bundle', active: true },
        { attr: 'domain', label: 'Domain', active: true },
        { attr: 'country', label: 'country', active: true },
        { attr: 'impressions', label: 'Impressions', active: true },
        { attr: 'spend', label: 'Spend', active: true },
        { attr: 'revenue', label: 'Revenue', active: true },
      ],
      dsp: [
        { attr: 'date', label: 'Date', active: true },
        { attr: 'name', label: 'Company Name', active: true },
        { attr: 'endpoint', label: 'Endpoint Name', active: true },
        { attr: 'bundle', label: 'App Bundle', active: true },
        { attr: 'domain', label: 'Domain', active: true },
        { attr: 'country', label: 'country', active: true },
        { attr: 'impressions', label: 'Impressions', active: true },
        { attr: 'spend', label: 'Spend', active: true },
        { attr: 'revenue', label: 'Revenue', active: true },
      ],
    };
    return defaultOptions;
  }

  getStaticticTableFields() {
    return {
      date: 'Date',
      sspId: 'Endpoint',
      dspId: 'Endpoint',
      resource: 'Domain/Bundle',
      publisher: 'Publisher',
      crid: 'Creative',
      dspSpend: 'Spend, $',
      sspSpend: 'Revenue, $',
      impressions: 'Impressions',
    };
  }

  getSelectedFields(type: string) {
    const options = this.getOptions();
    return options[type as keyof AppOptions];
  }

  setOption(options: any, type: string) {
    const old = this.getOptions();
    old[type as keyof AppOptions] = options;
    try {
      localStorage.setItem(this.storageName, JSON.stringify(old));
      this.optionsSource.next(old);
    } catch (e) {
      console.log('error while trying to save options');
    }
  }

  setOptionsMainActivity(options: {
    id: string;
    name: string;
  }[] & {
    id: string;
    name: string;
  }[] & {
    id: number;
    name: string;
  }[] & {
    id: number;
    name: string;
  }[] & {
    id: string;
    name: string;
  }[] & {
    id: string;
    name: string;
  }[] & string, type: string) {
    const old = this.getOptionsMainActivity();
    old[type as keyof MainOptionActivity] = options;
    try {
      localStorage.setItem(this.storageMainActivity, JSON.stringify(old));
    } catch (e) {
      console.log('error while trying to save options');
    }
  }

  setStatisticOptions(options: {
    attr: string;
    label: string;
    active: boolean;
  }[] & {
    attr: string;
    label: string;
    active: boolean;
  }[], type: string) {
    const old = this.getStatisticOptions();
    old[type as keyof StatisticOptions] = options;
    try {
      localStorage.setItem(this.statOptionsStorageName, JSON.stringify(old));
    } catch (e) {
      console.log('error while trying to save options');
    }
  }

  getApiUrl(): string {
    return environment.apiRoot;
  }

  getRegions(regions: any): any[] {
    const arrayReturn = [];
    for (const i in regions) {
      arrayReturn.push({ id: regions[i].region, name: regions[i].region });
    }
    return arrayReturn;
  }

  getSecureOptions(): { id: number, name: string }[] {
    return [
      { id: 0, name: 'Both' },
      { id: 1, name: 'Secure' },
      { id: 2, name: 'Non Secure' },
    ];
  }

  getCountriesList(): { id: string, name: string }[] {
    return [
      { id: 'ABW', name: 'Aruba' },
      { id: 'AFG', name: 'Afghanistan' },
      { id: 'AGO', name: 'Angola' },
      { id: 'AIA', name: 'Anguilla' },
      { id: 'ALA', name: 'Åland Islands' },
      { id: 'ALB', name: 'Albania' },
      { id: 'AND', name: 'Andorra' },
      { id: 'ANT', name: 'Netherlands Antilles' },
      { id: 'ARE', name: 'United Arab Emirates' },
      { id: 'ARG', name: 'Argentina' },
      { id: 'ARM', name: 'Armenia' },
      { id: 'ASM', name: 'American Samoa' },
      { id: 'ATA', name: 'Antarctica' },
      { id: 'ATF', name: 'French Southern Territories' },
      { id: 'ATG', name: 'Antigua and Barbuda' },
      { id: 'AUS', name: 'Australia' },
      { id: 'AUT', name: 'Austria' },
      { id: 'AZE', name: 'Azerbaijan' },
      { id: 'BDI', name: 'Burundi' },
      { id: 'BEL', name: 'Belgium' },
      { id: 'BEN', name: 'Benin' },
      { id: 'BFA', name: 'Burkina Faso' },
      { id: 'BGD', name: 'Bangladesh' },
      { id: 'BGR', name: 'Bulgaria' },
      { id: 'BHR', name: 'Bahrain' },
      { id: 'BHS', name: 'Bahamas' },
      { id: 'BIH', name: 'Bosnia and Herzegovina' },
      { id: 'BLM', name: 'Saint Barthélemy' },
      { id: 'BLR', name: 'Belarus' },
      { id: 'BLZ', name: 'Belize' },
      { id: 'BMU', name: 'Bermuda' },
      { id: 'BOL', name: 'Bolivia, Plurinational State of' },
      { id: 'BRA', name: 'Brazil' },
      { id: 'BRB', name: 'Barbados' },
      { id: 'BRN', name: 'Brunei Darussalam' },
      { id: 'BTN', name: 'Bhutan' },
      { id: 'BVT', name: 'Bouvet Island' },
      { id: 'BWA', name: 'Botswana' },
      { id: 'CAF', name: 'Central African Republic' },
      { id: 'CAN', name: 'Canada' },
      { id: 'CCK', name: 'Cocos (Keeling) Islands' },
      { id: 'CHE', name: 'Switzerland' },
      { id: 'CHL', name: 'Chile' },
      { id: 'CHN', name: 'China' },
      { id: 'CIV', name: 'Côte d\'Ivoire' },
      { id: 'CMR', name: 'Cameroon' },
      { id: 'COD', name: 'Congo, the Democratic Republic of the' },
      { id: 'COG', name: 'Congo' },
      { id: 'COK', name: 'Cook Islands' },
      { id: 'COL', name: 'Colombia' },
      { id: 'COM', name: 'Comoros' },
      { id: 'CPV', name: 'Cape Verde' },
      { id: 'CRI', name: 'Costa Rica' },
      { id: 'CUB', name: 'Cuba' },
      { id: 'CXR', name: 'Christmas Island' },
      { id: 'CYM', name: 'Cayman Islands' },
      { id: 'CYP', name: 'Cyprus' },
      { id: 'CZE', name: 'Czech Republic' },
      { id: 'DEU', name: 'Germany' },
      { id: 'DJI', name: 'Djibouti' },
      { id: 'DMA', name: 'Dominica' },
      { id: 'DNK', name: 'Denmark' },
      { id: 'DOM', name: 'Dominican Republic' },
      { id: 'DZA', name: 'Algeria' },
      { id: 'ECU', name: 'Ecuador' },
      { id: 'EGY', name: 'Egypt' },
      { id: 'ERI', name: 'Eritrea' },
      { id: 'ESH', name: 'Western Sahara' },
      { id: 'ESP', name: 'Spain' },
      { id: 'EST', name: 'Estonia' },
      { id: 'ETH', name: 'Ethiopia' },
      { id: 'FIN', name: 'Finland' },
      { id: 'FJI', name: 'Fiji' },
      { id: 'FLK', name: 'Falkland Islands (Malvinas)' },
      { id: 'FRA', name: 'France' },
      { id: 'FRO', name: 'Faroe Islands' },
      { id: 'FSM', name: 'Micronesia, Federated States of' },
      { id: 'GAB', name: 'Gabon' },
      { id: 'GBR', name: 'United Kingdom' },
      { id: 'GEO', name: 'Georgia' },
      { id: 'GGY', name: 'Guernsey' },
      { id: 'GHA', name: 'Ghana' },
      { id: 'GIB', name: 'Gibraltar' },
      { id: 'GIN', name: 'Guinea' },
      { id: 'GLP', name: 'Guadeloupe' },
      { id: 'GMB', name: 'Gambia' },
      { id: 'GNB', name: 'Guinea-Bissau' },
      { id: 'GNQ', name: 'Equatorial Guinea' },
      { id: 'GRC', name: 'Greece' },
      { id: 'GRD', name: 'Grenada' },
      { id: 'GRL', name: 'Greenland' },
      { id: 'GTM', name: 'Guatemala' },
      { id: 'GUF', name: 'French Guiana' },
      { id: 'GUM', name: 'Guam' },
      { id: 'GUY', name: 'Guyana' },
      { id: 'HKG', name: 'Hong Kong' },
      { id: 'HMD', name: 'Heard Island and McDonald Islands' },
      { id: 'HND', name: 'Honduras' },
      { id: 'HRV', name: 'Croatia' },
      { id: 'HTI', name: 'Haiti' },
      { id: 'HUN', name: 'Hungary' },
      { id: 'IDN', name: 'Indonesia' },
      { id: 'IMN', name: 'Isle of Man' },
      { id: 'IND', name: 'India' },
      { id: 'IOT', name: 'British Indian Ocean Territory' },
      { id: 'IRL', name: 'Ireland' },
      { id: 'IRN', name: 'Iran, Islamic Republic of' },
      { id: 'IRQ', name: 'Iraq' },
      { id: 'ISL', name: 'Iceland' },
      { id: 'ISR', name: 'Israel' },
      { id: 'ITA', name: 'Italy' },
      { id: 'JAM', name: 'Jamaica' },
      { id: 'JEY', name: 'Jersey' },
      { id: 'JOR', name: 'Jordan' },
      { id: 'JPN', name: 'Japan' },
      { id: 'KAZ', name: 'Kazakhstan' },
      { id: 'KEN', name: 'Kenya' },
      { id: 'KGZ', name: 'Kyrgyzstan' },
      { id: 'KHM', name: 'Cambodia' },
      { id: 'KIR', name: 'Kiribati' },
      { id: 'KNA', name: 'Saint Kitts and Nevis' },
      { id: 'KOR', name: 'Korea, Republic of' },
      { id: 'KWT', name: 'Kuwait' },
      { id: 'LAO', name: 'Lao People\'s Democratic Republic' },
      { id: 'LBN', name: 'Lebanon' },
      { id: 'LBR', name: 'Liberia' },
      { id: 'LBY', name: 'Libyan Arab Jamahiriya' },
      { id: 'LCA', name: 'Saint Lucia' },
      { id: 'LIE', name: 'Liechtenstein' },
      { id: 'LKA', name: 'Sri Lanka' },
      { id: 'LSO', name: 'Lesotho' },
      { id: 'LTU', name: 'Lithuania' },
      { id: 'LUX', name: 'Luxembourg' },
      { id: 'LVA', name: 'Latvia' },
      { id: 'MAC', name: 'Macao' },
      { id: 'MAF', name: 'Saint Martin (French part)' },
      { id: 'MAR', name: 'Morocco' },
      { id: 'MCO', name: 'Monaco' },
      { id: 'MDA', name: 'Moldova, Republic of' },
      { id: 'MDG', name: 'Madagascar' },
      { id: 'MDV', name: 'Maldives' },
      { id: 'MEX', name: 'Mexico' },
      { id: 'MHL', name: 'Marshall Islands' },
      { id: 'MKD', name: 'Macedonia, the former Yugoslav Republic of' },
      { id: 'MLI', name: 'Mali' },
      { id: 'MLT', name: 'Malta' },
      { id: 'MMR', name: 'Myanmar' },
      { id: 'MNE', name: 'Montenegro' },
      { id: 'MNG', name: 'Mongolia' },
      { id: 'MNP', name: 'Northern Mariana Islands' },
      { id: 'MOZ', name: 'Mozambique' },
      { id: 'MRT', name: 'Mauritania' },
      { id: 'MSR', name: 'Montserrat' },
      { id: 'MTQ', name: 'Martinique' },
      { id: 'MUS', name: 'Mauritius' },
      { id: 'MWI', name: 'Malawi' },
      { id: 'MYS', name: 'Malaysia' },
      { id: 'MYT', name: 'Mayotte' },
      { id: 'NAM', name: 'Namibia' },
      { id: 'NCL', name: 'New Caledonia' },
      { id: 'NER', name: 'Niger' },
      { id: 'NFK', name: 'Norfolk Island' },
      { id: 'NGA', name: 'Nigeria' },
      { id: 'NIC', name: 'Nicaragua' },
      { id: 'NIU', name: 'Niue' },
      { id: 'NLD', name: 'Netherlands' },
      { id: 'NOR', name: 'Norway' },
      { id: 'NPL', name: 'Nepal' },
      { id: 'NRU', name: 'Nauru' },
      { id: 'NZL', name: 'New Zealand' },
      { id: 'OMN', name: 'Oman' },
      { id: 'PAK', name: 'Pakistan' },
      { id: 'PAN', name: 'Panama' },
      { id: 'PCN', name: 'Pitcairn' },
      { id: 'PER', name: 'Peru' },
      { id: 'PHL', name: 'Philippines' },
      { id: 'PLW', name: 'Palau' },
      { id: 'PNG', name: 'Papua New Guinea' },
      { id: 'POL', name: 'Poland' },
      { id: 'PRI', name: 'Puerto Rico' },
      { id: 'PRK', name: 'Korea, Democratic People\'s Republic of' },
      { id: 'PRT', name: 'Portugal' },
      { id: 'PRY', name: 'Paraguay' },
      { id: 'PSE', name: 'Palestinian Territory, Occupied' },
      { id: 'PYF', name: 'French Polynesia' },
      { id: 'QAT', name: 'Qatar' },
      { id: 'REU', name: 'Réunion' },
      { id: 'ROU', name: 'Romania' },
      { id: 'RUS', name: 'Russian Federation' },
      { id: 'RWA', name: 'Rwanda' },
      { id: 'SAU', name: 'Saudi Arabia' },
      { id: 'SDN', name: 'Sudan' },
      { id: 'SEN', name: 'Senegal' },
      { id: 'SGP', name: 'Singapore' },
      { id: 'SGS', name: 'South Georgia and the South Sandwich Islands' },
      { id: 'SHN', name: 'Saint Helena, Ascension and Tristan da Cunha' },
      { id: 'SJM', name: 'Svalbard and Jan Mayen' },
      { id: 'SLB', name: 'Solomon Islands' },
      { id: 'SLE', name: 'Sierra Leone' },
      { id: 'SLV', name: 'El Salvador' },
      { id: 'SMR', name: 'San Marino' },
      { id: 'SOM', name: 'Somalia' },
      { id: 'SPM', name: 'Saint Pierre and Miquelon' },
      { id: 'SRB', name: 'Serbia' },
      { id: 'STP', name: 'Sao Tome and Principe' },
      { id: 'SUR', name: 'Suriname' },
      { id: 'SVK', name: 'Slovakia' },
      { id: 'SVN', name: 'Slovenia' },
      { id: 'SWE', name: 'Sweden' },
      { id: 'SWZ', name: 'Swaziland' },
      { id: 'SYC', name: 'Seychelles' },
      { id: 'SYR', name: 'Syrian Arab Republic' },
      { id: 'TCA', name: 'Turks and Caicos Islands' },
      { id: 'TCD', name: 'Chad' },
      { id: 'TGO', name: 'Togo' },
      { id: 'THA', name: 'Thailand' },
      { id: 'TJK', name: 'Tajikistan' },
      { id: 'TKL', name: 'Tokelau' },
      { id: 'TKM', name: 'Turkmenistan' },
      { id: 'TLS', name: 'Timor-Leste' },
      { id: 'TON', name: 'Tonga' },
      { id: 'TTO', name: 'Trinidad and Tobago' },
      { id: 'TUN', name: 'Tunisia' },
      { id: 'TUR', name: 'Turkey' },
      { id: 'TUV', name: 'Tuvalu' },
      { id: 'TWN', name: 'Taiwan, Province of China' },
      { id: 'TZA', name: 'Tanzania, United Republic of' },
      { id: 'UGA', name: 'Uganda' },
      { id: 'UKR', name: 'Ukraine' },
      { id: 'UMI', name: 'United States Minor Outlying Islands' },
      { id: 'URY', name: 'Uruguay' },
      { id: 'USA', name: 'United States' },
      { id: 'UZB', name: 'Uzbekistan' },
      { id: 'VAT', name: 'Holy See (Vatican City State)' },
      { id: 'VCT', name: 'Saint Vincent and the Grenadines' },
      { id: 'VEN', name: 'Venezuela, Bolivarian Republic of' },
      { id: 'VGB', name: 'Virgin Islands, British' },
      { id: 'VIR', name: 'Virgin Islands, U.S.' },
      { id: 'VNM', name: 'Viet Nam' },
      { id: 'VUT', name: 'Vanuatu' },
      { id: 'WLF', name: 'Wallis and Futuna' },
      { id: 'WSM', name: 'Samoa' },
      { id: 'YEM', name: 'Yemen' },
      { id: 'ZAF', name: 'South Africa' },
      { id: 'ZMB', name: 'Zambia' },
      { id: 'ZWE', name: 'Zimbabwe' },
    ];
  }

  getSizesList(): { id: string, name: string }[] {
    return [
      { id: '300x250', name: '300x250' },
      { id: '728x90', name: '728x90' },
      { id: '160x600', name: '160x600' },
      { id: '240x400', name: '240x400' },
      { id: '300x600', name: '300x600' },
      { id: '468x60', name: '468x60' },
      { id: '320x50', name: '320x50' },
      { id: '300x50', name: '300x50' },
      { id: '970x250', name: '970x250' },
      { id: '320x480', name: '320x480' },
      { id: '250x250', name: '250x250' },
    ];
  }

  getListOfSizes(): { id: number, width: number | null, height: number | null, name: string }[] {
    return [
      { id: 0, width: null, height: null, name: 'Custom' },
      { id: 1, width: 320, height: 50, name: 'Standard Banner Phones and Tablets' },
      { id: 2, width: 320, height: 100, name: 'Large Banner Phones and Tablets' },
      { id: 3, width: 300, height: 250, name: 'IAB Medium Rectangle Phones and Tablets' },
      { id: 4, width: 728, height: 90, name: 'IAB Leaderboard Tablets' },
      { id: 5, width: 468, height: 60, name: 'IAB Full-Size Banner Tablets' },
      { id: 6, width: 120, height: 600, name: 'IAB Skyscraper' },
      { id: 7, width: 120, height: 90, name: 'IAB Button 1' },
      { id: 9, width: 120, height: 60, name: 'IAB Button 2' },
      { id: 10, width: 234, height: 60, name: 'IAB Half Banner' },
      { id: 11, width: 88, height: 31, name: 'IAB Micro Bar' },
      { id: 12, width: 125, height: 125, name: 'IAB Square Button' },
      { id: 13, width: 120, height: 240, name: 'IAB Vertical Banner' },
      { id: 14, width: 180, height: 150, name: 'IAB Rectangle' },
      { id: 15, width: 336, height: 280, name: 'IAB Large Rectangle' },
      { id: 16, width: 240, height: 400, name: 'IAB Vertical Rectangle' },
      { id: 17, width: 250, height: 250, name: 'IAB Square Pop - up' },
      { id: 18, width: 160, height: 600, name: 'IAB Wide Skyscraper' },
      { id: 19, width: 720, height: 300, name: 'IAB Pop - Under' },
      { id: 20, width: 300, height: 100, name: 'IAB 3: 1 Rectangle' },
      { id: 21, width: 0, height: 0, name: 'Full - screen Banner' },
    ];
  }

  getPornFilter(): { id: number, name: string }[] {
    return [
      { id: 0, name: 'All' },
      { id: 1, name: 'Exclude Adult' },
      { id: 2, name: 'Only Adult' },
    ];
  }

  getOSList(): { id: string, name: string }[] {
    return [
      { id: 'android', name: 'Android' },
      { id: 'macos', name: 'macOS' },
      { id: 'ios', name: 'iOS' },
      { id: 'windows', name: 'Windows' },
      { id: 'linux', name: 'Linux' },
    ];
  }

  getListsLabes(type: string): string {
    const lists: MainLists = {
      'bundles': 'Bundles',
      'sites': 'Sites',
      'blocked-publishers': 'Blocked Publishers',
      'blocked-sites': 'Blocked Sites',
      'blocked-crids': 'Blocked Crids',
      'publishers': 'Publishers',
      'blocked-domains': 'Blocked Domains (ADM)',
    };

    return lists[type as keyof MainLists];
  }

  getTimezones() {
    return [
      { id: '-12', name: 'UTC -12' },
      { id: '-11', name: 'UTC -11' },
      { id: '-10', name: 'UTC -10' },
      { id: '-9', name: 'UTC -9' },
      { id: '-8', name: 'UTC -8' },
      { id: '-7', name: 'UTC -7' },
      { id: '-6', name: 'UTC -6' },
      { id: '-5', name: 'UTC -5' },
      { id: '-4', name: 'UTC -4' },
      { id: '-3', name: 'UTC -3' },
      { id: '-2', name: 'UTC -2' },
      { id: '-1', name: 'UTC -1' },
      { id: '0', name: 'UTC 0' },
      { id: '+1', name: 'UTC +1' },
      { id: '+2', name: 'UTC +2' },
      { id: '+3', name: 'UTC +3' },
      { id: '+4', name: 'UTC +4' },
      { id: '+5', name: 'UTC  +5' },
      { id: '+6', name: 'UTC +6' },
      { id: '+7', name: 'UTC +7' },
      { id: '+8', name: 'UTC +8' },
      { id: '+9', name: 'UTC +9' },
      { id: '+10', name: 'UTC +10' },
      { id: '+11', name: 'UTC +11' },
      { id: '+12', name: 'UTC +12' },
    ];
  }

  getConnectionTypes(): { id: number, name: string }[] {
    return [
      { id: 2, name: 'Wifi' },
      { id: 3, name: 'Cellular Network' },
      { id: 4, name: 'Cellular Network - 3G' },
      { id: 5, name: 'Cellular Network - 4G' },
    ];
  }

  getConnectionTypesForAutologger(): { value: number, label: string, checked?: boolean }[] {
    return [
      { value: 0, label: 'Unknown' },
      { value: 1, label: 'Ethernet' },
      { value: 2, label: 'Wifi' },
      { value: 3, label: 'Cellular Network - Unknown Generation' },
      { value: 4, label: 'Cellular Network - 2G' },
      { value: 5, label: 'Cellular Network - 3G' },
      { value: 6, label: 'Cellular Network - 4G' },
    ];
  }

  getTrafficTypesForAutologger(): { value: number, label: string, checked?: boolean }[] {
    return [
      { value: 1, label: 'Mobile Web' },
      { value: 2, label: 'Desktop' },
      { value: 3, label: 'Mobile App' },
      { value: 4, label: 'CTV' },
      { value: 5, label: 'Rewarded Video' },
    ];
  }

  getTypeForAutologger(): { id: string, name: string }[] {
    return [
      { id: 'banner', name: 'Banner' },
      { id: 'video', name: 'Video' },
      { id: 'native', name: 'Native' },
      { id: 'pop', name: 'Pop' },
      { id: 'push', name: 'Push' },
      { id: 'audio', name: 'Audio' },
    ];
  }

  getDeviceTypesForAutologger(): { value: number, label: string, checked?: boolean }[] {
    return [
      { value: 1, label: 'Mobile/Tablet' },
      { value: 2, label: 'Personal Computer' },
      { value: 3, label: 'Connected TV' },
      { value: 4, label: 'Phone' },
      { value: 5, label: 'Tablet' },
      { value: 6, label: 'Connected Device' },
      { value: 7, label: 'Set Top Box' },
    ];
  }

  getAggregationsForAutologger(): { id: number, name: string }[] {
    return [
      { id: 1, name: 'SSP' },
      { id: 2, name: 'Sitelist' },
      { id: 3, name: 'SSP+Domains' },
      { id: 4, name: 'Geo' },
      { id: 6, name: 'SSP+Geo' },
      { id: 7, name: 'Domains+Geo' },
    ];
  }

  getRegionsForAutologger(): { id: string, name: string }[] {
    return [
      { id: 'US_EAST', name: 'US_EAST' },
      { id: 'EU', name: 'EU' },
      { id: 'SGP', name: 'SGP' },
    ];
  }

  getBrowsersForAutologger(): { id: string, name: string }[] {
    return [
      { id: 'Chrome', name: 'Chrome' },
      { id: 'Edge', name: 'Edge' },
      { id: 'IE', name: 'IE' },
      { id: 'Safari', name: 'Safari' },
      { id: 'Mozilla', name: 'Mozilla Firefox' },
      { id: 'Opera', name: 'Opera' },
      { id: 'UCBrowser', name: 'UC Browser' },
      { id: 'Yandex', name: 'Yandex' },
    ];
  }

  getCategoriesForAutologger(): { id: string, name: string }[] {
    return [
      { id: 'IAB1', name: 'IAB1 Arts & Entertainment' },
      { id: 'IAB2', name: 'IAB2 Automotive' },
      { id: 'IAB3', name: 'IAB3 Business' },
      { id: 'IAB4', name: 'IAB4 Careers' },
      { id: 'IAB5', name: 'IAB5 Education' },
      { id: 'IAB6', name: 'IAB6 Family & Parenting' },
      { id: 'IAB7', name: 'IAB7 Health & Fitness' },
      { id: 'IAB8', name: 'IAB8 Food & Drink' },
      { id: 'IAB9', name: 'IAB9 Hobbies & Interests' },
      { id: 'IAB10', name: 'IAB10 Home & Garden' },
      { id: 'IAB11', name: 'IAB11 Law, Gov\'t & Politics' },
      { id: 'IAB12', name: 'IAB12 News' },
      { id: 'IAB13', name: 'IAB13 Personal Finance' },
      { id: 'IAB14', name: 'IAB14 Society' },
      { id: 'IAB15', name: 'IAB15 Science' },
      { id: 'IAB16', name: 'IAB16 Pets' },
      { id: 'IAB17', name: 'IAB17 Sports' },
      { id: 'IAB18', name: 'IAB18 Style & Fashion' },
      { id: 'IAB19', name: 'IAB19 Technology & Computing' },
      { id: 'IAB20', name: 'IAB20 Travel' },
      { id: 'IAB21', name: 'IAB21 Real Estate' },
      { id: 'IAB22', name: 'IAB22 Shopping' },
      { id: 'IAB23', name: 'IAB23 Religion & Spirituality' },
    ];
  }

  getLanguagesForAutologger(): { id: string, name: string }[] {
    return [
      { id: 'ab', name: 'Abkhazian' },
      { id: 'aa', name: 'Afar' },
      { id: 'af', name: 'Afrikaans' },
      { id: 'sq', name: 'Albanian' },
      { id: 'am', name: 'Amharic' },
      { id: 'ar', name: 'Arabic' },
      { id: 'an', name: 'Aragonese' },
      { id: 'hy', name: 'Armenian' },
      { id: 'as', name: 'Assamese' },
      { id: 'ay', name: 'Aymara' },
      { id: 'az', name: 'Azerbaijani' },
      { id: 'ba', name: 'Bashkir' },
      { id: 'eu', name: 'Basque' },
      { id: 'bn', name: 'Bengali (Bangla)' },
      { id: 'dz', name: 'Bhutani' },
      { id: 'bh', name: 'Bihari' },
      { id: 'bi', name: 'Bislama' },
      { id: 'br', name: 'Breton' },
      { id: 'bg', name: 'Bulgarian' },
      { id: 'my', name: 'Burmese' },
      { id: 'be', name: 'Byelorussian (Belarusian)' },
      { id: 'km', name: 'Cambodian' },
      { id: 'ca', name: 'Catalan' },
      { id: 'zh', name: 'Chinese' },
      { id: 'zh-Hans', name: 'Chinese (Simplified)' },
      { id: 'zh-Hant', name: 'Chinese (Traditional)' },
      { id: 'co', name: 'Corsican' },
      { id: 'hr', name: 'Croatian' },
      { id: 'cs', name: 'Czech' },
      { id: 'da', name: 'Danish' },
      { id: 'nl', name: 'Dutch' },
      { id: 'en', name: 'English' },
      { id: 'eo', name: 'Esperanto' },
      { id: 'et', name: 'Estonian' },
      { id: 'fo', name: 'Faeroese' },
      { id: 'fa', name: 'Farsi' },
      { id: 'fj', name: 'Fiji' },
      { id: 'fi', name: 'Finnish' },
      { id: 'fr', name: 'French' },
      { id: 'fy', name: 'Frisian' },
      { id: 'gl', name: 'Galician' },
      { id: 'gd', name: 'Gaelic (Scottish)' },
      { id: 'gv', name: 'Gaelic (Manx)' },
      { id: 'ka', name: 'Georgian' },
      { id: 'de', name: 'German' },
      { id: 'el', name: 'Greek' },
      { id: 'kl', name: 'Greenlandic' },
      { id: 'gn', name: 'Guarani' },
      { id: 'gu', name: 'Gujarati' },
      { id: 'ht', name: 'Haitian Creole' },
      { id: 'ha', name: 'Hausa' },
      { id: 'he', name: 'Hebrew' },
      { id: 'iw', name: 'Hebrew' },
      { id: 'hi', name: 'Hindi' },
      { id: 'hu', name: 'Hungarian' },
      { id: 'is', name: 'Icelandic' },
      { id: 'io', name: 'Ido' },
      { id: 'id', name: 'Indonesian' },
      { id: 'in', name: 'Indonesian' },
      { id: 'ia', name: 'Interlingua' },
      { id: 'ie', name: 'Interlingue' },
      { id: 'iu', name: 'Inuktitut' },
      { id: 'ik', name: 'Inupiak' },
      { id: 'ga', name: 'Irish' },
      { id: 'it', name: 'Italian' },
      { id: 'ja', name: 'Japanese' },
      { id: 'jv', name: 'Javanese' },
      { id: 'kn', name: 'Kannada' },
      { id: 'ks', name: 'Kashmiri' },
      { id: 'kk', name: 'Kazakh' },
      { id: 'rw', name: 'Kinyarwanda (Ruanda)' },
      { id: 'ky', name: 'Kirghiz' },
      { id: 'rn', name: 'Kirundi (Rundi)' },
      { id: 'ko', name: 'Korean' },
      { id: 'ku', name: 'Kurdish' },
      { id: 'lo', name: 'Laothian' },
      { id: 'la', name: 'Latin' },
      { id: 'lv', name: 'Latvian (Lettish)' },
      { id: 'li', name: 'Limburgish ( Limburger)' },
      { id: 'ln', name: 'Lingala' },
      { id: 'lt', name: 'Lithuanian' },
      { id: 'mk', name: 'Macedonian' },
      { id: 'mg', name: 'Malagasy' },
      { id: 'ms', name: 'Malay' },
      { id: 'ml', name: 'Malayalam' },
      { id: 'mt', name: 'Maltese' },
      { id: 'mi', name: 'Maori' },
      { id: 'mr', name: 'Marathi' },
      { id: 'mo', name: 'Moldavian' },
      { id: 'mn', name: 'Mongolian' },
      { id: 'na', name: 'Nauru' },
      { id: 'ne', name: 'Nepali' },
      { id: 'no', name: 'Norwegian' },
      { id: 'oc', name: 'Occitan' },
      { id: 'or', name: 'Oriya' },
      { id: 'om', name: 'Oromo (Afaan Oromo)' },
      { id: 'ps', name: 'Pashto (Pushto)' },
      { id: 'pl', name: 'Polish' },
      { id: 'pt', name: 'Portuguese' },
      { id: 'pa', name: 'Punjabi' },
      { id: 'qu', name: 'Quechua' },
      { id: 'rm', name: 'Rhaeto-Romance' },
      { id: 'ro', name: 'Romanian' },
      { id: 'ru', name: 'Russian' },
      { id: 'sm', name: 'Samoan' },
      { id: 'sg', name: 'Sangro' },
      { id: 'sa', name: 'Sanskrit' },
      { id: 'sr', name: 'Serbian' },
      { id: 'sh', name: 'Serbo-Croatian' },
      { id: 'st', name: 'Sesotho' },
      { id: 'tn', name: 'Setswana' },
      { id: 'sn', name: 'Shona' },
      { id: 'ii', name: 'Sichuan Yi' },
      { id: 'sd', name: 'Sindhi' },
      { id: 'si', name: 'Sinhalese' },
      { id: 'ss', name: 'Siswati' },
      { id: 'sk', name: 'Slovak' },
      { id: 'sl', name: 'Slovenian' },
      { id: 'so', name: 'Somali' },
      { id: 'es', name: 'Spanish' },
      { id: 'su', name: 'Sundanese' },
      { id: 'sw', name: 'Swahili (Kiswahili)' },
      { id: 'sv', name: 'Swedish' },
      { id: 'tl', name: 'Tagalog' },
      { id: 'tg', name: 'Tajik' },
      { id: 'ta', name: 'Tamil' },
      { id: 'tt', name: 'Tatar' },
      { id: 'te', name: 'Telugu' },
      { id: 'th', name: 'Thai' },
      { id: 'bo', name: 'Tibetan' },
      { id: 'ti', name: 'Tigrinya' },
      { id: 'to', name: 'Tonga' },
      { id: 'ts', name: 'Tsonga' },
      { id: 'tr', name: 'Turkish' },
      { id: 'tk', name: 'Turkmen' },
      { id: 'tw', name: 'Twi' },
      { id: 'ug', name: 'Uighur' },
      { id: 'uk', name: 'Ukrainian' },
      { id: 'ur', name: 'Urdu' },
      { id: 'uz', name: 'Uzbek' },
      { id: 'vi', name: 'Vietnamese' },
      { id: 'vo', name: 'Volap?k' },
      { id: 'wa', name: 'Wallon' },
      { id: 'cy', name: 'Welsh' },
      { id: 'wo', name: 'Wolof' },
      { id: 'xh', name: 'Xhosa' },
      { id: 'yi', name: 'Yiddish' },
      { id: 'ji', name: 'Yiddish' },
      { id: 'yo', name: 'Yoruba' },
      { id: 'zu', name: 'Zulu' },
    ];
  }
}
