export interface IRunState {
  isRun: boolean;
  timerTime: string;
  isLoad: boolean;
  isReady: boolean;
  isError: boolean;
  uuid: string;
}

export interface IReportDataState {
  report: any;
}

export interface ReportTableData {
  date: string;
  name: string;
  endpoint_name: string;
  impressions: string;
  app_bundle: number;
  domain: string;
  country: string;
  ssp: string;
  ssp_spend: string;
}
