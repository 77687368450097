export interface ItemEndpoint {
  id: number;
  name: string;
  checked: boolean;
  tooltip?: string;
  active?: number;
}

export interface ItemList {
  value: string;
  id: string;
}