import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';

import { NgxSpinnerService } from 'ngx-spinner';
import { format } from 'date-fns';

import { AppOptionsService, ErrorsHandlingService } from '@core/services';
import { generateCardColor, calculateXAxisSteps, filterNormalize } from '@core/helpers';

import { DashboardsInterface } from '../models';
import { FormGroup } from '@angular/forms';

enum DSPChartTypes {
  dsp_ecpm = 'dsp_ecpm',
  dsp_rcpm = 'dsp_rcpm',
  dsp_win_rate = 'dsp_win_rate',
  timeout_rate = 'timeout_rate',
  total_bid_rate = 'total_bid_rate',
  wins = 'wins',
  requests = 'requests',
  responses = 'responses',
}

enum DSPChartNames {
  dsp_ecpm = 'dspEcpm',
  dsp_rcpm = 'dspRcpm',
  dsp_win_rate = 'dspWinRate',
  timeout_rate = 'timeoutRate',
  total_bid_rate = 'totalBidRate',
  wins = 'wins',
  requests = 'requests',
  responses = 'responses',
}

enum SSPChartTypes {
  ssp_ecpm = 'ssp_ecpm',
  ssp_rcpm = 'ssp_rcpm',
  ssp_requests = 'ssp_requests',
  ssp_win_rate = 'ssp_win_rate',
  ssp_wins = 'ssp_wins',
  render_rate = 'render_rate',
  bid_rate = 'bid_rate',
}

enum SSPChartNames {
  ssp_ecpm = 'sspEcpm',
  ssp_rcpm = 'sspRcpm',
  ssp_requests = 'sspRequests',
  ssp_win_rate = 'sspWinRate',
  ssp_wins = 'sspWins',
  render_rate = 'renderRate',
  bid_rate = 'bidRate',
}

enum GeneralChartTypes {
  fill_rate = 'fill_rate',
  imps = 'imps',
  profit = 'profit',
}

enum GeneralChartNames {
  fill_rate = 'fillRate',
  imps = 'imps',
  profit = 'profit',
}

@Injectable({
  providedIn: 'root'
})
export class DashboardMetricOverviewService {

  public chartType = 'ssp';

  public sspChartNames = SSPChartNames;
  public dspChartNames = DSPChartNames;
  public generalChartNames = GeneralChartNames;

  public fillRateChartSeries = [];
  public fillRateChartColors = [];
  public fillRateChartOptions = null;
  public fillRateChartTotal = null;
  public fillRateChartColumns = null;

  public impsChartSeries = [];
  public impsChartColors = [];
  public impsChartOptions = null;
  public impsChartTotal = null;
  public impsChartColumns = null;

  public profitChartSeries = [];
  public profitChartColors = [];
  public profitChartOptions = null;
  public profitChartTotal = null;
  public profitChartColumns = null;

  public sspRequestsChartSeries = [];
  public sspRequestsChartColors = [];
  public sspRequestsChartOptions = null;
  public sspRequestsChartTotal = null;
  public sspRequestsChartColumns = null;

  public bidRateChartSeries = [];
  public bidRateChartColors = [];
  public bidRateChartOptions = null;
  public bidRateChartTotal = null;
  public bidRateChartColumns = null;

  public sspEcpmChartSeries = [];
  public sspEcpmChartColors = [];
  public sspEcpmChartOptions = null;
  public sspEcpmChartTotal = null;
  public sspEcpmChartColumns = null;

  public sspRcpmChartSeries = [];
  public sspRcpmChartColors = [];
  public sspRcpmChartOptions = null;
  public sspRcpmChartTotal = null;
  public sspRcpmChartColumns = null;

  public sspWinRateChartSeries = [];
  public sspWinRateChartColors = [];
  public sspWinRateChartOptions = null;
  public sspWinRateChartTotal = null;
  public sspWinRateChartColumns = null;

  public renderRateChartSeries = [];
  public renderRateChartColors = [];
  public renderRateChartOptions = null;
  public renderRateChartTotal = null;
  public renderRateChartColumns = null;

  public sspWinsChartSeries = [];
  public sspWinsChartColors = [];
  public sspWinsChartOptions = null;
  public sspWinsChartTotal = null;
  public sspWinsChartColumns = null;

  public dspEcpmChartSeries = [];
  public dspEcpmChartColors = [];
  public dspEcpmChartOptions = null;
  public dspEcpmChartTotal = null;
  public dspEcpmChartColumns = null;

  public dspRcpmChartSeries = [];
  public dspRcpmChartColors = [];
  public dspRcpmChartOptions = null;
  public dspRcpmChartTotal = null;
  public dspRcpmChartColumns = null;

  public dspWinRateChartSeries = [];
  public dspWinRateChartColors = [];
  public dspWinRateChartOptions = null;
  public dspWinRateChartTotal = null;
  public dspWinRateChartColumns = null;

  public timeoutRateChartSeries = [];
  public timeoutRateChartColors = [];
  public timeoutRateChartOptions = null;
  public timeoutRateChartTotal = null;
  public timeoutRateChartColumns = null;

  public totalBidRateChartSeries = [];
  public totalBidRateChartColors = [];
  public totalBidRateChartOptions = null;
  public totalBidRateChartTotal = null;
  public totalBidRateChartColumns = null;

  public requestsChartSeries = [];
  public requestsChartColors = [];
  public requestsChartOptions = null;
  public requestsChartTotal = null;
  public requestsChartColumns = null;

  public responsesChartSeries = [];
  public responsesChartColors = [];
  public responsesChartOptions = null;
  public responsesChartTotal = null;
  public responsesChartColumns = null;

  public winsChartSeries = [];
  public winsChartColors = [];
  public winsChartOptions = null;
  public winsChartTotal = null;
  public winsChartColumns = null;

  public metricOverViewCharts: any[] = [];

  public get dspEecpmSpinnerStatus(): boolean {
    return this._dspECPMSpinnerStatus;
  }

  public set dspEcpmSpinnerStatus(value: boolean) {
    this._dspECPMSpinnerStatus = value;
  }

  public get dspRcpmSpinnerStatus(): boolean {
    return this._dspRCPMSpinnerStatus;
  }

  public set dspRcpmSpinnerStatus(value: boolean) {
    this._dspRCPMSpinnerStatus = value;
  }

  public get dspWinRateSpinnerStatus(): boolean {
    return this._dspWinRateSpinnerStatus;
  }

  public set dspWinRateSpinnerStatus(value: boolean) {
    this._dspWinRateSpinnerStatus = value;
  }

  public get requestsSpinnerStatus(): boolean {
    return this._requestsSpinnerStatus;
  }

  public set requestsSpinnerStatus(value: boolean) {
    this._requestsSpinnerStatus = value;
  }

  public get responsesSpinnerStatus(): boolean {
    return this._responsesSpinnerStatus;
  }

  public set responsesSpinnerStatus(value: boolean) {
    this._responsesSpinnerStatus = value;
  }

  public get timeoutRateSpinnerStatus(): boolean {
    return this._timeoutRateSpinnerStatus;
  }

  public set timeoutRateSpinnerStatus(value: boolean) {
    this._timeoutRateSpinnerStatus = value;
  }

  public get totalBidRateSpinnerStatus(): boolean {
    return this._totalBidRateSpinnerStatus;
  }

  public set totalBidRateSpinnerStatus(value: boolean) {
    this._totalBidRateSpinnerStatus = value;
  }

  public get bidRateSpinnerStatus(): boolean {
    return this._bidRateSpinnerStatus;
  }

  public set bidRateSpinnerStatus(value: boolean) {
    this._bidRateSpinnerStatus = value;
  }

  public get fillRateSpinnerStatus(): boolean {
    return this._fillRateSpinnerStatus;
  }

  public set fillRateSpinnerStatus(value: boolean) {
    this._fillRateSpinnerStatus = value;
  }

  public get impsSpinnerStatus(): boolean {
    return this._impsSpinnerStatus;
  }

  public set impsSpinnerStatus(value: boolean) {
    this._impsSpinnerStatus = value;
  }

  public get profitSpinnerStatus(): boolean {
    return this._profitSpinnerStatus;
  }

  public set profitSpinnerStatus(value: boolean) {
    this._profitSpinnerStatus = value;
  }

  public get sspECPMSpinnerStatus(): boolean {
    return this._sspECPMSpinnerStatus;
  }

  public set sspECPMSpinnerStatus(value: boolean) {
    this._sspECPMSpinnerStatus = value;
  }

  public get sspRCPMSpinnerStatus(): boolean {
    return this._sspRCPMSpinnerStatus;
  }

  public set sspRCPMSpinnerStatus(value: boolean) {
    this._sspRCPMSpinnerStatus = value;
  }

  public get sspWinRateSpinnerStatus(): boolean {
    return this._sspWinRateSpinnerStatus;
  }

  public set sspWinRateSpinnerStatus(value: boolean) {
    this._sspWinRateSpinnerStatus = value;
  }

  public get sspWinsSpinnerStatus(): boolean {
    return this._sspWinsSpinnerStatus;
  }

  public set sspWinsSpinnerStatus(value: boolean) {
    this._sspWinsSpinnerStatus = value;
  }

  public get renderRateSpinnerStatus(): boolean {
    return this._renderRateSpinnerStatus;
  }

  public set renderRateSpinnerStatus(value: boolean) {
    this._renderRateSpinnerStatus = value;
  }

  public get winsSpinnerStatus(): boolean {
    return this._winsSpinnerStatus;
  }

  public set winsSpinnerStatus(value: boolean) {
    this._winsSpinnerStatus = value;
  }

  public get sspRequestsSpinnerStatus(): boolean {
    return this._sspRequestsSpinnerStatus;
  }

  public set sspRequestsSpinnerStatus(value: boolean) {
    this._sspRequestsSpinnerStatus = value;
  }

  private _dspECPMSpinnerStatus = false;
  private _dspRCPMSpinnerStatus = false;
  private _dspWinRateSpinnerStatus = false;
  private _requestsSpinnerStatus = false;
  private _responsesSpinnerStatus = false;
  private _timeoutRateSpinnerStatus = false;
  private _totalBidRateSpinnerStatus = false;
  private _winsSpinnerStatus = false;

  private _bidRateSpinnerStatus = false;
  private _sspECPMSpinnerStatus = false;
  private _sspRCPMSpinnerStatus = false;
  private _sspWinRateSpinnerStatus = false;
  private _sspWinsSpinnerStatus = false;
  private _renderRateSpinnerStatus = false;
  private _sspRequestsSpinnerStatus = false;

  private _fillRateSpinnerStatus = false;
  private _impsSpinnerStatus = false;
  private _profitSpinnerStatus = false;

  private apiUrl = '';

  constructor(
    private http: HttpClient,
    private options: AppOptionsService,
    private errorsHandlingService: ErrorsHandlingService,
    private loadingService: NgxSpinnerService,
  ) {
    this.apiUrl = this.options.getApiUrl();
  }

  public loadCharts(metricOverviewForm: FormGroup, type: string): void {
    this.getFillRateChart(metricOverviewForm);
    this.getImpsChart(metricOverviewForm);
    this.getProfitChart(metricOverviewForm);
    if (type === 'ssp') {
      this.loadSSPChars(metricOverviewForm);
    } else {
      this.loadDSPCharts(metricOverviewForm);
    }
  }

  private loadSSPChars(metricOverviewForm: FormGroup): void {
    this.getBidRateChart(metricOverviewForm);
    this.getSSPEcpmChart(metricOverviewForm);
    this.getSSPRcpmChart(metricOverviewForm);
    this.getSSPWinRateChart(metricOverviewForm);
    this.getRenderRateChart(metricOverviewForm);
    this.getSSPWinsChart(metricOverviewForm);
    this.getSSPRequestsChart(metricOverviewForm);
  }

  private loadDSPCharts(metricOverviewForm: FormGroup): void {
    this.getDSPEcpmChart(metricOverviewForm);
    this.getDSPRcpmChart(metricOverviewForm);
    this.getDSPWinRateChart(metricOverviewForm);
    this.getTimeoutRateChart(metricOverviewForm);
    this.getTotalBidRateChart(metricOverviewForm);
    this.getRequestsChart(metricOverviewForm);
    this.getResponsesChart(metricOverviewForm);
    this.getWinsChart(metricOverviewForm);
  }

  private getTimeoutRateChart(metricOverviewForm: FormGroup): void {
    this.getChart(metricOverviewForm, DSPChartNames.timeout_rate, DSPChartTypes.timeout_rate);
  }

  private getTotalBidRateChart(metricOverviewForm: FormGroup): void {
    this.getChart(metricOverviewForm, DSPChartNames.total_bid_rate, DSPChartTypes.total_bid_rate);
  }

  private getRequestsChart(metricOverviewForm: FormGroup): void {
    this.getChart(metricOverviewForm, DSPChartNames.requests, DSPChartTypes.requests);
  }

  private getResponsesChart(metricOverviewForm: FormGroup): void {
    this.getChart(metricOverviewForm, DSPChartNames.responses, DSPChartTypes.responses);
  }

  private getWinsChart(metricOverviewForm: FormGroup): void {
    this.getChart(metricOverviewForm, DSPChartNames.wins, DSPChartTypes.wins);
  }

  private getFillRateChart(metricOverviewForm: FormGroup): void {
    this.getChart(metricOverviewForm, GeneralChartNames.fill_rate, GeneralChartTypes.fill_rate);
  }

  private getImpsChart(metricOverviewForm: FormGroup): void {
    this.getChart(metricOverviewForm, GeneralChartNames.imps, GeneralChartTypes.imps);
  }

  private getProfitChart(metricOverviewForm: FormGroup): void {
    this.getChart(metricOverviewForm, GeneralChartNames.profit, GeneralChartTypes.profit);
  }

  private getSSPRequestsChart(metricOverviewForm: FormGroup): void {
    this.getChart(metricOverviewForm, SSPChartNames.ssp_requests, SSPChartTypes.ssp_requests);
  }

  private getBidRateChart(metricOverviewForm: FormGroup): void {
    this.getChart(metricOverviewForm, SSPChartNames.bid_rate, SSPChartTypes.bid_rate);
  }

  private getSSPEcpmChart(metricOverviewForm: FormGroup): void {
    this.getChart(metricOverviewForm, SSPChartNames.ssp_ecpm, SSPChartTypes.ssp_ecpm);
  }

  private getSSPRcpmChart(metricOverviewForm: FormGroup): void {
    this.getChart(metricOverviewForm, SSPChartNames.ssp_rcpm, SSPChartTypes.ssp_rcpm);
  }

  private getSSPWinRateChart(metricOverviewForm: FormGroup): void {
    this.getChart(metricOverviewForm, SSPChartNames.ssp_win_rate, SSPChartTypes.ssp_win_rate);
  }

  private getRenderRateChart(metricOverviewForm: FormGroup): void {
    this.getChart(metricOverviewForm, SSPChartNames.render_rate, SSPChartTypes.render_rate);
  }

  private getSSPWinsChart(metricOverviewForm: FormGroup): void {
    this.getChart(metricOverviewForm, SSPChartNames.ssp_wins, SSPChartTypes.ssp_wins);
  }

  private getDSPEcpmChart(metricOverviewForm: FormGroup): void {
    this.getChart(metricOverviewForm, DSPChartNames.dsp_ecpm, DSPChartTypes.dsp_ecpm);
  }

  private getDSPRcpmChart(metricOverviewForm: FormGroup): void {
    this.getChart(metricOverviewForm, DSPChartNames.dsp_rcpm, DSPChartTypes.dsp_rcpm);
  }

  private getDSPWinRateChart(metricOverviewForm: FormGroup): void {
    this.getChart(metricOverviewForm, DSPChartNames.dsp_win_rate, DSPChartTypes.dsp_win_rate);
  }

  private getChart(metricOverviewForm: FormGroup, chartType: string, chartKey: string): void {
    this.resetChartProperties(chartType);
    const filter = this.extractFilter(metricOverviewForm, chartKey);
    this.showLoading(chartType);
    this.fetchChartData(filter, chartType, chartKey);
  }

  private extractFilter(form: FormGroup, chartKey: string): any {
    this.chartType = form.value.type;
    const { date, region, type, endpoint, dsp, os, traffic_type, ad_format } = form.value;
    return { region, endpoint, dsp, date, type, ad_format, traffic_type, os, key: chartKey };
  }

  private showLoading(chartType: string): void {
    this.loadingService.show(chartType);
    this.getSpinnerStatus(chartType, `${chartType}SpinnerStatus`);
  }

  private fetchChartData(filter: any, chartType: string, chartKey: string): void {
    this.getMetricOverviewChart(filterNormalize(filter))
      .pipe(finalize(() => this.loadingService.hide(chartType)))
      .subscribe(
        res => this.processChartDataResponse(res, filter.date, chartType, chartKey),
        error => this.errorsHandlingService.handleError(error)
      );
  }

  private processChartDataResponse(res: any, date: string, chartType: string, chartKey: string): void {
    const dataForTransformChartByKey = this.prepareChartData(res, chartKey);
    const chart = this.transformChartData(dataForTransformChartByKey, chartKey, date);
    this.finalizeChart(chart, chartType, date);
  }

  private prepareChartData(res: any, chartType: string): any {
    return {
      config: { [chartType]: res.config[chartType], date: res.config.date },
      data: res.data,
    };
  }

  private transformChartData(dataForTransformChartByKey: any, chartType: string, date: string): any {
    const chart = this.transformToMetricChartData(
      JSON.parse(JSON.stringify(dataForTransformChartByKey)),
      chartType,
      this.getNumberOfDays(date),
      date,
    );
    chart.total = {
      [chartType]: dataForTransformChartByKey.data.reduce((total: any, item: { [x: string]: any; }) => total + item[chartType], 0)
    };
    return chart;
  }

  private finalizeChart(chart: any, chartType: string, date: string): void {
    chart.chartOptions.annotations = null;
    chart.chartOptions.xaxis.tickAmount = this.calculateXaxisTickAmount(chart.modifiedData, date);
    this.updateChartProperties(chartType, chart);
  }

  private updateChartProperties(chartType: string, chart: any): void {
    this[`${chartType}ChartSeries`] = chart.series;
    this[`${chartType}ChartColors`] = chart.chartColors;
    this[`${chartType}ChartOptions`] = chart.chartOptions;
    this[`${chartType}ChartTotal`] = chart.total;
    this[`${chartType}ChartColumns`] = chart.chartColumns;
  }

  private resetChartProperties(chartType: string): void {
    this[`${chartType}ChartSeries`] = [];
    this[`${chartType}ChartColors`] = [];
    this[`${chartType}ChartOptions`] = null;
    this[`${chartType}ChartTotal`] = null;
    this[`${chartType}ChartColumns`] = null;
  }

  private calculateMissingLeftElementsByDays(daysAgo: number, startDate: string): number {
    const today = new Date();
    const threeDaysAgo = new Date(today);
    threeDaysAgo.setDate(today.getDate() - daysAgo);
    threeDaysAgo.setHours(0, 0, 0, 0);

    const timeDiff = Math.abs(new Date(startDate).getTime() - threeDaysAgo.getTime());

    const diffHours = Math.ceil(timeDiff / (1000 * 60 * 60));

    return diffHours;
  }

  private generateMissingLeftElements(elementCount: number, firstDate: string, key: string) {
    let datesArray = [];

    // Convert the firstDate string to a Date object
    let startDate = new Date(firstDate);

    // Subtract one hour from the startDate
    startDate.setHours(startDate.getHours() - 1);

    // Loop to generate the array of dates
    for (let i = 0; i < elementCount; i++) {
      // Clone the startDate to avoid modifying it in place
      let newDate = new Date(startDate);

      // Subtract i hours from the newDate
      newDate.setHours(newDate.getHours() - i);


      // Push the newDate to the array
      datesArray.push({
        date: format(newDate, 'yyyy-MM-dd HH:mm:ss'),
        [key]: null,
      });
    }

    return datesArray.reverse();
  }

  private generateMissingRightElements(elementCount: number, lastDate: string, key: string) {
    let datesArray = [];

    // Convert the firstDate string to a Date object
    let startDate = new Date(lastDate);
    startDate.setHours(startDate.getHours() + 1);
    // Loop to generate the array of dates
    for (let i = 0; i < elementCount; i++) {
      // Clone the startDate to avoid modifying it in place
      let newDate = new Date(startDate);

      // Add i hours to the newDate
      newDate.setHours(newDate.getHours() + i);

      // Push the newDate to the array
      datesArray.push({
        date: format(newDate, 'yyyy-MM-dd HH:mm:ss'),
        [key]: null,
      });
    }

    return datesArray;
  }

  private findLargestValue(array: { [key: string]: number }[], key: string) {
    return array.reduce((max, item) => {
      return Math.max(max, (item[key]));
    }, 0);
  }

  private roundToNearestValue(number: number) {
    // Define the powers of 10 for the target values
    const powersOf10 = [
      0.1, 1, 10, 100, 1000, 10000, 100000, 1000000, 10000000,
      100000000, 100000000, 1000000000, 10000000000, 100000000000,
      1000000000000
    ];

    // Calculate the target values dynamically based on the powers of 10
    const targetValues = powersOf10.reduce((acc, val) => {
      acc.push(
        val * 0.25,
        val * 0.5,
        val * 1,
        val * 1.5,
        val * 2,
        val * 2.5,
        val * 3,
        val * 3.5,
        val * 4,
        val * 4.5,
        val * 5
      );
      return acc;
    }, []);

    if (number < 0.1) {
      return 0.1;
    }

    for (let i = 0; i < targetValues.length - 1; i++) {
      if (number < targetValues[i]) {
        return targetValues[i];
      }
    }

    return targetValues[targetValues.length - 1];
  }

  private getIntervalInMinutes(selectedDate: string): number {
    switch (selectedDate) {
      case 'today':
        return 60;
      case 'yesterday':
        return 60;
      case 'last_3_days':
        return 360;
      case 'last_7_days':
        return 720;
      case 'last_30_days':
        return 1440;
      default:
        return 1440;
    }
  }

  private getNumberOfDays(selectedDate: string): number {
    switch (selectedDate) {
      case 'today':
        return 1;
      case 'yesterday':
        return 1;
      case 'last_3_days':
        return 3;
      case 'last_7_days':
        return 7;
      case 'last_30_days':
        return 30;
      default:
        return 30;
    }
  }

  private calculateXaxisTickAmount(array: { [key: string]: string | number }[], selectedDate: string) {
    return calculateXAxisSteps(array, this.getIntervalInMinutes(selectedDate));
  }

  private transformToMetricChartData(
    chart: Partial<DashboardsInterface.IDataResForTotal>,
    key: string,
    numberOfDays: number,
    selectedDate: string,
  ): DashboardsInterface.IChartMetricTransformData {
    const dynamicFields = Object.keys(chart.config).filter(
      (key) => key !== 'date',
    );

    const datesItems = ['today', 'last_7_days', 'last_30_days'];


    if (!datesItems.includes(selectedDate)) {
      const startDate = chart.data[0].date;
      const endDate = chart.data[chart.data.length - 1].date;

      const missingLeft = this.calculateMissingLeftElementsByDays(numberOfDays, startDate);
      let missingRight = 0;

      if (new Date(endDate).getHours() !== 0) {
        missingRight = 23 - new Date(endDate).getHours();
      }

      let arrayLeft = [];
      let arrayRight = [];

      if (missingLeft) {
        arrayLeft = this.generateMissingLeftElements(missingLeft, startDate, key);
      }

      if (missingRight) {
        arrayRight = this.generateMissingRightElements(missingRight, endDate, key);
      }

      chart.data = [...arrayLeft, ...chart.data, ...arrayRight];
    }

    const modifiedData = [...chart.data];

    chart.data = chart.data.map(item => {
      item.date = new Date(item.date + 'Z').getTime();
      return item;
    });


    const largestValue = this.findLargestValue(modifiedData, key);
    const roundValue = this.roundToNearestValue(largestValue);

    const transformedData = chart.data.map((item) => ({
      x: item.date,
      y: dynamicFields.map((field) => item[field] === 0 ? null : item[field]),
    }));


    const series = dynamicFields.map((field, index) => {
      const name = chart.config[field].name;
      const color = generateCardColor(field);

      let chartData = transformedData.map((item) => ({
        x: item.x,
        y: item.y[index],
      }));

      return {
        field,
        name,
        color,
        data: chartData,
      };
    });

    const colors = series.map((series) => ({
      name: series.name,
      color: series.color,
    }));

    const limitedSeries = series.slice(0, 3);

    const chartOptions: Partial<DashboardsInterface.ChartOptions> = {
      chart: {
        type: 'line',
        height: 650,
        zoom: {
          enabled: false,
        }
      },
      plotOptions: {},
      xaxis: {
        type: 'datetime',
        tickAmount: 24,
        labels: {
          show: true,
          formatter: function (value) {
            const formatter = new Intl.DateTimeFormat('en-US', {
              day: '2-digit',
              hour: '2-digit',
              minute: '2-digit',
              hour12: true,
              timeZone: 'UTC',
              month: 'short',
            });
            const formattedDate = formatter.format(new Date(value));
            return formattedDate;
          },
        },
      },
      stroke: {
        curve: 'smooth',
        width: 3,
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      tooltip: {
        enabled: true
      },
      yaxis: {
        opposite: false,
        min: 0,
        max: roundValue,
        tickAmount: 10,
        labels: {
          minWidth: 50,
          maxWidth: 160,
          formatter: function (val) {
            if (val && val > 2) {
              return val.toFixed(0); // Removes decimal places from the y-axis labels
            } else if (val && val < 2 && val > 1) {
              return val.toFixed(1);
            } else if (val && val < 1) {
              return val.toFixed(3);
            } else {
              return [];
            }
          }
        }
      },
      colors: [],
    };

    const total = 0;

    const chartColors = [
      { name: colors[0].name, color: '' },
    ];
    const chartColumns = {
      [key]: { name: colors[0].name }
    };

    const name = colors[0].name;

    return {
      series,
      colors,
      limitedSeries,
      chartOptions,
      total,
      chartColors,
      chartColumns,
      name,
      transformedData,
      modifiedData,
    };
  }

  public getSpinnerStatus(spinnerName: string, name: string): void {
    this.loadingService.getSpinner(spinnerName).subscribe(res => {
      this[name] = res.show;
    });
  }

  private getMetricOverviewChart(filter: DashboardsInterface.IMetricOverviewChartReqData): Observable<any> {
    return this.http.post(`${this.apiUrl}/analytics-dashboards/metrics`, { filter: filter })
      .pipe(catchError(this.errorsHandlingService.processError));
  }
}
