<nz-table nzSize="small" [nzFrontPagination]="isShowFrontPagination" [nzShowPagination]="isShowPagination"
  [nzPageSize]="pageSize" [nzPageIndex]="pageIndex" [nzTotal]="total" [nzPageSizeOptions]="pageSizeOptions"
  [nzShowSizeChanger]="isShowSizeChanger" [nzScroll]="{x: '1360px', y: tableHeight}" [nzData]="tableData"
  (nzPageIndexChange)="onChangePageIndex($event)" (nzPageSizeChange)="onChangePageSize($event)">
  <thead>
    <tr>
      <th *ngFor="let columnHead of tableConfig.columns" (click)="columnHead.sortable && sort(columnHead.rowProperty)"
        [nzWidth]="columnHead.width">
        {{columnHead.label}}
        <span *ngIf="columnHead.sortable">
          <span class="sorting-indicator" *ngIf="sortBy === columnHead.rowProperty">
            <span class="sort-icon sort-icon-{{ sortOrder }}"></span>
          </span>
          <span class="sorting-indicator" *ngIf="sortBy !== columnHead.rowProperty">
            <span class="sort-icon sort-icon-normal"></span>
          </span>
        </span>
      </th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let td of tableData">
      <ng-container *ngFor="let rowAction of tableConfig.rowActions">
        <td>
          <span *ngIf="rowAction.hasFunc && !rowAction.hasControl" [ngClass]="rowAction.getClass(td)">
            {{rowAction.rowFn(td)}}
          </span>
          <span *ngIf="!rowAction.hasControl && !rowAction.hasFunc">
            {{td[rowAction.rowName]}}
          </span>
          <span *ngIf="rowAction.hasControl" [ngClass]="tableConfig.type === 'filterList' ? '' : 'controls'"
            [style.width]="rowAction.width">
            <span *ngFor="let action of rowAction.action; last as isLast">
              <button *ngIf="action.element === 'button'" nz-button nzType="text" [name]="action.actionType"
                (click)="onRowActionClicked(td, action.actionType)" class="control">
                <i nz-icon [nzType]="action.iconName" nzTheme="outline"></i>
                {{action.label}}
              </button>
              <nz-divider nzType="vertical" *ngIf="!isLast && tableConfig.type === 'filterList'"></nz-divider>
              <label *ngIf="action.element === 'checkbox'" nz-checkbox
                [nzDisabled]="rowAction.hasFunc && rowAction.rowFn(td)"
                (ngModelChange)="onCheckboxActionClicked($event, td, action.actionType)"
                [(ngModel)]="!!td[rowAction.rowName]" class="control"></label>
              <span *ngIf="action.element === 'buttonRow'" [ngClass]="rowAction.getClass(td)"
                (click)="onRowActionClicked(td, action.actionType, rowAction.rowName)" class="control">
                {{rowAction.rowFn(td)}}
              </span>
            </span>
          </span>
        </td>
      </ng-container>
    </tr>
  </tbody>
</nz-table>