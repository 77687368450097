/* eslint-disable max-len */
export interface PaginationState<T> {
  data: T[];
  per_page: number;
  total: number;
  to?: number;
  categories?: string[];
  current_page?: number;
  next_page_url: string | null;
}

export class PaginatorModel<T> implements PaginationState<T> {
  data: T[] = [];
  per_page: 25;
  total: 1;
  to?: 1;
  current_page?: 1;
  next_page_url: null;
}

export interface SortState {
  sortBy: string;
  sortOrder: string;
}
export interface FilterList {
  id: number;
  name: string;
  type: string;
  record_type: string;
  is_all_ssp: number;
  is_all_dsp: number;
  create_at: string;
  updated_at: string;
  bundleLists: string[];
  values_count: number;
}

export interface BundlesList {
  data: string[];
  total: number;
  per_page: number;
  current_page: number;
}
export interface FilterTypes {
  id: string;
  name: string;
}

export interface FilterCategory {
  id: string | number;
  name: string;
  checked?: boolean;
}

export class DataEndpoints {
  dsp: number[] = [];
  ssp: number[] = [];
}

export interface EndpointsForEditList {
  dsp: number[];
  ssp: number[];
}

// tslint:disable-next-line: class-name
export interface CreateNewFilterListData {
  list: NewFilterList;
  endpoints: DataEndpoints;
  values_list: ValuesList;
}

// tslint:disable-next-line: class-name
export interface RequestFilterListData {
  list: FilterListData;
  endpoints: DataEndpoints;
}

export interface FilterListData {
  name: string;
  record_type: string;
  type: string;
  id?: number;
  is_all_dsp: number;
  is_all_ssp: number;
  hash: string;
  has_item_changes: boolean;
}

export interface NewFilterList {
  name: string;
  record_type: string;
  type: string;
  is_all_dsp: number;
  is_all_ssp: number;
  hash: string;
  has_item_changes: boolean;
}

export class EndpointDSP {
  dsp: number[] = [];
}

export class EndpointSSP {
  ssp: number[] = [];
}

export class ValuesList {
  values_list!: string[];
}

export interface EndpointsData {
  ssp: EndpointsGeneral[];
  dsp: EndpointsGeneral[];
}

export interface CompaniesDataRes {
  ssp: CompaniesDataGeneral[];
  dsp: CompaniesDataGeneral[];
}

export class FormList {
  type: 'all';
  category: 'all';
}

export interface CompaniesDataGeneral {
  id: number;
  name: string;
  value: number;
  label: string;
  checked: boolean;
  indeterminate: boolean;
  disabled: boolean;
  endpoints: {
    value: number;
    label: string;
    checked: boolean;
    tooltip?: string;
    active?: number;
    name?: string;
    id: number;
    disabled: boolean;
  }[]
}

export interface EndpointsGeneral {
  value: number;
  label: string;
  checked: boolean;
  tooltip?: string;
  active?: number;
  name?: string;
  id: number;
  disabled: boolean;
  enabled?: number;
}

export interface GeneralEndpoints {
  value: number;
  label: string;
  checked: boolean;
  tooltip?: string;
  active?: number;
  full_name?: string;
  id: number;
}

export interface DataList {
  data: ListData;
}

export interface ListData {
  create_at: string;
  endpoints: EndpointsForEditList;
  id: number;
  is_all_dsp: number;
  is_all_ssp: number;
  name: string;
  record_type: string;
  type: string;
  updated_at: string;
  has_grouped_ssp: number;
  has_grouped_dsp: number;
}

export interface CheckedItems {
  id: number;
  listsItems: number[];
  hashChanges: boolean
}

export interface SelectCategoryItems { id: number | string, name: string, checked?: boolean, tooltip?: string }


export const notificationRestrictedText = {
  filterList: 'Selecting more than 30 endpoints on the SSP or DSP sides is prohibited. Setting up filter lists with a high number of connections leads to increased auction latency and may have a negative impact on trading activity. In such cases, it is recommended to use the \'All Companies/All Endpoints\' checkboxes to create a global filter list for blocking or adding specific source categories to the allowed list.',
  ruleManager: 'Selecting more than 30 endpoints on the SSP or DSP sides is prohibited. Setting up rule with a high number of connections leads to increased auction latency and may have a negative impact on trading activity. In such cases, it is recommended to use the \'All Companies/All Endpoints\' checkboxes to create a global rule for blocking or adding specific source categories to the allowed list.',
};