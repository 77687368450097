import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzButtonModule } from 'ng-zorro-antd/button';

import { DeleteDialogComponent } from './delete-dialog.component';

@NgModule({
  declarations: [DeleteDialogComponent],
  exports: [DeleteDialogComponent],
  imports: [
    CommonModule,
    NzModalModule,
    NzButtonModule,
  ]
})
export class DeleteDialogModule { }
