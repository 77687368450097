<div
  class="dropdown-component"
  [class.opened]="opened"
  (clickOutside)="clickOutSide($event)"
>
  <button class="btn dropdown-component__button" (click)="toggleOpened($event)">
    <img class="icon-table" src="assets/img/ic_sort_table.svg" alt="" />
    <img class="icon-arrow" src="assets/img/ic_arrow_drop_down.svg" alt="" />
  </button>
  <div class="dropdown-component__body" (click)="onDropdownBodyClicked($event)">
    <div
      class="custom-scroll"
      [style.height]="contentHeight"
      [style.max-height]="contentMaxHeight"
    >
      <div
        class="left"
        dndDropzone
        dndEffectAllowed="copyMove"
        (dndDrop)="onDrop($event, draggableListLeft)"
      >
        <div class="title">Selected</div>
        <div class="item" dndPlaceholderRef class="dndPlaceholder"></div>
        <div
          class="item"
          *ngFor="let item of draggableListLeft"
          [dndDraggable]="item"
          [dndEffectAllowed]="item.effectAllowed"
          [dndDisableIf]="item.disable"
          (dndStart)="onDragStart($event)"
          (dndCopied)="onDragged(item, draggableListLeft, 'copy')"
          (dndLinked)="onDragged(item, draggableListLeft, 'link')"
          (dndMoved)="onDragged(item, draggableListLeft, 'move')"
          (dndCanceled)="onDragged(item, draggableListLeft, 'none')"
          (dndEnd)="onDragEnd($event)"
        >
          {{ item.content }}
          <div *ngIf="item.handle" dndHandle class="handle-icon">
            <img src="assets/img/handle-icon.svg" alt="" />
          </div>
        </div>
      </div>
      <div class="right">
        <div class="title">Settings</div>
        <div class="list-wrap">
          <ng-container *ngFor="let item of items">
            <div class="item" *ngIf="metricFields.indexOf(item.attr) === -1">
              <label
                nz-checkbox
                *ngIf="metricFields.indexOf(item.attr) === -1"
                [(ngModel)]="item.active"
                (ngModelChange)="changeOptions($event)"
                >{{ item.label }}</label
              >
              <div
                *ngIf="metricFields.indexOf(item.attr) !== -1"
                class="icon"
                nz-tooltip
                nzTooltipOverlayClassName="item-tooltip"
                nzTooltipTitle="{{ item.tooltip }}"
                nzTooltipPlacement="top"
              >
                <img src="assets/img/ic_inform.svg" alt="" />
              </div>
            </div>
          </ng-container>
        </div>

        <div class="title">Metrics</div>
        <div class="list-wrap">
          <ng-container *ngFor="let item of items">
            <div class="item" *ngIf="metricFields.indexOf(item.attr) !== -1">
              <label
                nz-checkbox
                [(ngModel)]="item.active"
                (ngModelChange)="changeOptions($event)"
                *ngIf="metricFields.indexOf(item.attr) !== -1"
                >{{ item.label }}</label
              >
              <div
                *ngIf="metricFields.indexOf(item.attr) !== -1"
                class="icon"
                nz-tooltip
                nzTooltipOverlayClassName="item-tooltip"
                nzTooltipTitle="{{ item.tooltip }}"
                nzTooltipPlacement="top"
              >
                <img src="assets/img/ic_inform.svg" alt="" />
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
