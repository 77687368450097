import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DndModule } from 'ngx-drag-drop';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';

import { ColumnOptionDropComponent } from './column-option-drop.component';
import { ClickOutsideModule } from "@shared/directives";

@NgModule({
  declarations: [ColumnOptionDropComponent],
  exports: [ColumnOptionDropComponent],
  imports: [
    CommonModule,
    FormsModule,
    NgScrollbarModule,
    DndModule,
    NzCheckboxModule,
    NzToolTipModule,
    ClickOutsideModule
  ]
})
export class ColumnOptionDropModule { }
