export function isEveryElementChecked<T>(array: T[], parentKey: string, enableKey: string): boolean {
  for (const element of array) {
    for (const endpoint of element[parentKey]) {
      if (!endpoint[enableKey]) {
        return false;
      }
    }
  }

  return true;
}