import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { EndpointUrlDirective } from './endpoint-url.directive';

@NgModule({
  declarations: [EndpointUrlDirective],
  exports: [EndpointUrlDirective],
  imports: [
    CommonModule,
  ]
})
export class EndpointUrlModule { }
