<div class="platform-form platform-form__lists">
  <div class="platform-form__top" *nzModalTitle>
    {{data.title | titlecase}} <span class="list-count">{{list.length}}</span>
  </div>
  <div class="row">
    <div class="col-4">
      <div class="form-group form-group--row">
        <label>Type</label>
        <nz-select nzShowSearch nzPlaceHolder="Choose Item" [(ngModel)]="filter"
          (ngModelChange)="changeAllowed($event === 'Allowed' ? 1 : 0)">
          <nz-option *ngFor="let item of filters" [nzValue]="item.id" [nzLabel]="item.name"></nz-option>
        </nz-select>
      </div>
    </div>
    <div class="col-4">
      <button nz-button nzType="default" (click)="listDownload()" [disabled]="allowed === -1">Download</button>
    </div>
    <div class="col-4">
      <button nz-button nzType="default" (click)="fileInput.click()" [disabled]="allowed === -1">
        Upload
        <input #fileInput (change)="fileUpload($event.target.files)" type="file">
      </button>
    </div>
  </div>
  <label class="lists-label" for="list">
    {{options.getListsLabes(data.listType)}}
  </label>
  <div class="platform-form__middle">
    <textarea (change)="onListUpdated($event)" [(ngModel)]="listAsString" [disabled]="allowed === -1"
      class="form-control list-textarea" id="list" name="list">{{listAsString}}</textarea>
    <div class="platform-form__middle__buttons">

    </div>

  </div>
  <div *nzModalFooter class="modal-footer">
    <button nz-button nzType="primary" (click)="saveData()"
      [disabled]="clickSaveButton || allowed === -1 || list.length > allowedLimitItems" type="submit">SAVE
    </button>
    <button (click)="close()" nz-button nzType="default" type="button">CANCEL</button>
  </div>
</div>