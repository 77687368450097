import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS } from '@angular/forms';

@Directive({
  selector: '[app-domain-valid]',
  providers: [{ provide: NG_VALIDATORS, useExisting: DomainValidDirective, multi: true }]
})
export class DomainValidDirective {
  @Input('errorField') errName!: string;
  @Input('errorMessages') errMessages: any;
  constructor() { }

  validate(control: AbstractControl): { [key: string]: any } {
    const URL_REGEXP = /^([\w\-.]+)\.([a-z]{2,7}\.?)?$/;
    if (control.value && !URL_REGEXP.test(control.value)) {
      return { [this.errName]: { value: this.errMessages[this.errName] } };
    }
    return null as unknown as object;
  }

}
