import { Component, Inject } from '@angular/core';
import { AccessLimitationGeneral } from '@core/interfaces';

import { NZ_MODAL_DATA, NzModalRef } from 'ng-zorro-antd/modal';
@Component({
  selector: 'app-access-limitation-dialog',
  templateUrl: './access-limitation-dialog.component.html',
  styleUrls: ['./access-limitation-dialog.component.scss']
})
export class AccessLimitationDialogComponent {

  constructor(
    @Inject(NZ_MODAL_DATA) public data: { tableData: AccessLimitationGeneral[], total: number; },
    private dialogRef: NzModalRef<AccessLimitationDialogComponent>
  ) { }

  close(): void {
    this.dialogRef.close();
  }
}
