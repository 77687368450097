export const trafficAnalyzerTableColumnSize = {
  ssp_name: '250px',
  dsp_name: '250px',
  ad_format: '125px',
  traffic_type: '125px',
  event_name: '250px',
  event_requests: '155px',
  event_ratio: '145px',
};

export const trafficAnalyzerTableColumnToolTip = {
  event_ratio: `Is the proportion of detected suspicious events to the total number of events triggered by the script, expressed as a percentage. 
  It is calculated as the number of detected suspicious events divided by the total number of events triggered by the script, multiplied by 100%. 
  This metric helps to understand the percentage of potentially suspicious events compared to all events triggered by the script, 
  providing insights into the quality of the traffic.`,
  event_requests: `The direct count of events triggered by the Traffic Analyzer script to detect potentially suspicious events.
   It represents the total number of events triggered by the script for analyzing the quality of the impression.`
};