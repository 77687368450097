<div class="dialog-change">
  <div class="content">
    <img src="assets/img/ic_error_outline.svg" alt="icon-error">
    <p class="content-text">Please note:</p>
    <p class="content-text">the change of category will lead to uploaded</p>
    <p class="content-text">filter list reset</p>
  </div>
  <div *nzModalFooter class="button-footer">
    <button nz-button type="submit" nzType="primary" (click)="resetCategory()">Reset</button>
    <button nz-button type="button" nzType="default" (click)="onCloseDialog()">Cancel</button>
  </div>
</div>