import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';

import { ChartInterface } from '@core/interfaces';
import { generateCardColor } from '@core/helpers';

import { DashboardsService } from '@modules/dashboards/services';
@Component({
  selector: 'app-metric-cards',
  templateUrl: './metric-cards.component.html',
  styleUrls: ['./metric-cards.component.scss']
})
export class MetricCardsComponent implements OnInit, OnChanges {
  public get colors(): {
    color: string;
    name: string;
  }[] {
    return this._colors;
  }

  public get items() {
    return this._items;
  }

  public get names() {
    return this._names;
  }

  @Input()
  public set items(value: any) {
    this._items = value;
  }

  @Input()
  public set colors(
    value: {
      color: string;
      name: string;
    }[],
  ) {
    this._colors = value;
  }

  @Input()
  public set names(value: any) {
    this._names = value;
  }

  @Input() type: string;

  public enabled: string[] = [];

  public cards: ChartInterface.ICard[] = [];

  public get _cards() {
    return this.cards.filter((card) => card.show);
  }

  private _items: any = {};

  private _names: any = {};

  private _colors: { color: string; name: string }[] = [];

  constructor(private dashboardsService: DashboardsService,) { }

  ngOnInit() {
    this.cards = this.createCards();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.items) {
      this.cards = this.createCards();
    }
  }


  public createCards() {
    const keys = Object.keys(this.items);
    const firstThreeCardKeys = keys.slice(0, 3);

    if (Object.keys(this.names).length > 0) {
      return keys.map(key => {
        return {
          name: this.names[key].name,
          value: this.items[key],
          enabled: firstThreeCardKeys.includes(key),
          color: generateCardColor(key),
          textColor: this.dashboardsService.generateTextColor(key),
          show: true,
          column: key,
        };
      });
    }
    else {
      return [];
    }
  }
}
