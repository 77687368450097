import { TrafficAnalyzerInterface, HelpersInterface } from '../../interfaces';

export function transformArray(companiesArray: TrafficAnalyzerInterface.Companies[]): HelpersInterface.ITressSelectItem[] {
  return companiesArray.map((company) => ({
    title: company.name,
    key: `${company.id}`,
    checked: company.checked,
    children: company.endpoints.map((endpoint) => ({
      title: endpoint.label,
      key: `${endpoint.id}`,
      checked: endpoint.checked,
      isLeaf: true,
    })),
  }));
}

export function getStringCheckedKeys(items: HelpersInterface.ITressSelectItem[]): string[] {
  const checkedKeys: string[] = [];

  const processElement = (element: HelpersInterface.ITressSelectItem) => {
    if (element.checked) {
      checkedKeys.push(element.key);
    }

    if (Array.isArray(element.children)) {
      element.children.forEach(child => processElement(child));
    }
  };

  items.forEach(element => processElement(element));

  return checkedKeys;
}

export function getEndpointsCheckedKeys(items: HelpersInterface.ITressSelectItem[]): number[] {
  const checkedKeys: number[] = [];

  const processElement = (element: HelpersInterface.ITressSelectItem, isChild = false) => {
    if (isChild && element.checked) {
      checkedKeys.push(+element.key);
    }

    if (Array.isArray(element.children)) {
      element.children.forEach(child => processElement(child, true));
    }
  };

  items.forEach(element => processElement(element));

  return checkedKeys;
}


export function getNumberCheckedKeys(items: HelpersInterface.ITressSelectItem[]): number[] {
  const checkedKeys: number[] = [];

  const processElement = (element: HelpersInterface.ITressSelectItem) => {
    if (element.checked) {
      checkedKeys.push(+element.key);
    }

    if (Array.isArray(element.children)) {
      element.children.forEach(child => processElement(child));
    }
  };

  items.forEach(element => processElement(element));

  return checkedKeys;
}

export function getModifiedEndpointList(items: HelpersInterface.ITressSelectItem[]): number[] {
  const array = getEndpointsCheckedKeys(items);
  const modifiedDSPList = [...array];

  return modifiedDSPList;
}

export function updateCheckedStatus(
  items: HelpersInterface.ITressSelectItem[],
  keysToCheck: number[]
): HelpersInterface.ITressSelectItem[] {
  const updateItem = (item: HelpersInterface.ITressSelectItem): boolean => {
    if (item.children && item.children.length > 0) {
      // Рекурсивно оновлюємо статус checked для всіх дочірніх елементів
      item.children.forEach(updateItem);
      // Перевіряємо, чи всі дочірні елементи відмічені
      const allChildrenChecked = item.children.every((child) => child.checked);
      // Встановлюємо статус checked для батьківського елемента
      item.checked = allChildrenChecked;
    }
    // Перевіряємо, чи ключ елемента знаходиться в масиві keysToCheck
    const shouldBeChecked = keysToCheck.includes(Number(item.key));
    item.checked = shouldBeChecked || item.checked;
    return shouldBeChecked;
  };

  // Проходимося по кожному елементу та оновлюємо його статус checked
  items.forEach(updateItem);

  return items;
}