import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NzSelectModule } from 'ng-zorro-antd/select';

import { TimezoneComponent } from './timezone.component';

@NgModule({
  declarations: [TimezoneComponent],
  exports: [TimezoneComponent],
  imports: [
    CommonModule,
    NzSelectModule,
    FormsModule,
    ReactiveFormsModule,
  ]
})
export class TimezoneModule { }
