import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { PartnerInterfaceGlobal } from '../../models';

@Injectable({
  providedIn: 'root'
})
export class PartnerTitleService {

  constructor(
    private titleService: Title,
  ) { }

  public setPartnerTitle(partner: PartnerInterfaceGlobal.IPublicPartnerInfo): void {
    const title = partner.useTitle ? partner.title : this.capitalize(partner.name);
    this.titleService.setTitle(title + ' Ad Exchange');
  }

  private capitalize(str: string): string {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
}
