import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzButtonModule } from 'ng-zorro-antd/button';

import { JsAcceptDialogComponent } from './js-accept-dialog.component';

@NgModule({
  declarations: [JsAcceptDialogComponent],
  exports: [JsAcceptDialogComponent],
  imports: [
    CommonModule,
    NzModalModule,
    NzButtonModule,
  ]
})
export class JsAcceptDialogModule { }
