import { Component, OnInit, Inject, AfterViewInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ActivatedRoute, ActivationEnd, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';

import {
  AuthService,
  NetworkService,
  ScanProgressService,
} from './core/services';
import { IScanState } from './core/interfaces';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit {
  public scanState: IScanState;
  public showScanProgressBanner = true;
  public partner: {
    name: string;
    title: string
    useTitle: number;
    loginLogo: string;
    headerLogo: string;
    favicon: string;
  } = {
      name: 'test2',
      title: '',
      useTitle: 0,
      loginLogo: '/assets/img/logo.svg',
      headerLogo: '/assets/img/logo.svg',
      favicon: '',
    };

  public domainVerified = false;

  constructor(
    private authService: AuthService,
    @Inject(DOCUMENT) private _document: Document,
    private router: Router,
    private route: ActivatedRoute,
    private scanProgressService: ScanProgressService,
    private networkService: NetworkService,
    private titleService: Title,
  ) {
    this.router.events.subscribe((ev) => {
      if (ev instanceof ActivationEnd) {
        this.showScanProgressBanner = !this.route.snapshot.firstChild.data.hideScanProgress;
      }
    });

    this.scanProgressService.scanState$
      .subscribe((res) => {
        this.scanState = res;
      });
  }

  public ngOnInit() {
    this.getPartnerPublicInfo();
    this.networkService.createOnline();
  }

  public ngAfterViewInit() {
    let loader = this._document.getElementById('loader');
    if (loader) {
      loader.style.display = 'none';
    }
  }

  private capitalize(str: string): string {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  private getPartnerPublicInfo(): void {
    this.authService.getPartnerPublicInfo()
      .subscribe({
        next: (resp) => {
          this.domainVerified = true;
          this.partner = resp.data;
          if (this.partner.favicon) {
            const faviconElement = this._document.getElementById('appFavicon');
            if (faviconElement) {
              faviconElement.setAttribute('href', this.partner.favicon);
            }
          }

          const title = this.partner.useTitle ? this.partner.title : this.capitalize(this.partner.name);
          this.titleService.setTitle(title + ' Ad Exchange');
        },
        error: (err) => {
          this.domainVerified = false;
          // Additional error handling logic if necessary
          console.error('Error fetching partner public info:', err);
        },
      });
  }

}
