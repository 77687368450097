import { UserRole } from '../../enum';

export class UserLogin {
  constructor(
    public email: string = '',
    public password: string = '',
    public remember: number = 1,
  ) { }

}


export class User {
  id: number;
  active: number;
  deletedAt: string;
  partnerId: number;
  reportingForCompanyId: number | string;
  companyName: string;
  constructor(
    public name: string = '',
    public email: string = '',
    public role: string = UserRole.viewer,
    public lastLogin: string = '',
  ) { }
}

export interface UserLoginResponse {
  success: boolean;
  data: { token: string, user: User };
}

export interface UserListResponse {
  status: string;
  totat: number;
  data: User[];
}


export interface AccessLimitationGeneral {
  amount: string;
  created_at: string;
  id: number;
  invoice_id: number;
  overdue_days: number;
  partner_id: number;
  updated_at: string;
}