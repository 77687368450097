import { CUSTOM_ELEMENTS_SCHEMA, NgModule, ErrorHandler, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';

import { NZ_I18N } from 'ng-zorro-antd/i18n';
import { en_US } from 'ng-zorro-antd/i18n';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthTokenInterceptor } from '@core/interceptors';
import { AuthService, CustomErrorHandlerService } from '@core/services';
import { AuthGuard } from '@core/guards';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NotificationComponent, NotificationModule } from '@shared/components';
import { environment } from '@environment/environment';
import { AppConfig } from '@core/configs';
import { FormsModule } from '@angular/forms';

const initializeApp = (appConfigService: AppConfig) => {
  return (): Promise<any> => {
    return appConfigService.init();
  };
};

function checkEnv() {
  if (environment.isEnableErrorHandler) {
    return { provide: ErrorHandler, useClass: CustomErrorHandlerService };
  }

  return { provide: ErrorHandler, useClass: ErrorHandler };
}

registerLocaleData(en);

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NzNotificationModule,
    HttpClientModule,
    NgxSpinnerModule,
    NotificationModule,
    NzModalModule,
    FormsModule,
    InfiniteScrollModule,
  ],
  providers: [
    checkEnv(),
    AppConfig,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [AppConfig],
      multi: true,
    },
    { provide: NZ_I18N, useValue: en_US },
    { provide: HTTP_INTERCEPTORS, useClass: AuthTokenInterceptor, multi: true },
    AuthGuard,
    AuthService,
    NotificationComponent,
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
