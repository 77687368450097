import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzIconModule } from 'ng-zorro-antd/icon';

import { SelectCheckboxComponent } from './select-checkbox.component';
import { PlaceholderModule } from '../placeholder';
import { PipesModule } from './../../pipes/pipes.module';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ScrollTriggerModule } from '../../directives';

@NgModule({
  declarations: [SelectCheckboxComponent],
  exports: [SelectCheckboxComponent],
  imports: [
    CommonModule,
    FormsModule,
    NzToolTipModule,
    PlaceholderModule,
    NzInputModule,
    NzIconModule,
    PipesModule,
    NzToolTipModule,
    NzCheckboxModule,
    InfiniteScrollModule,
    ScrollTriggerModule
  ]
})
export class SelectCheckboxModule { }
