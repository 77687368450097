<div class="confirm-dialog">
  <div class="confirm-dialog__top" *nzModalTitle>
    <div class="title">{{data.title}}</div>
  </div>
  <div class="confirm-dialog__content">
    <input nz-input class="form-control" type="text" placeholder="Preset name" [(ngModel)]="data.text">
    <div class="confirm-dialog__content--error" *ngIf="errorMessage" [textContent]="errorMessage"></div>
  </div>
  <div *nzModalFooter class="button-footer">
    <button type="submit" nz-button nzType="primary" (click)="onConfirm()">Save</button>
    <button type="button" nz-button nzType="default" (click)="onCancel()">Close</button>
  </div>
</div>