import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzIconModule } from 'ng-zorro-antd/icon';

import { PlaceholderModule } from '../placeholder';
import { PipesModule } from './../../pipes/pipes.module';

import { SelectCheckboxControlComponent } from './select-checkbox-control.component';


@NgModule({
  declarations: [SelectCheckboxControlComponent],
  exports: [SelectCheckboxControlComponent],
  imports: [
    CommonModule,
    FormsModule,
    NzToolTipModule,
    NzInputModule,
    NzIconModule,
    NzCheckboxModule,
    PlaceholderModule,
    PipesModule,
    ReactiveFormsModule,
  ]
})
export class SelectCheckboxControlModule { }
