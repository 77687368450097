import { Injectable } from '@angular/core';

import { AuthService, FillStateService } from '../../services';
import { UserRole } from '@core/enum';

@Injectable()
export class AppConfig {
  constructor(
    private authService: AuthService,
    private fillStateService: FillStateService,
  ) { }

  async init(): Promise<void> {
    return new Promise<void>(async (resolve) => {
      if (this.authService.isAuthenticated() && this.authService.userRole !== UserRole.external) {
        this.fillStateService.handle();
        try {
          await this.authService.check().toPromise();
        } catch (error) { }
      }

      setTimeout(() => resolve(), 100);
    });
  }
}
