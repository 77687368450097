<div class="legend">
  <div class="legend-table">
    <div class="legend-table__content">
      <div class="legend-table__header header-content">
        <div class="header-content__item"></div>
        <div class="header-content__item">Value</div>
      </div>
      <div class="legend-table__body body-content">
        <div class="body-content__items" *ngFor="let item of legendItems">
          <div class="body-content__item item-name">
            <div class="item-name__row">
              <span nz-icon nzType="minus" nzTheme="outline" [style.color]="item.color" [style.font-size]="'22px'"
                class="icon"></span>
              <span class="name">{{getRawValue(item)}}</span>
            </div>
          </div>
          <div class="body-content__item item-value">{{modifyRowValue(item.value)}}</div>
        </div>
      </div>
    </div>
  </div>
</div>