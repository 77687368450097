import { Injectable } from '@angular/core';

import { AlertsService } from '@modules/alerts/services';
import { alertForm } from '../../constants';
import { NotificationService } from '../notification';
import { ErrorsHandlingService } from '../errors-handling';

@Injectable({
  providedIn: 'root'
})
export class FillStateService {

  constructor(
    private readonly alertsService: AlertsService,
    private notificationService: NotificationService,
    private errorsHandlingService: ErrorsHandlingService,
  ) { }


  public handle(): void {
    this.alertsService.getAlerts({}).subscribe(res => {
      if (res.firstAlert) {
        const { date } = res.firstAlert;
        alertForm.get('date').patchValue([new Date(date), new Date()]);
      } else {
        alertForm.get('date').patchValue([new Date(), new Date()]);
      }
    },
      error => {
        if (this.notificationService.templateRef) {
          this.errorsHandlingService.handleError(error);
        }
      });
  }
}
