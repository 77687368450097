import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzIconModule } from 'ng-zorro-antd/icon';

import { CheckboxCompaniesGroupComponent } from './checkbox-companies-group.component';
import { PipesModule } from '@shared/pipes/pipes.module';
@NgModule({
  declarations: [CheckboxCompaniesGroupComponent],
  exports: [CheckboxCompaniesGroupComponent],
  imports: [
    CommonModule,
    NzCheckboxModule,
    FormsModule,
    NzInputModule,
    NzIconModule,
    PipesModule,
    NzToolTipModule
  ]
})
export class CheckboxCompaniesGroupModule { }
