export interface TMTSspEndpoint {
  id: number;
  name: string;
  ssp_settings: { id: number, name: string, active: number, useTMT: number }[]
}

export interface TMTDspEndpoint {
  id: number;
  name: string;
  dsp_settings: { id: number, name: string, active: number, allowedSitesCount: number, blockedSitesCount: number }[];
}

export interface PMSspEndpoint {
  id: number;
  name: string;
  ssp_settings: { id: number, name: string, active: number, usePMKey: number }[]
}

export interface PMDspEndpoint {
  id: number;
  name: string;
  dsp_settings: { id: number, name: string, active: number, has_pm_postbid: number }[]
}

export interface PMPreBidSspEndpoint {
  id: number;
  name: string;
  ssp_settings: { id: number, name: string, active: number, usePMPrebid: number, pmIsFraud: number }[]
}

export interface PMPreBidDspEndpoint {
  id: number;
  name: string;
  dsp_settings: { id: number, name: string, active: number, pmPrebidSuspiciousIVT: number, pmPrebidFraudIVT: number }[]
}

export interface ForensiqSspCompany {
  id: number;
  name: string;
  ssp_settings: { id: number, name: string, active: number, useForensiq: number }[];
}

export interface ForensiqDspEndpoint {
  id: number;
  name: string;
  dsp_settings: any[];
}

export interface PixalataPreBidSspEndpoint {
  id: number;
  name: string;
  ssp_settings: {
    id: number, name: string, active: number, is_pixalate_postbid?: number, pixalate_pb_settings: {
      ip: number;
      ip_percent: number;
      ifa: number;
      ifa_percent: number;
      ua: number;
      ott: number;
      ott_percent: number;
      def_app: number;
      dc_subnet: number;
      domain: number;
      hr_app: number;
      hr_app_percent: number;
    }
  }[]
}

export interface PixalataPreBidDspEndpoint {
  active: number;
  id: number;
  name: string;
  dsp_settings: {
    id: number, name: string, active: number, pixalate_pb_settings: {
      ip: number;
      ip_percent: number;
      ifa: number;
      ifa_percent: number;
      ua: number;
      dc_subnet: number;
      def_app: number;
      domain: number;
      hr_app: number;
      hr_app_percent: number;
      ott: number;
      ott_percent: number;
    }
  }[]
}

export interface PixalatePostBidSspEndpoint {
  id: number;
  name: string;
  ssp_settings: { id: number, name: string, active: number, is_pixalate_postbid: number }[]
}

export interface PixalatePostBidDspEndpoint {
  id: number;
  name: string;
  dsp_settings: { id: number, name: string, active: number, is_pixalate_postbid: number }[]
}

export interface GeoEdgeSSPCompany {
  id: number;
  name: string;
  ssp_settings: GeoEdgeSSPEndpoint[]
}

export interface GeoEdgeSSPEndpoint {
  id: number;
  name: string;
  active: number;
  useGeoedge: number;
  geoedge_key: string;
}

export interface GeoedgeDspEndpoint {
  id: number;
  name: string;
  dsp_settings: any[];
}

export interface BotmanPostBidSsp {
  companies: BotmanPostBidSspEndpoint[];
  is_all_selected: boolean;
}

export interface BotmanPostBidDsp {
  companies: BotmanPostBidDspEndpoint[];
  is_all_selected: boolean;
}

export interface BotmanPostBidSspEndpoint {
  id: number;
  name: string;
  ssp_settings: { id: number, name: string, active: number, enable_postbid: number | boolean }[]
}

export interface BotmanPostBidDspEndpoint {
  id: number;
  name: string;
  dsp_settings: { id: number, name: string, active: number, enable_postbid: number | boolean }[]
}

export interface BotmanPreBidSsp {
  companies: BotmanPreBidSspCompany[];
  is_all_selected: boolean;
}

export interface BotmanPreBidDsp {
  companies: BotmanPreBidDspEndpoint[];
  is_all_selected: boolean;
}

export interface BotmanPreBidSspCompany {
  id: number;
  name: string;
  ssp_settings: BotmanPreBidSspEndpoint[]
}

export interface BotmanPreBidSspEndpoint {
  id: number;
  name: string;
  enable_prebid: number | boolean;
  active: number; is_fraud?: number
}

export interface BotmanPreBidDspCompany {
  id: number;
  name: string;
  dsp_settings: BotmanPreBidDspEndpoint[]
}

export interface BotmanPreBidDspEndpoint {
  id: number;
  name: string;
  active: number;
  enable_prebid: number;
  allow_fraud?: any;
}