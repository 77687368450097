export enum ConnectionTypeEnum {
  RTB,
  VAST,
  JS = 3,
  Prebid = 4,
  'Prebid.js' = 5,
}

export enum RtbConnectionMode {
  All = 0,
  Rtb = 2,
  Vast = 1,
  Js = 3,
}