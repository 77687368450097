import { Component, Input } from '@angular/core';

enum ChartTypes {
  'Source' = 'source',
  'Performance' = 'performance',
}

type ChartLegend = {
  color: string;
  enabled: boolean;
  id: number;
  value: number;
  name: string;
  inventory_key?: string
}

import { getModifiedRowValue } from '@core/helpers';

@Component({
  selector: 'app-pie-chart-legend',
  templateUrl: './pie-chart-legend.component.html',
  styleUrls: ['./pie-chart-legend.component.scss']
})
export class PieChartLegendComponent {
  @Input() public set legendItems(value: ChartLegend[]) {
    this._legendItems = value;
  }

  public get legendItems(): ChartLegend[] {
    return this._legendItems;
  }

  @Input() public set chartType(value: string) {
    this._chartType = value;
  }

  public get chartType(): string {
    return this._chartType;
  }

  public tableHeight = '350px';

  private _legendItems: ChartLegend[];
  private _chartType = 'performance';

  constructor() { }

  public getRawValue(item: ChartLegend): string {
    return this.chartType === ChartTypes['Source'] ? item.inventory_key : item.name;
  }

  public modifyRowValue(rowValue: number): string {
    return getModifiedRowValue(rowValue);
  }
}
