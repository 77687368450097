import { HttpParams } from '@angular/common/http';

export class HttpUtils {

  static formatRequestParams<Type>(params: Type): FormData {
    const formData: FormData = new FormData();

    Object.keys(params).forEach(key => {
      formData.append(key, params[key]);
    });

    return formData;
  }

  static getRequestParams(params: object): HttpParams {
    const propNames = Object.getOwnPropertyNames(params);

    propNames.forEach(elem => {
      if (Array.isArray(params[elem])) {
        params[elem] = params[elem].join(',');
      }

      if (params[elem] === null || params[elem] === undefined || params[elem] === '') {
        delete params[elem];
      }
    });

    return new HttpParams({ fromObject: params as any });
  }
}