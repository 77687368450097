import { SSP } from '../ssp';
import { DSP } from '../dsp';

export class Company {
  id: number;
  name: string;
  domain?: string;
  partnerId?: string;
  dsp_settings?: DSP[];
  ssp_settings?: SSP[];
  sspSummary?: SSP;
  dspSummary?: DSP;
  contact?: string;
  terms = 30;
  email?: string;
  start?: Date | string;
  invoice?: string;
  payment?: string;
  dspApiUrl?: string;
  sspApiUrl?: string;
  date?: string;
  cookie_sync?: number | boolean;
  company_sync_url?: string;
  sync_url?: string;
  sync_url_iframe?: string;
  cookie_storage?: string;
  user_requests?: number;
  unique_users?: number;
}


export interface ListTypeApiEndpoints {
  ssp: {
    'blocked-crids': string;
  };
  dsp: {
    bundles: string;
    sites: string;
    publishers: string;
    'blocked-publishers': string;
    'blocked-bundles': string;
    'blocked-sites': string;
    'blocked-ips': string;
  };
}

export type TypeApiEndpoint = { 'blocked-crids': string; } | {
  bundles: string;
  sites: string;
  publishers: string;
  'blocked-publishers': string;
  'blocked-bundles': string;
  'blocked-sites': string;
  'blocked-ips': string;
};

export interface ListTypeParam {
  ssp: {
    'blocked-crids': string;
  };
  dsp: {
    bundles: string;
    sites: string;
    publishers: string;
    'blocked-publishers': string;
    'blocked-bundles': string;
    'blocked-sites': string;
    'blocked-ips': string;
  };
}

export type TypeParam = { 'blocked-crids': string; } | {
  bundles: string;
  sites: string;
  publishers: string;
  'blocked-publishers': string;
  'blocked-bundles': string;
  'blocked-sites': string;
  'blocked-ips': string;
};

export interface CompanyResponse {
  status: string;
  data: Company[];
}

export interface CompanyResponseSingle {
  status: string;
  data: Company;
}

export interface CompanyWithChildren {
  ssps: { company: Company; children: SSP[] }[];
  dsps: { company: Company; children: DSP[] }[];
}

export interface SingleCompany {
  id: number;
  name: string;
}

export interface SingleCompanyResponce {
  data: SingleCompany;
}

export class ApiLinkTestResponse {
  body!: string;
  contentType!: string;
  link!: string;
  statusCode!: number;
}
