import { NzSelectModule } from 'ng-zorro-antd/select';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NzModalModule } from 'ng-zorro-antd/modal';

import { ListAllowedBlockedDialogComponent } from './list-allowed-blocked-dialog.component';
import { NzButtonModule } from 'ng-zorro-antd/button';

@NgModule({
  declarations: [ListAllowedBlockedDialogComponent],
  exports: [ListAllowedBlockedDialogComponent],
  imports: [
    CommonModule,
    NzModalModule,
    FormsModule,
    NzSelectModule,
    NzButtonModule
  ]
})
export class ListAllowedBlockedDialogModule { }
