import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzTableModule } from 'ng-zorro-antd/table';

import { AlertsDrawerComponent } from './alerts-drawer.component';
import { NzSelectModule } from 'ng-zorro-antd/select';

@NgModule({
  declarations: [AlertsDrawerComponent],
  exports: [AlertsDrawerComponent],
  imports: [
    CommonModule,
    FormsModule,
    NzDrawerModule,
    NzDividerModule,
    NzButtonModule,
    NzInputModule,
    NzIconModule,
    NzTableModule,
    NzSelectModule,
  ]
})
export class AlertsDrawerModule { }
