import { AfterContentInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { TrafficAnalyzerInterface } from '@core/interfaces';
import { findDifferentBetweenEndpointsArrays } from '@core/helpers';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-select-checkbox-control',
  templateUrl: './select-checkbox-control.component.html',
  styleUrls: ['./select-checkbox-control.component.scss']
})
export class SelectCheckboxControlComponent implements OnInit, AfterContentInit {
  @Input() public set allChecked(value: boolean) {
    this._allChecked = value;
  }

  public get allChecked(): boolean {
    return this._allChecked;
  }

  @Input() public set items(value: TrafficAnalyzerInterface.Endpoints[]) {
    this._items = value;
  }

  public get items(): TrafficAnalyzerInterface.Endpoints[] {
    return this._items;
  }

  @Input() public set isDisabledAll(value: boolean) {
    this._isDisabledAll = value;
  }

  public get isDisabledAll(): boolean {
    return this._isDisabledAll;
  }

  @Input() control: FormControl = new FormControl();

  @Output() changedItems = new EventEmitter<{ items: number[], isAll: boolean, hasChanges: boolean }>();

  searchValue: string;
  arrayForSearch: TrafficAnalyzerInterface.Endpoints[] = [];
  initArray: TrafficAnalyzerInterface.Endpoints[] = [];

  checkedItems: number[] = [];
  outputData: { isAll: boolean, items: number[], hasChanges: boolean } = { isAll: false, items: [], hasChanges: false };
  listHeight = 'auto';
  listMaxHeight = '800px';

  private _allChecked = false;
  private _items: TrafficAnalyzerInterface.Endpoints[] = [];
  private _isDisabledAll = false;

  ngOnInit(): void {
    this.arrayForSearch = [...this.items];
    this.initArray = JSON.parse(JSON.stringify(this.items));
  }

  ngAfterContentInit(): void {
    this.listHeight = window.innerHeight - 320 + 'px';
    window.addEventListener('resize', () => {
      this.listHeight = window.innerHeight - 320 + 'px';
    });
  }

  updateAllChecked(): void {
    if (this.allChecked) {
      this.items = this.items.map(item => ({
        ...item,
        checked: true,
        disabled: true,
      }));
    } else {
      this.items = this.items.map(item => ({
        ...item,
        checked: false,
        disabled: false,
      }));
    }

    this.checkedItems = this.items.filter(item => item.checked).map(item => item['value']);

    this.outputData = {
      isAll: this.allChecked,
      items: this.checkedItems,
      hasChanges: findDifferentBetweenEndpointsArrays(this.items, this.initArray).length > 0 ? true : false
    };

    this.changedItems.emit(this.outputData);
  }

  updateSingleChecked(): void {
    if (this.items.every(item => !item.checked)) {
      this.allChecked = false;
    } else if (this.items.every(item => item.checked)) {
      this.allChecked = true;
    } else {
      this.allChecked = false;
    }

    this.checkedItems = this.items.filter(item => item.checked).map(item => item['value']);


    this.isDisabledAll = this.items.every(item => item.checked);

    this.outputData = {
      isAll: this.allChecked,
      items: this.checkedItems,
      hasChanges: findDifferentBetweenEndpointsArrays(this.items, this.initArray).length > 0 ? true : false
    };

    this.changedItems.emit(this.outputData);
  }

  onSearchInput(event: Event): void {
    this.searchValue = (event.target as HTMLInputElement).value;
  }
}
