export interface ITressSelectItem {
  title: string;
  key: string;
  checked: boolean;
  isLeaf?: boolean;
  children?: ITressSelectItem[];
}

export interface IFilterListsItems {
  item_group: string;
  items: IFilterListsItem[];
}

export interface IFilterListsItem {
  id: number;
  name: string;
  group: string;
  count: number;
  is_all_ssp: number;
  is_all_dsp: number;
}

export type Stringable = number | string;

export interface SelectItemHelper<K = Stringable, T = Stringable> {
  id: K;
  name: T;
}