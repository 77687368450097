import { Pipe, PipeTransform } from '@angular/core';
import { FilterListInterface } from '@core/interfaces';

@Pipe({
  name: 'tableSearch',
})
export class TableSearchPipe implements PipeTransform {

  transform(lists: FilterListInterface.FilterList[], search = ''): FilterListInterface.FilterList[] {
    if (!search.trim()) {
      return lists;
    }
    return lists.filter(list => {
      return list.name.toLowerCase().includes(search.toLowerCase())
        || list.type.toLowerCase().includes(search.toLowerCase())
        || list.record_type.toLowerCase().includes(search.toLowerCase())
        || list.create_at.toLowerCase().includes(search.toLowerCase())
        || list.updated_at.toLowerCase().includes(search.toLowerCase());
    });
  }

}
