export const sizes = {
  toggle: 40,
  id: 70,
  active: 75,
  name: 110,
  region: 110,
  qps: 110,
  bidqps: 110,
  bidQPS: 110,
  daylySpend: 110,
  defaultTmax: 85,
  defaultBidfloor: 85,
  useGeoedge: 55,
  useTMT: 55,
  type: 90,
  scanner: 160,
  margin: 90,
  ad_formats: 200,
  spendLimit: 100,
  comments: 115,
  endpoint: 110,
  mobileWeb: 55,
  inApp: 55,
  desktop: 55,
  intstlOnly: 55,
  pop: 55,
  marga: 75,
  nurl: 55,
  limitQPS: 110,
  realQPS: 110,
  email: 110,
  cookieSyncPanel: 40,
  settings: 40,
  statistics: 40,
  winrate: 100,
  fill_rate: 110,
  win_rate: 115,
  total_bid_rate: 145,
  render_rate: 130,
  valid_bid_rate: 140,
  formats: 200
};
