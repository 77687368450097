import { FormControl, FormGroup } from '@angular/forms';

import { startOfDay, endOfDay } from 'date-fns';

export const alertForm = new FormGroup({
  date: new FormControl([startOfDay(new Date()), endOfDay(new Date())]),
  is_read: new FormControl(-1),
  type: new FormControl(-1),
  region: new FormControl(-1),
  page: new FormControl(1),
  per_page: new FormControl(25),
});