import { Injectable } from '@angular/core';

import { ErrorsHandlingService } from '../errors-handling';

@Injectable({
  providedIn: 'root'
})
export class ViewerRestrictionService {

  constructor(
    private errorsHandlingService: ErrorsHandlingService,
  ) { }


  public restrictUserViewer(): void {
    this.errorsHandlingService.showNotification(
      'error',
      'error',
      'error',
      'Action not allowed: you have view-only permission and cannot make any changes.',
      null
    );
    return;
  }
}
