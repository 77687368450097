import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  ChangeDetectorRef,
  ChangeDetectionStrategy
} from '@angular/core';

import { TableConfig, SellersJsonTableData } from '@core/interfaces';

import { UserRole } from '@core/enum';

@Component({
  selector: 'app-reusable-editable-table',
  templateUrl: './reusable-editable-table.component.html',
  styleUrls: ['./reusable-editable-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReusableEditableTableComponent implements OnChanges {
  @Input() public set tableConfig(value: TableConfig.IDataTableConfig) {
    this._tableConfig = value;
  }

  public get tableConfig(): TableConfig.IDataTableConfig {
    return this._tableConfig;
  }

  @Input() public set tableData(value: SellersJsonTableData[]) {
    this._tableData = value;
  }

  public get tableData(): SellersJsonTableData[] {
    return this._tableData;
  }

  @Input() public set pageSize(value: number) {
    this._pageSize = value;
  }

  public get pageSize(): number {
    return this._pageSize;
  }

  @Input() public set pageSizeOptions(value: number[]) {
    this._pageSizeOptions = value;
  }

  public get pageSizeOptions(): number[] {
    return this._pageSizeOptions;
  }

  @Input() public set pageIndex(value: number) {
    this._pageIndex = value;
    this.cd.markForCheck();
  }

  public get pageIndex(): number {
    return this._pageIndex;
  }

  @Input() public set total(value: number) {
    this._total = value;
  }

  public get total(): number {
    return this._total;
  }

  @Input() public set isShowFrontPagination(value: boolean) {
    this._isShowFrontPagination = value;
  }

  public get isShowFrontPagination(): boolean {
    return this._isShowFrontPagination;
  }

  @Input() public set isShowPagination(value: boolean) {
    this._isShowPagination = value;
  }

  public get isShowPagination(): boolean {
    return this._isShowPagination;
  }

  @Input() public set isShowSizeChanger(value: boolean) {
    this._isShowSizeChanger = value;
  }

  public get isShowSizeChanger(): boolean {
    return this._isShowSizeChanger;
  }

  @Input() public set sortBy(value: string) {
    this._sortBy = value;
  }

  public get sortBy(): string {
    return this._sortBy;
  }

  @Input() public set sortOrder(value: string) {
    this._sortOrder = value;
  }

  @Input() public set tableHeight(value: string) {
    this._tableHeight = value;
  }

  @Input() public set editCache(value: { [key: string]: { edit: boolean; data: SellersJsonTableData } }) {
    this._editCache = value;
  }

  public get editCache(): { [key: string]: { edit: boolean; data: SellersJsonTableData } } {
    return this._editCache;
  }

  public get tableHeight(): string {
    return this._tableHeight;
  }

  public get sortOrder(): string {
    return this._sortOrder;
  }

  @Input() public set userRole(value: string) {
    this._userRole = value;
  }

  public get userRole(): string {
    return this._userRole;
  }

  @Input() public set partnerId(value: number) {
    this._partnerId = value;
  }

  public get partnerId(): number {
    return this._partnerId;
  }

  public tbodyHeight = 'auto';

  public userRoleEnum = UserRole;

  @Output() actionClicked = new EventEmitter<{ item: SellersJsonTableData; action: string; }>();
  @Output() tableSort = new EventEmitter<string>();
  @Output() changePageIndex = new EventEmitter<number>();
  @Output() changePageSize = new EventEmitter<number>();

  isPageSizeTriggering = false;

  private _tableConfig: TableConfig.IDataTableConfig;
  private _tableData: SellersJsonTableData[];
  private _pageSize = 25;
  private _pageSizeOptions: number[] = [5, 10, 15, 25, 50, 100];
  private _pageIndex = 1;
  private _total = 100;
  private _isShowFrontPagination = false;
  private _isShowPagination = false;
  private _isShowSizeChanger = false;
  private _sortBy: string;
  private _sortOrder: string;
  private _tableHeight = 'auto';
  private _userRole: string;
  private _editCache: { [key: string]: { edit: boolean; data: SellersJsonTableData } } = {};
  private _partnerId: number;

  constructor(private cd: ChangeDetectorRef) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.pageIndex && changes.pageSize) {
      this.pageIndex = 1;
    }
  }

  public onChangePageSize(pageSize: number): void {
    this.pageIndex = 1;
    this.changePageSize.emit(pageSize);
    this.cd.detectChanges();
  }

  public onChangePageIndex(pageIndex: number): void {
    this.changePageIndex.emit(pageIndex);
  }


  public onRowActionClicked(rowData: SellersJsonTableData, rowAction: string): void {
    const eventData = {
      item: rowData,
      action: rowAction,
    };

    this.actionClicked.emit(eventData);
  }

  public sort(columnName: string): void {
    this.tableSort.emit(columnName);
  }
}
