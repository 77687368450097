import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';

import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthService } from '@core/services';

@Injectable()
export class AuthTokenInterceptor implements HttpInterceptor {
  constructor(private auth: AuthService, private router: Router) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // add authorization header with jwt token if available
    if (this.auth.token) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${this.auth.token}`,
        },
      });
    }


    return next.handle(request).pipe(catchError((error) => {
      this.handleAuthError(error);
      return of(error);
    }) as any);
  }

  private handleAuthError(error: HttpErrorResponse) {
    if (error.status === 503) {
      this.router.navigate(['/technical-works']);
    }

    if (error.status === 401) {
      this.router.navigate(['/login']);
    }
    throw error;
  }
}
