import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { QpsHeadItemComponent } from './qps-head-item.component';

@NgModule({
  declarations: [QpsHeadItemComponent],
  exports: [QpsHeadItemComponent],
  imports: [
    CommonModule
  ]
})
export class QpsHeadItemModule { }
