<div class="warning-dialog">
  <div class="warning-dialog__content">
    <div class="warning-dialog__title" *nzModalTitle>{{ data.title }}</div>
    <div class="warning-dialog__text" [innerHTML]="data.message"></div>
  </div>
  <div *nzModalFooter class="modal-footer">
    <button type="button" nz-button nzType="primary" (click)="onConfirm()">
      OK
    </button>
    <button type="button" nz-button nzType="default" (click)="onCancel()">
      Close
    </button>
  </div>
</div>
