import { Component, Inject, OnInit } from '@angular/core';
import { NZ_MODAL_DATA, NzModalRef } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-js-accept-dialog',
  templateUrl: './js-accept-dialog.component.html',
  styleUrls: ['./js-accept-dialog.component.scss']
})
export class JsAcceptDialogComponent implements OnInit {
  title: string;

  constructor(
    @Inject(NZ_MODAL_DATA) public data: { side: string },
    private dialogRef: NzModalRef<JsAcceptDialogComponent>
  ) { }

  ngOnInit(): void {
    this.title = this.data.side === 'ssp'
      ? 'We recommend you to work via JS tags with trusted partners only (preferably direct publishers).'
      : 'We recommend you to work via JS tags with your partners based on your numbers and tend to set strict caps.';
  }

  onCancel(): void {
    this.dialogRef.close({ action: 'cancel' });
  }

  onAccept(): void {
    this.dialogRef.close({ action: 'accept' });
  }

}
