<div class="content-list__top">
  <nz-input-group nzPrefixIcon="search">
    <input [(ngModel)]="searchValue" name="bundles" id="bundles" (input)="onSearchInput($event)" type="text" nz-input
      placeholder="Search..." />
  </nz-input-group>
</div>
<div class="checkboxes-content" [style.height]="listHeight" [style.max-height]="listMaxHeight">
  <div class="checkboxes-list__every">
    <label nz-checkbox [(ngModel)]="allChecked" [nzDisabled]="isAllDisabled" (ngModelChange)="updateAllChecked()">
      All Companies
    </label>
  </div>
  <div class="checkboxes-list" *ngFor="let company of companies | searchSelect : searchValue">
    <div class="checkboxes-list__all"
      [ngStyle]="{ 'border-bottom': isClosed(company.id) === 'visible' ? '1px solid #a8b7c7' : 'none' }"
      (click)="toggleState(company.id)">
      <div class="item">
        <label [nzDisabled]="company.disabled" class="item-name" nz-checkbox [(ngModel)]="company.checked"
          (ngModelChange)="updateAllEndpointByCompanyChecked($event, company)"
          [nzIndeterminate]="company.indeterminate">
          {{company.name}}
        </label>
        <div class="arrow" [@toggleArrow]="isClosed(company.id)">
          <img src="/assets/img/ic_arrow_down.svg" alt="">
        </div>
      </div>
    </div>
    <ng-container *ngIf="isClosed(company.id) === 'visible'">
      <div class="checkboxes-company" *ngFor="let endpoint of company.endpoints, let isLast = last">
        <label class="item" nz-checkbox [(ngModel)]="endpoint.checked" [nzDisabled]="endpoint.disabled"
          [ngStyle]="{ 'border-bottom': isLast ? 'none' : '1px solid #a8b7c7', 'color': !endpoint.active ? '#A8B7C7' : '#000' }"
          (ngModelChange)="updateSingleEndpointByCompanyChecked($event, company, endpoint)" nz-tooltip
          nzTooltipOverlayClassName="item-tooltip" [nzTooltipTitle]="endpoint.label"
          nzTooltipPlacement="top">{{endpoint.label | trunc:[25]}}</label>
      </div>
    </ng-container>
  </div>
</div>