<div class="checkboxes-list" [style.height]="listHeight" [style.max-height]="listMaxHeight">
  <div class="checkboxes-list__all">
    <label [nzDisabled]="isDisabledAll" nz-checkbox [(ngModel)]="allChecked" (ngModelChange)="updateAllChecked()">All
      Events</label>
  </div>
  <div class="checkboxes" *ngFor="let item of items | search:searchValue; let isLast=last">
    <label class="item"
      [ngStyle]="{ 'border-bottom': isLast ? 'none' : '1px solid #a8b7c7', 'color': !item.active ? '#A8B7C7' : '#000' }"
      nz-checkbox [(ngModel)]="item.checked" [nzDisabled]="item.disabled" (ngModelChange)="updateSingleChecked()"
      nz-tooltip nzTooltipOverlayClassName="item-tooltip" [nzTooltipTitle]="item.label"
      nzTooltipPlacement="top">{{item.label |
      trunc:[25]}}</label>
  </div>
</div>