import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';

import { SelectCategoryChangeComponent } from './select-category-change.component';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { PipesModule } from '@shared/pipes/pipes.module';

@NgModule({
  declarations: [SelectCategoryChangeComponent],
  exports: [SelectCategoryChangeComponent],
  imports: [
    CommonModule,
    FormsModule,
    NzSelectModule,
    PipesModule,
    NzToolTipModule,
    NzModalModule
  ]
})
export class SelectCategoryChangeModule { }
