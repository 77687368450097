import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { catchError } from 'rxjs/operators';
import { BehaviorSubject, Observable, throwError as _throw } from 'rxjs';

import { format, differenceInCalendarDays } from 'date-fns';

import {
  AppOptionsService,
  DataManagerService,
  ErrorsHandlingService
} from '@core/services';

import { filterNormalize } from '@core/helpers';

import { AlertInterface } from '../models';
import { FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class AlertsService {
  public alertsCount = new BehaviorSubject(0);
  public alertsObs = this.alertsCount.asObservable();
  public alertsData = new BehaviorSubject<AlertInterface.IAlert[]>([]);
  public alertsDataObs = this.alertsData.asObservable();

  public alertsTypes: AlertInterface.IAlertType[] = [];
  public alertsTableData: AlertInterface.IAlert[] = [];
  public total = 100;

  public filter: AlertInterface.IAlertReqData = {
    from: format(new Date(), 'yyyy-MM-dd'),
    to: format(new Date(), 'yyyy-MM-dd'),
    is_read: -1,
    type: -1,
    page: 1,
    per_page: 25,
    region: 'US_EAST'
  };

  public disabledDate = (current: Date): boolean => {
    // Calculate the difference in days between the selected date and today
    const difference = differenceInCalendarDays(new Date(), current);

    // Allow dates only within the next 30 days
    return difference < 0 || difference > 30;
  };

  public readTypes: { id: boolean | number; name: string }[] = [
    { id: -1, name: 'All' },
    { id: true, name: 'Read' },
    { id: false, name: 'Unread' },
  ];

  private apiUrl: string;

  constructor(
    private http: HttpClient,
    private options: AppOptionsService,
    private errorsHandlingService: ErrorsHandlingService,
    private dataManagerService: DataManagerService,
  ) {
    this.apiUrl = this.options.getApiUrl();
  }

  public getAlertTypes(): void {
    this.dataManagerService.getFilters({ filters: ['bellAlertTypes'] })
      .subscribe(res => {
        for (const key in res.bellAlertTypes) {
          this.alertsTypes.push({ id: key, name: res.bellAlertTypes[key] });
        }

        this.alertsTypes.unshift({ id: -1, name: 'All Types' });
      },
        error => this.errorsHandlingService.handleError(error));
  }


  public getAlertsTableData(alertsForm: FormGroup): void {
    this.getAlerts(filterNormalize(this.setFilter(alertsForm)))
      .subscribe(res => {
        this.alertsTableData = res.data;
        this.total = res.totals;
        const alertsArray = res.data.length > 10 ? res.data.slice(0, 10) : res.data;
        this.alertsData.next(alertsArray);
      },
        error => this.errorsHandlingService.handleError(error));
  }

  public setFilter(alertForm: FormGroup): AlertInterface.IAlertReqData {
    const { is_read, type, page, per_page, date, region } = alertForm.value;
    const [from, to] = date;
    return {
      from: format(from, 'yyyy-MM-dd'),
      to: format(to, 'yyyy-MM-dd'),
      is_read,
      type,
      page,
      per_page,
      region
    };
  }

  public getAlerts(filter: Partial<AlertInterface.IAlertFilter>): Observable<AlertInterface.IAlertRes> {
    return this.http.post<AlertInterface.IAlertRes>(`${this.apiUrl}/bell-alerts`, filter)
      .pipe(catchError(this.errorsHandlingService.processError));
  }

  public setReadStatusForAlert(alertId?: number): Observable<{ success: string }> {
    const url = alertId ? `${this.apiUrl}/bell-alerts/set-read/${alertId}` : `${this.apiUrl}/bell-alerts/set-read`;

    return this.http.get<{ success: string }>(url)
      .pipe(catchError(this.errorsHandlingService.processError));
  }

  public setUserActionCount(value: number): Observable<{ success: boolean }> {
    return this.http.get<{ success: boolean }>(`${this.apiUrl}/bell-alerts/counts/${value}`)
      .pipe(catchError(this.errorsHandlingService.processError));
  }

  public readAlert(alertId: number, callback: () => void): void {
    this.setReadStatusForAlert(alertId)
      .subscribe(() => {
        this.alertsCount.next(this.alertsCount.value - 1);
        callback();
      },
        error => this.errorsHandlingService.handleError(error));
  }

  public readAllAlerts(callback: () => void): void {
    this.setReadStatusForAlert()
      .subscribe(() => {
        this.alertsCount.next(0);
        callback();
      },
        error => this.errorsHandlingService.handleError(error));
  }
}
