import { Component, OnInit, Inject } from '@angular/core';

import { finalize } from 'rxjs/internal/operators';

import { saveAs } from 'file-saver';
import { NgxSpinnerService } from 'ngx-spinner';
import { NZ_MODAL_DATA, NzModalRef } from 'ng-zorro-antd/modal';

import { AppOptionsService, DataManagerService, ErrorsHandlingService, NotificationService } from '@core/services';
import { NotificationComponent } from '../notification';

export enum ListType {
  Allowed = 'Allowed',
  Blocked = 'Blocked'
}

interface ListAllowedBlockedDialog {
  endpointType: string;
  endpoint: {
    active: number;
    allowedSitesCount: number;
    blockedSitesCount: number;
    id: number;
    name: string;
  };
  listType: string;
  filter: ListType;
  title: string;
}
@Component({
  selector: 'app-list-allowed-blocked-dialog',
  templateUrl: './list-allowed-blocked-dialog.component.html',
  styleUrls: ['./list-allowed-blocked-dialog.component.scss'],
})
export class ListAllowedBlockedDialogComponent implements OnInit {

  list: string[] = [];
  listAllowed: string[] = [];
  listBlocked: string[] = [];
  isFileUploading = false;
  listAsString = '';
  listAsStringAllowed = '';
  listAsStringBlocked = '';
  error = '';
  clickSaveButton = false;
  allowed = -1;

  allowedLimitItems = 20000;

  filters = [{ id: ListType.Allowed, name: ListType.Allowed }, { id: ListType.Blocked, name: ListType.Blocked }];
  filter = this.filters[0].id;

  constructor(
    @Inject(NZ_MODAL_DATA) public data: ListAllowedBlockedDialog,
    private dataManger: DataManagerService,
    private dialogRef: NzModalRef<ListAllowedBlockedDialogComponent>,
    public options: AppOptionsService,
    private loadingService: NgxSpinnerService,
    private notificationService: NotificationService,
    private notificationComponent: NotificationComponent,
    private readonly errorsHandlingService: ErrorsHandlingService,
  ) { }

  ngOnInit(): void {
    this.filter = this.data.listType as ListType;
    this.dataManger.getListAllowedBlocked(this.data.endpointType, this.data.endpoint.id.toString(), this.data.listType)
      .subscribe((data) => {
        for (const item in data.allowed) {
          this.listAsStringAllowed += data.allowed[item] + '\n';
          this.listAllowed.push(data.allowed[item]);
        }

        for (const item in data.blocked) {
          this.listAsStringBlocked += data.blocked[item] + '\n';
          this.listBlocked.push(data.blocked[item]);
        }
        this.changeAllowed(this.filter === ListType.Allowed ? 1 : 0);
      },
        error => this.errorsHandlingService.handleError(error));

    if (this.data.filter) {
      this.filter = this.filters.find((item) =>
        item.id.toLocaleLowerCase() === this.data.filter.toLocaleLowerCase()).id;
    }
  }

  close(): void {
    this.dialogRef.close();
  }

  saveData(): void {
    this.clickSaveButton = true;
    this.loadingService.show();
    let listType = null;

    if (this.allowed) {
      listType = this.getListType().allowed;
    } else {
      listType = this.getListType().blocked;
    }

    this.dataManger.setList(this.data.endpointType, this.data.endpoint.id.toString(), listType, this.list)
      .pipe(finalize(() => this.loadingService.hide()))
      .subscribe(() => {
        this.clickSaveButton = false;
        this.dialogRef.close({
          itemsCount: this.list.length,
          type: this.data.listType,
          list: this.list,
          allowedItems: this.listAllowed,
          blockedItems: this.listBlocked
        });
      },
        error => {
          this.errorsHandlingService.handleError(error);
          this.clickSaveButton = false;
        });
  }

  shouldClose(): boolean | void {
    if (this.isFileUploading && !confirm('File upload is still in progress, are you sure you want to stop it?')) {
      return false;
    }
    this.dialogRef.close({
      itemsCount: this.list.length,
      type: this.data.listType,
      list: this.list,
      allowedItems: this.listAllowed,
      blockedItems: this.listBlocked
    });
  }

  fileUpload(files: FileList): void {
    this.loadingService.show();
    this.isFileUploading = true;
    this.dataManger.parseFile(files.item(0))
      .pipe(finalize(() => this.loadingService.hide()))
      .subscribe((resp) => {
        for (const item in resp.items) {
          this.listAsString += resp.items[item] + '\n';
          this.list.push(resp.items[item]);
        }

        if (this.list.length > this.allowedLimitItems) {
          this.loadingService.hide();
          this.showNotification('error', 'error', 'error', 'Maximum elements for set up: 20000');
        }

        if (this.allowed) {
          this.listAllowed = this.list;
          this.listAsStringAllowed = this.listAsString;
        } else {
          this.listBlocked = this.list;
          this.listAsStringBlocked = this.listAsString;
        }

        this.isFileUploading = false;
        this.loadingService.hide();
      },
        error => {
          this.errorsHandlingService.handleError(error);
          this.isFileUploading = false;
        });
  }

  listDownload(): void {
    this.loadingService.show();
    this.dataManger.downLoadList(this.data.endpointType, this.data.endpoint.id.toString(), this.getListType()[this.getType()])
      .pipe(finalize(() => this.loadingService.hide()))
      .subscribe(
        response => {
          saveAs(response, this.data.listType + '.txt');
        },
        error => this.errorsHandlingService.handleError(error));
  }

  onListUpdated($event: Event): void {
    this.list = ($event.target as HTMLInputElement).value.split('\n').filter(i => i.trim().length > 0);

    if (this.list.length > this.allowedLimitItems) {
      this.showNotification('error', 'error', 'error', 'Maximum elements for set up: 20000');
    }

    if (this.allowed) {
      this.listAllowed = this.list;
      this.listAsStringAllowed = ($event.target as HTMLInputElement).value;
    } else {
      this.listBlocked = this.list;
      this.listAsStringBlocked = ($event.target as HTMLInputElement).value;
    }
  }

  changeAllowed(val: number): void {
    this.allowed = val;
    if (this.allowed === 1) {
      this.list = this.listAllowed;
      this.listAsString = this.listAsStringAllowed;
    } else {
      this.list = this.listBlocked;
      this.listAsString = this.listAsStringBlocked;
    }
  }

  getType(): 'allowed' | 'blocked' {
    return this.allowed ? 'allowed' : 'blocked';
  }

  getListType(): { allowed: string; blocked: string } {
    const types = {
      dsp: {
        sites: {
          allowed: 'sites', blocked: 'blocked-sites',
        }
      }
    };

    return types[this.data.endpointType][this.data.listType];
  }

  private showNotification(
    iconType: string,
    color: string,
    notificationType: string,
    description: string,
  ): void {
    const data = {
      iconType,
      color,
      notificationType,
      description,
    };

    this.notificationComponent.data = data;
    this.notificationService.showTemplate({ nzData: data });
  }
}
