export const CardBgColor: { [key: string]: string } = {
  demand_bid_qps: '#0070D2',
  supply_bid_qps: '#EB5757',
  incoming_requests: '#EB5757',
  valid_qps: '#6FCF97',
  outgoing_requests: '#0070D2',
  today: '#0070D2',
  yesterday: '#00FFF0',
  two_days_ago: '#54698D',
  ssp_requests: '#0070D2',
  requests: '#0070D2',
  responses: '#0070D2',
  timeout_rate: '#0070D2',
  total_bid_rate: '#0070D2',
  bid_rate: '#0070D2',
  fill_rate: '#0070D2',
  imps: '#0070D2',
  profit: '#0070D2',
  render_rate: '#0070D2',
  ssp_ecpm: '#0070D2',
  dsp_ecpm: '#0070D2',
  ssp_rcpm: '#0070D2',
  dsp_rcpm: '#0070D2',
  ssp_win_rate: '#0070D2',
  dsp_win_rate: '#0070D2',
  ssp_wins: '#0070D2',
  wins: '#0070D2',
};

export const ChartTotalColor: { [key: string]: string } = {
  today: '#0070D2',
  yesterday: '#0070D2',
  two_days_ago: '#0070D2'
};

export const CardTextColor = {
  demand_bid_qps: '#fff',
  supply_bid_qps: '#fff',
  incoming_requests: '#fff',
  outgoing_requests: '#fff',
  today: '#FFF',
  yesterday: '#333',
  two_days_ago: '#D3D3D3',
  ssp_requests: '#fff',
  requests: '#fff',
  responses: '#fff',
  timeout_rate: '#fff',
  total_bid_rate: '#fff',
  bid_rate: '#fff',
  fill_rate: '#fff',
  imps: '#fff',
  profit: '#fff',
  render_rate: '#fff',
  ssp_ecpm: '#fff',
  dsp_ecpm: '#fff',
  ssp_rcpm: '#fff',
  dsp_rcpm: '#fff',
  ssp_win_rate: '#fff',
  dsp_win_rate: '#fff',
  ssp_wins: '#fff',
  wins: '#fff',
};

export const generatedColors = new Set([
  'rgb(0, 255, 240)',
  'rgb(127, 0, 255)',
  'rgb(0, 0, 255)',
  'rgb(128, 0, 0)',
  'rgb(0, 128, 0)',
  'rgb(0, 0, 128)',
  'rgb(255, 128, 0)',
  'rgb(128, 0, 128)',
  'rgb(0, 128, 128)',
  'rgb(128, 128, 255)',
  'rgb(128, 128, 0)',
  'rgb(255, 128, 0)',
  'rgb(0, 64, 64)',
  'rgb(64, 64, 0)',
  'rgb(64, 0, 64)',
]);