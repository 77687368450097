import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BigNumberPipe } from './big-number';
import { BtsPipe } from './bts';
import { EndpointFilterPipe } from './endpoint-filter';
import { EndpointFilterWithoutCompaniesPipe } from './endpoint-filter-without-companies';
import { SafeHtmlPipe } from './safe-html';
import { SearchPipe } from './search';
import { SearchSelectPipe } from './search-select';
import { SelectSearchPipe } from './select-search';
import { TableSearchPipe } from './table-search';
import { TruncPipe } from './trunc';

@NgModule({
  declarations: [
    BigNumberPipe,
    BtsPipe,
    EndpointFilterPipe,
    EndpointFilterWithoutCompaniesPipe,
    SafeHtmlPipe,
    SearchPipe,
    SearchSelectPipe,
    SelectSearchPipe,
    TableSearchPipe,
    TruncPipe,
  ],
  exports: [
    BigNumberPipe,
    BtsPipe,
    EndpointFilterPipe,
    EndpointFilterWithoutCompaniesPipe,
    SafeHtmlPipe,
    SearchPipe,
    SearchSelectPipe,
    SelectSearchPipe,
    TableSearchPipe,
    TruncPipe,
  ],
  imports: [
    CommonModule,
  ],
  providers: [
    BigNumberPipe,
    BtsPipe,
    EndpointFilterPipe,
    EndpointFilterWithoutCompaniesPipe,
    SafeHtmlPipe,
    SearchPipe,
    SearchSelectPipe,
    SelectSearchPipe,
    TableSearchPipe,
    TruncPipe,
  ]
})
export class PipesModule { }
