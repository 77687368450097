<router-outlet></router-outlet>
<ngx-spinner
  bdColor="rgba(0, 0, 0, 0.8)"
  size="medium"
  color="#fff"
  type="ball-clip-rotate-multiple"
  [fullScreen]="true"
>
  <p style="color: white; font-size: 26px">Please wait...</p>
</ngx-spinner>
<app-notification></app-notification>
