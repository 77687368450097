import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'trunc',
})
export class TruncPipe implements PipeTransform {

  transform(value: string, args: any[]): any {
    const limit = args[0] ? parseInt(args[0], 10) : 20;
    if (!value)
      value = '';
    value = value.toString();

    if (value.length > limit) {
      return value.substring(0, limit) + '...';
    }
    return value;
  }

}
