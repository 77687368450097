import { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  loadingState$ = new BehaviorSubject<boolean>(false);

  constructor() { }

  getLoadingState(): Observable<boolean> {
    return this.loadingState$.asObservable();
  }

  showLoading(): void {
    this.loadingState$.next(true);
  }

  hideLoading(): void {
    this.loadingState$.next(false);
  }
}
