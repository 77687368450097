import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { fromEvent, merge, Observable, Observer } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class NetworkService {

  private needGoBack = false;

  constructor(
    private router: Router,
    private location: Location,
  ) {
    this.createOnline().subscribe(isOnline => {
      if (isOnline && this.needGoBack) {
        this.location.back();
        this.needGoBack = false;
      }
      if (!isOnline) {
        this.router.navigate(['offline']);
        this.needGoBack = true;
      }
    });
  }

  createOnline() {
    return merge<boolean>(
      fromEvent(window, 'offline').pipe(map(() => false)),
      fromEvent(window, 'online').pipe(map(() => true)),
      new Observable((sub: Observer<boolean>) => {
        sub.next(navigator.onLine);
        sub.complete();
      }));
  }
}
