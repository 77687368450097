export interface DashboardFilterModel {
  active: boolean;
  forAll: string;
  filters: string[];
  label: string;
  name: string;
  values: { id: string; name: string }[];
}

export interface DashboardFilterResponse {
  data: {
    dsp: {
      active: boolean;
      forAll: string;
      label: string;
      name: string;
      filters: string[];
      values: any;
    }[];
    ssp: {
      active: boolean;
      forAll: string;
      label: string;
      name: string;
      filters: string[];
      values: { [key: string]: string };
    }[];
  }
}