import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzSelectModule } from 'ng-zorro-antd/select';

import { SelectTreeMainComponent } from './select-tree-main.component';

@NgModule({
  declarations: [SelectTreeMainComponent],
  exports: [SelectTreeMainComponent],
  imports: [
    CommonModule,
    NzIconModule,
    NzSelectModule,
    FormsModule,
    NzButtonModule
  ]
})
export class SelectTreeMainModule { }
