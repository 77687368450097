<div class="reports__statistics statistics">
  <ng-container *ngFor="let card of _cards">
    <div *ngIf="card.enabled" class="pointer d-block statistics__item"
      [style]="{ background: card.color, border: '1px solid ' + card.color }" (click)="onToggleMetric(card)">
      <div class="statistics__item-text" [style]="{color: card.textColor}" [innerText]="card.name"></div>
      <div class="statistics__item-number h3" [style]="{color: card.textColor}"
        [innerText]="card.value | number: '1.0-2'"></div>
    </div>

    <div *ngIf="!card.enabled" class="pointer d-block statistics__item" [style]="{ border: '1px solid ' + card.color }"
      (click)="onToggleMetric(card)">
      <div class="statistics__item-text" [style]="{color: '#54698d'}" [innerText]="card.name"></div>
      <div class="statistics__item-number h3" [style]="{color: '#000'}" [innerText]="card.value | number: '1.0-2'">
      </div>
    </div>
  </ng-container>
</div>