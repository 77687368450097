<div class="stat-head-wrap">
  <div class="left" style="display: flex;">
    <div class="limits--container" style="width: 100%;">
      <div [ngClass]="limits.length > 1 ? 'limit-container-other' : 'limit-container' "
        *ngFor="let limit of limits; let i = index">
        <div class="limit-region">{{limit.region}}</div>
        <div>
          <app-qps-head-item class="item" title="Incoming QPS:" [items]="limit.sspEndpoints" [side]="'ssp'"
            [(opened)]="limit.sspHeadOpen" (clickOutside)="limit.sspHeadOpen = false;"
            [partnerQps]="{real: limit.partnerQps.incomingReal, standart: limit.partnerQps.incomingStandart}">
          </app-qps-head-item>
          <app-qps-head-item class="item" title="Outgoing QPS:" (selectedEndpoint)="translateEndpoint($event)"
            [items]="limit.dspEndpoints" [side]="'dsp'" [(opened)]="limit.dspHeadOpen"
            (clickOutside)="limit.dspHeadOpen = false;"
            [partnerQps]="{real: limit.partnerQps.outgoingReal, standart: limit.partnerQps.outgoingStandart}">
          </app-qps-head-item>
        </div>
      </div>
    </div>
    <div class="center-warn-item" *ngIf="overQps">
      <div class="item head-warn message">
        Over the past 2 days, you have systematically exceeded the QPS limit!
        <br>
        Please adjust the load or Upgrade the Plan.
      </div>
      <div class="item head-warn alt" *ngIf="netAlert && netAlert.message">
        <div class="icon">
          <img src="assets/img/alert.svg" alt="">
          <div class="message" [innerHTML]="netAlert.message"></div>
        </div>
        <span>Payment reminder</span>
      </div>
    </div>
  </div>
  <div class="right">
    <div class="item">
      <div class="title category-title" id="ad-types" (click)="toggleHeaderInfoState('ad-types')"
        [class.active]="headerInfoState['ad-types']">
        Traffic Types
        <div class="chevron-wrapper" [@toggleArrow]="isVisibleInfoContent('ad-types')">
          <img src="assets/img/ic_arrow_down.svg" alt="">
        </div>
      </div>
    </div>
    <div class="item">
      <div class="title category-title" id="ad-formats" (click)="toggleHeaderInfoState('ad-formats')"
        [class.active]="headerInfoState['ad-formats']">
        Ad Formats
        <div class="chevron-wrapper" [@toggleArrow]="isVisibleInfoContent('ad-formats')">
          <img src="assets/img/ic_arrow_down.svg" alt="">
        </div>
      </div>
    </div>
    <div class="item day-stat">
      <div class="item today">
        <div class="title">Today</div>
        <div class="info">
          $ {{ statistic.adFormat.today.total | number: '1.2-2'}}
        </div>
      </div>
      <div class="item yesterday">
        <div class="title">Yesterday</div>
        <div class="info">
          $ {{ statistic.adFormat.yesterday.total | number: '1.2-2'}}
        </div>
      </div>
    </div>
  </div>
</div>
<div class="header-info-wrap">
  <div class="right info-right">
    <div class="header-info" id="ad-types-content" [class.visible]="headerInfoState['ad-types']">
      <div class="item" *ngIf="statistic.trafficType.today.unknown.revenue">
        <div class="title">Unknown</div>
        <div class="info">
          $ {{ statistic.trafficType.today.unknown.revenue | number: '1.2-2'}}
          / {{ statistic.trafficType.today.unknown.percent | number: '1.0-0'}}%
        </div>
      </div>
      <div class="item">
        <div class="title">Desktop</div>
        <div class="info">
          $ {{ statistic.trafficType.today.web.revenue | number: '1.2-2'}}
          / {{ statistic.trafficType.today.web.percent | number: '1.0-0'}}%
        </div>
      </div>
      <div class="item">
        <div class="title">Mobile app</div>
        <div class="info">
          $ {{ statistic.trafficType.today.mobile.revenue | number: '1.2-2'}}
          / {{ statistic.trafficType.today.mobile.percent | number: '1.0-0'}}%
        </div>
      </div>
      <div class="item">
        <div class="title">Mobile web</div>
        <div class="info">
          $ {{ statistic.trafficType.today.mobileWeb.revenue | number: '1.2-2'}}
          / {{ statistic.trafficType.today.mobileWeb.percent | number: '1.0-0'}}%
        </div>
      </div>
      <div class="item">
        <div class="title">CTV</div>
        <div class="info">
          $ {{ statistic.trafficType.today.ctv.revenue | number: '1.2-2'}}
          / {{ statistic.trafficType.today.ctv.percent | number: '1.0-0'}}%
        </div>
      </div>
    </div>
    <div class="header-info" id="ad-formats-content" [class.visible]="headerInfoState['ad-formats']">
      <div class="item" *ngIf="statistic.adFormat.today.unknown.revenue">
        <div class="title">Unknown</div>
        <div class="info">
          $ {{ statistic.adFormat.today.unknown.revenue | number: '1.2-2'}}
          / {{ statistic.adFormat.today.unknown.percent | number: '1.0-0'}}%
        </div>
      </div>
      <div class="item">
        <div class="title">Banner</div>
        <div class="info">
          $ {{ statistic.adFormat.today.banner.revenue | number: '1.2-2'}}
          / {{ statistic.adFormat.today.banner.percent | number: '1.0-0'}}%
        </div>
      </div>
      <div class="item">
        <div class="title">Video</div>
        <div class="info">
          $ {{ statistic.adFormat.today.video.revenue | number: '1.2-2'}}
          / {{ statistic.adFormat.today.video.percent | number: '1.0-0'}}%
        </div>
      </div>
      <div class="item">
        <div class="title">Native</div>
        <div class="info">
          $ {{ statistic.adFormat.today.native.revenue | number: '1.2-2'}}
          / {{ statistic.adFormat.today.native.percent | number: '1.0-0'}}%
        </div>
      </div>
      <div class="item">
        <div class="title">Pop</div>
        <div class="info">
          $ {{ statistic.adFormat.today.pop.revenue | number: '1.2-2'}}
          / {{ statistic.adFormat.today.pop.percent | number: '1.0-0'}}%
        </div>
      </div>
      <div class="item">
        <div class="title">Push</div>
        <div class="info">
          $ {{ statistic.adFormat.today.push.revenue | number: '1.2-2'}}
          / {{ statistic.adFormat.today.push.percent | number: '1.0-0'}}%
        </div>
      </div>
      <div class="item">
        <div class="title">Audio</div>
        <div class="info">
          $ {{ statistic.adFormat.today.audio.revenue | number: '1.2-2'}}
          / {{ statistic.adFormat.today.audio.percent | number: '1.0-0'}}%
        </div>
      </div>
      <div class="item">
        <div class="title">Rewarded video</div>
        <div class="info">
          $ {{ statistic.adFormat.today.rewarded.revenue | number: '1.2-2'}}
          / {{ statistic.adFormat.today.rewarded.percent | number: '1.0-0'}}%
        </div>
      </div>
    </div>
  </div>