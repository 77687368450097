import { Component, Input, EventEmitter, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-checkbox-group',
  templateUrl: './checkbox-group.component.html',
  styleUrls: ['./checkbox-group.component.scss']
})
export class CheckboxGroupComponent {

  @Input() control: FormControl = new FormControl();
  @Input() items: { value: number, label: string, checked?: boolean }[];
  @Output() checked = new EventEmitter();

  constructor() { }

  onCheckedItems(value: { value: number, label: string, checked?: boolean }[]): void {
    const checkedItems = value.filter(element => element.checked === true).map(element => element['value']);
    this.control.patchValue(checkedItems);
    this.checked.emit(checkedItems);
  }

}
