import { Directive, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';

export type Key = { [key: string]: any; };

@Directive({
  selector: '[csRequired]',
})
export class CsRequiredDirective {
  @Input('csRequired') errName!: string;
  constructor() { }

  validate(control: AbstractControl): { [key: string]: any } {

    if (control.value === undefined || control.value === '') {
      return { [this.errName]: { value: control.value } };
    }

    if (control.value && typeof control.value.length !== 'undefined' && !control.value.length) {
      return { [this.errName]: { value: control.value } };
    }
    return null as unknown as Key;
  }
}
