export interface StatisticRequest {
  from: string;
  to: string;
  orderField: string;
  orderDir: string;
  page: number;
  rowsOnPage: number;
  filter: Array<object>;
  groupBy: Array<string>;
}


export interface StatisticResponse {
  data: Array<object>;
  totals: object;
  status: string;
}

export interface DspStatisticFilter {
  type: string;
  from: string;
  to: string;
  maxDate: string;
  timezone: string;
  dspId: number[];
  sspId: number[];
  hourly: boolean;
}
export interface ResponsesStatisticFilter {
  dspId: number[];
  sspId: number[];
  domain: string | null;
}
