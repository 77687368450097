import { Directive, HostListener, Output, EventEmitter } from '@angular/core';

@Directive({
  selector: '[appScrollTrigger]'
})
export class ScrollTriggerDirective {
  @Output() scrolled = new EventEmitter<void>();

  @HostListener('scroll', ['$event'])
  onScroll(event: Event): void {
    const element = event.target as HTMLElement;
    const scrollPercentage = (element.scrollTop / (element.scrollHeight - element.clientHeight)) * 100;

    if (scrollPercentage >= 80 && !this.scrolling) {
      this.scrolling = true;
      this.scrolled.emit();

      // Use a timeout to prevent multiple triggers for the same scroll position
      setTimeout(() => {
        this.scrolling = false;
      }, 1000); // Adjust the timeout value as needed
    }
  }

  private scrolling = false;
}