<div *ngIf="showEndpointValidationStatuses">
  <ng-container *ngFor="let validationStatus of endpointValidationStatuses">
    <div class="mt-10">
      <div class="form-group_inline" *ngIf="validationStatus.status === 'done'">
        <img src="assets/img/ic_check.svg" alt="">
      </div>
      <div class="form-group_inline" *ngIf="validationStatus.status === 'error'">
        <img src="assets/img/ic_clear_red.svg" alt="">
      </div>
      <div class="form-group_inline" *ngIf="validationStatus.status === 'pending'">
        <img src="assets/img/ic_loading.svg" alt="">
      </div>
      <div class="form-group_inline" *ngIf="validationStatus.status === 'none'">
        <img src="assets/img/ic_check_gray.svg" alt="">
      </div>
      <div class="form-group_inline ml-10">
        <label for="">{{ validationStatus.label }}</label>
      </div>
    </div>
  </ng-container>
</div>