import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { NgScrollbarModule } from 'ngx-scrollbar';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import { NzBadgeModule } from 'ng-zorro-antd/badge';

import { HeaderComponent } from './header.component';
import { AlertsDrawerModule } from '../alerts-drawer';
import { PipesModule } from './../../pipes/pipes.module';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzButtonModule } from 'ng-zorro-antd/button';

@NgModule({
  declarations: [HeaderComponent],
  exports: [HeaderComponent],
  imports: [
    CommonModule,
    NgScrollbarModule,
    FormsModule,
    RouterModule,
    PipesModule,
    NzIconModule,
    NzNotificationModule,
    NzBadgeModule,
    NzDrawerModule,
    AlertsDrawerModule,
    NzButtonModule,
  ]
})
export class HeaderModule { }
