import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NgApexchartsModule } from 'ng-apexcharts';

import { PieChartComponent } from './pie-chart.component';

@NgModule({
  declarations: [PieChartComponent],
  exports: [PieChartComponent],
  imports: [
    CommonModule,
    NgApexchartsModule
  ]
})
export class PieChartModule { }
