import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';

import {
  AppOptionsService,
  AuthService,
  DataManagerService,
  ErrorsHandlingService
} from '@core/services';
import {
  generateCardColor
} from '@core/helpers';
import { HelpersInterface } from '@core/interfaces';

import { CardTextColor } from '../models';
@Injectable({
  providedIn: 'root'
})
export class DashboardsService {
  public sspEndpoints: HelpersInterface.SelectItemHelper<number, string>[] = [{ id: -1, name: 'All SSP Endpoints' }];
  public dspEndpoints: HelpersInterface.SelectItemHelper<number, string>[] = [{ id: -1, name: 'All DSP Endpoints' }];
  public adFormats: HelpersInterface.SelectItemHelper<number, string>[] = [{ id: -1, name: 'All Ad Formats' }];
  public trafficTypes: HelpersInterface.SelectItemHelper<number, string>[] = [{ id: -1, name: 'All Traffic Types' }];
  public osItems: HelpersInterface.SelectItemHelper<number | string, string>[] = [{ id: -1, name: 'All OSs' }];
  public regions: HelpersInterface.SelectItemHelper<number | string, string>[] = [{ id: -1, name: 'All Regions' }];
  public filteredRegions: HelpersInterface.SelectItemHelper<number | string, string>[] = [{ id: -1, name: 'All Regions' }];
  public geoItems: HelpersInterface.SelectItemHelper<number | string, string>[] = [{ id: -1, name: 'All GEOs' }];
  public types: HelpersInterface.SelectItemHelper<string, string>[] = [
    { id: 'ssp', name: 'SSP' },
    { id: 'dsp', name: 'DSP' }
  ];

  constructor(
    private options: AppOptionsService,
    private errorsHandlingService: ErrorsHandlingService,
    private dataManagerService: DataManagerService,
    private authService: AuthService,
  ) {
    this.regions = this.regions.concat(this.options.getRegions(this.authService.partner.regions));
    this.filteredRegions = this.regions.filter(region => region.id !== -1);
    this.getFilters();
  }

  public setRegionByFilteredRegions(control: AbstractControl): void {
    const usEastRegion = this.filteredRegions.find(region => region.id === 'US_EAST');
    const region = usEastRegion ? usEastRegion.id : this.regions[0].id;
    control.patchValue(region);
  }

  public setRegion(control: AbstractControl): void {
    const usEastRegion = this.regions.find(region => region.id === 'US_EAST');
    const region = usEastRegion ? usEastRegion.id : this.regions[0].id;
    control.patchValue(region);
  }

  public createCardBasedOnMetric(
    metric: any,
    enabled = false,
  ) {
    return {
      column: metric.id as string,
      name: metric.name as string,
      color: generateCardColor(metric.id as string),
      value: '-',
      enabled: enabled,
      show: metric.enabled,
    };
  }

  public generateTextColor(cardName: string): string {
    return CardTextColor[cardName];
  }

  private getFilters(): void {
    this.dataManagerService.getFilters({
      filters: [
        'adFormats',
        'trafficTypes',
        'dspSettings',
        'sspSettings',
        'countries',
        'os']
    })
      .subscribe((res: {
        adFormats: { id: number; name: string }[];
        trafficTypes: { id: number; name: string }[];
        dspSettings: { id: number; name: string }[];
        sspSettings: { id: number; name: string }[];
        os: { id: string | number; name: string }[];
        countries: { id: string | number; name: string }[];
      }) => {
        this.sspEndpoints = this.sspEndpoints.concat(res.sspSettings);
        this.dspEndpoints = this.dspEndpoints.concat(res.dspSettings);
        this.adFormats = this.adFormats.concat(res.adFormats);
        const modifiedTrafficTypes = res.trafficTypes.filter(trafficType => trafficType.id !== 0);
        this.trafficTypes = this.trafficTypes.concat(modifiedTrafficTypes);
        const modifiedOS = res.os.filter(os => os.id !== 'empty');
        this.osItems = this.osItems.concat(modifiedOS);
        this.geoItems = this.geoItems.concat(res.countries);
      },
        error => this.errorsHandlingService.handleError(error));
  }
}
