import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CheckEndpointStatusComponent } from './check-endpoint-status.component';

@NgModule({
  declarations: [CheckEndpointStatusComponent],
  exports: [CheckEndpointStatusComponent],
  imports: [
    CommonModule
  ]
})
export class CheckEndpointStatusModule { }
