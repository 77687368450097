<div class="qps-item" [class.opened]="opened">
  <div class="wrap" (click)="toggleOpened($event)">
    <div class="title">{{title}}</div>
    <div class="info" *ngIf="partnerQps.real>partnerQps.standart; else elseBlock">
      <span class="exceeded fs-12">{{ partnerQps.real}}</span> / {{ partnerQps.standart }}
    </div>
    <ng-template #elseBlock>
      <span class="fs-12">
        {{ partnerQps.real}} / {{ partnerQps.standart }}
      </span>
    </ng-template>
  </div>
  <div class="qps-item-dropdown" (click)="onDropdownBodyClicked($event)">
    <div class="dropdown__items">
      <div class="dropdown__item" *ngFor="let item of items" (click)="toggleSelection($event, item.attr)">
        <div class="dropdown__item__inner" [class.disabled]="item.disabled" (click)="sendIdEndpoint($event, item.attr)">
          <div class="name">
            {{item.attr}}
          </div>
          <div class="digit">{{item.label}}</div>
        </div>
      </div>
    </div>
  </div>
</div>