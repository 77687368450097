/* eslint-disable max-len */
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';

import { switchMap } from 'rxjs/internal/operators';
import { Subscription, timer } from 'rxjs';

import { NzDrawerService } from 'ng-zorro-antd/drawer';


import { messages } from '@core/constants';
import { IAlert, PartnerInt } from '@core/interfaces';
import { UserRole } from '@core/enum';
import { AuthService, DataManagerService, NotificationService, ErrorsHandlingService } from '@core/services';
import { AlertsDrawerComponent } from '../alerts-drawer/alerts-drawer.component';
import { AlertsService } from '@modules/alerts/services';

import { NotificationComponent } from '../notification';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  selectedMenu: string;
  partner: PartnerInt;
  userRole: string;
  isShowAlert = false;
  subscription: Subscription;
  revenueSubscription: Subscription;
  isShowRevenue = false;
  sslExpires: Subscription;
  isShowSSLExpires = false;
  dataSSLExpires: { days: number; message: string };
  messages = messages;

  protected userRoleEnum = UserRole;

  alertsDrawerData: IAlert[] = [];
  alertsCount = 0;

  @ViewChild(AlertsDrawerComponent, { static: true }) alertsDrawerComponent: ElementRef;

  constructor(
    private route: ActivatedRoute,
    public auth: AuthService,
    private router: Router,
    private dataManager: DataManagerService,
    @Inject(DOCUMENT) private _document: Document,
    private notificationService: NotificationService,
    private notificationComponent: NotificationComponent,
    private drawerService: NzDrawerService,
    private alertsService: AlertsService,
    private errorsHandlingService: ErrorsHandlingService,
  ) {
    this.alertsService.alertsObs.subscribe(res => this.alertsCount = res);
    this.alertsService.alertsDataObs.subscribe(res => this.alertsDrawerData = res);
  }

  ngOnInit(): void {
    this.route.data.subscribe((d) => {
      this.selectedMenu = d.selectedMenu;
    });

    if (this.auth.userRole !== UserRole.external) {
      this.getAlerts();
    }


    this.partner = this.auth.partner;
    this.userRole = this.auth.userRole;


    this.getLimitQpsNotification();

    if (this.auth.isAuthenticated()) {
      timer(1000, 1800000)
        .subscribe(() => {
          this.dataManager.checkTechnicalWorks()
            .subscribe(data => {
              this.isShowAlert = +data.useShowAlert === 1 ? true : false;
              if (this.isShowAlert) {
                this.showNotification('error', 'error', 'error', null, this.messages[data.messageType], null, null, 10000, null, null, null);
              }
            });
        });

      timer(1000, 1800000)
        .subscribe(() => {
          if (this.userRole == 'support' || this.userRole == 'manager') {
            this.dataManager.checkSSLExpiry()
              .subscribe(res => {
                if (res.data) {
                  this.dataSSLExpires = res.data;

                  if (this.isShowAlert && res.data) {
                    this.isShowSSLExpires = false;
                  } else if (!this.isShowAlert && res.data) {
                    this.isShowSSLExpires = true;
                  }
                  if (this.isShowSSLExpires) {
                    this.showNotification('error', 'error', 'error', null, this.dataSSLExpires.message, null, null, 10000, null, null, null);
                  }
                } else {
                  return;
                }
              });
          }
        });
    }
  }

  isMain(): boolean {
    return this.router.url === '/';
  }


  openedDocumentation() {
    this.showNotification(
      'info',
      'info',
      'info',
      'Contact your Account Manager for the access to', 'Knowledge Base',
      'on Confluence',
      '400px',
      15000,
      false,
      '#0070d2',
      'bold',
      'https://smart-hub-io.atlassian.net/wiki/spaces/KBS/overview',
    );
  }

  logout(e: MouseEvent): void {
    e.preventDefault();
    this.auth.logout();
    this._document.location.reload();
  }

  public openAlertsDrawer(): void {
    this.setUserActionCount(0);
    const drawerRef = this.drawerService.create({
      nzContent: AlertsDrawerComponent,
      nzContentParams: {
        data: {
          alertsCount: this.alertsCount,
          alertsArray: this.alertsDrawerData,
        }
      },
      nzKeyboard: false,
      nzClosable: false,
      nzPlacement: 'right',
      nzBodyStyle: { padding: '0px', overflowY: 'hidden', width: '100%' },
    });

    drawerRef.afterOpen.subscribe(() => {
      const alertsElement = document.getElementsByClassName('column-notification__row')[0];
      if (alertsElement) {
        alertsElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
        alertsElement.scrollTop = 0;
      }
    });
  }

  public getRole(): UserRole {
    return this.auth.userRole;
  }

  capitalize(str: string): string {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  isServiceDomain(): boolean {
    return UserRole.support === this.getRole();
  }

  private getLimitQpsNotification(): void {
    if (this.auth.isAuthenticated() && (this.auth.userRole === UserRole.viewer
      || this.auth.userRole === UserRole.manager || this.auth.userRole === UserRole.support)) {
      timer(2000, 1800000)
        .subscribe(() => {
          this.auth.checkLimitQPSForPartner()
            .subscribe(res => {
              const messages = this.generateWarningMessages(res);
              if (messages.length > 0 && this.router.url === '/') {
                messages.forEach(message => this.showNotification('error', 'error', 'error', null, message, null, null, 10000, null, null, null));
              }
            });
        });
    }
  }

  generateWarningMessages(data: {
    [region: string]: {
      incoming: number | boolean;
      outgoing: number | boolean;
    };
  }): string[] {
    const messages: string[] = [];

    for (const region in data) {
      const incoming = data[region].incoming;
      const outgoing = data[region].outgoing;

      if (incoming && !outgoing) {
        messages.push(`Dear partner, Your revenue is being affected! The system cannot operate normally. Please note that incoming traffic volume exceeds the limit. This results in server overload. Currently, you are receiving ${incoming} QPS on the ${region} region. Please reach out to your SSP partners and ask them to adjust QPS limits on their side.`);
      } else if (!incoming && outgoing) {
        messages.push(`Dear partner, Your revenue is being affected! The system cannot operate normally. Please note that outgoing traffic volume exceeds the limit. This results in server overload. Currently, you are receiving ${outgoing} QPS on the ${region} region. Please adjust QPS limits on the DSP side.`);
      } else if (incoming && outgoing) {
        messages.push(`Dear partner, Your revenue is being affected! The system cannot operate normally. Please note that incoming and outgoing traffic volume exceeds the limit. This results in server overload. Currently, you are receiving ${incoming} incoming QPS and ${outgoing} outgoing QPS on the ${region} region. Please reach out to your SSP ask them to adjust QPS limits on their side and adjust QPS limits in the DSP settings.`);
      }
    }

    return messages;
  }

  private getAlerts(): void {
    timer(1000, 1200000).subscribe(() => {
      this.alertsService.getAlerts({ is_read: false })
        .pipe(
          switchMap(res => {
            this.alertsService.alertsCount.next(res.totals);
            this.alertsCount = res.totals;
            return this.alertsService.getAlerts({});
          })
        )
        .subscribe(res => {
          const alertsDrawerData = res.data.length > 10 ? res.data.slice(0, 10) : res.data;
          this.alertsService.alertsData.next(alertsDrawerData);
          this.alertsDrawerData = alertsDrawerData;

        },
          error => this.errorsHandlingService.handleError(error));
    });
  }

  private setUserActionCount(value: number): void {
    this.alertsService.setUserActionCount(value).subscribe(() => { },
      error => this.errorsHandlingService.handleError(error));
  }

  private showNotification(
    iconType: string,
    color: string,
    notificationType: string,
    leftPartOfText: string,
    description: string,
    rightPartOfText: string,
    width: string,
    duration: number,
    pauseOnHover: boolean,
    textColor: string,
    fontWeight: string,
    linkUrl = null,
  ): void {
    const data = {
      iconType,
      color,
      notificationType,
      description,
      leftPartOfText: leftPartOfText ?? '',
      rightPartOfText: rightPartOfText ?? '',
      textColor: textColor ?? '#000',
      fontWeight: fontWeight ?? 'normal',
      linkUrl: linkUrl ?? '',
    };

    this.notificationComponent.data = data;
    this.notificationService.showTemplate({
      nzData: data,
      nzStyle: { width: width ?? '385px' },
      nzDuration: duration ?? 4500,
      nzPauseOnHover: pauseOnHover ?? true,
    });
  }
}
