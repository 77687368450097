import { Pipe, PipeTransform } from '@angular/core';

import { FilterListInterface } from '@core/interfaces';

@Pipe({
  name: 'selectSearch',
})
export class SelectSearchPipe implements PipeTransform {

  transform(items: FilterListInterface.SelectCategoryItems[], search = ''): FilterListInterface.SelectCategoryItems[] {
    if (!search.trim()) {
      return items;
    }

    return items.filter(item => {
      return item.name.toLowerCase().includes(search.toLowerCase()) || item.id.toString().toLowerCase().includes(search.toLowerCase());
    });
  }

}
