import { BotmanPostBidSSP } from '../botman-post-bid-ssp';
import { CookieSync } from '../cookie-sync';
import { EndpointPixalatePredidSettings } from '../dsp';

export interface SSPList {
  id: number | string;
  name: string;
  active: number;
}

export class SSP {
  id: number;
  partnerId: number;
  name?: string;
  secretKey?: string;
  seat?: string;
  region?: string;
  active? = 0;
  companyId?: number;
  margin?: number;
  createdAt: string;
  min_margin?: number;
  max_margin?: number;
  margin_type = 'fixed';
  comments?: string;
  qps?: number;
  bidqps?: number;
  useTMT = 0;
  usePMKey = 0;
  useGeoedge = 0;
  useForensiq = 0;
  usePreBid = 0;
  enablepixalatePostBid = 0;
  defaultTmax: number;
  defaultBidfloor: number;
  priceFromNurl: number;
  isPop: number;
  isPush: number;
  isBanner = 0;
  isVideo = 0;
  isNative = 0;
  isAudio = 0;
  desktop?: number;
  mobile_app?: number;
  mobile_web?: number;
  ctv?: number;
  rewarded?: number;
  popRespXml?: number;
  dailySpend?: number;
  spendLimit?: number;
  blockedDspList?: number[];
  allowedDspList?: number[];
  endpoint?: string;
  apiLink?: string;
  reportingApiLink?: string;
  adstxtCount?: number;
  useTotalSpendLimit?: number;
  totalSpendLimit?: number;
  connectionType?: number;
  email?: string;
  isSecure = 0;
  vast: any;
  cookieSync?: CookieSync;
  usePMPrebid?: number;
  useBotman?: number;
  pmIsFraud = 0;
  useBcatFilter?: number;
  has_badv_filter: 0;
  min_bidfloor?: number;
  allowedDspListTrigger = 1;
  dspList?: number[] | string[];
  gzipResponses = 0;
  enable_prebid = false;
  enable_postbid = false;
  botmanPostBid?: BotmanPostBidSSP[];
  geoedge_key?: string = '';
  is_fraud = 0;
  fixedPrice = false;
  isForTest = false;
  vastConnectionMode?: number;
  rtbConnectionMode = 0;
  deletedAt?: string;
  tokenPreBid?: string | number;
  hostPreBid?: string | number;
  seatPreBid?: string | number;
  prebid_params?: PreBidParams;
  partnerName?: string;
  js?: JSTags;
  selectedSize = 0;
  pixalate_pb_settings: EndpointPixalatePredidSettings;
  is_pixalate_postbid = 0;
  writing_pub_ids = 'automated';
  has_response_adomain: number;
  iab_categories = [];
  has_demand_booster = 0;
  filter_list_ids = [];
  adapter_id: string | number;
  adapter_config: any;
  imp_triggering = 'adm';
  is_gpp_support = 0;
}


export interface SSPEndpoint {
  id: number;
  partnerId: number;
  name: string;
  secretKey: string;
  seat: string;
  region: string;
  active: number;
  companyId?: number;
  margin?: number;
  min_margin?: number;
  max_margin?: number;
  margin_type: string;
  comments: string;
  qps: number;
  bidqps: number;
  useTMT: number;
  usePMKey: number;
  useGeoedge: number;
  useForensiq: number;
  usePreBid: number;
  enablepixalatePostBid: number;
  defaultTmax: number;
  defaultBidfloor: number;
  priceFromNurl: number;
  isPop: number;
  isPush: number;
  isBanner: number;
  isVideo: number;
  isNative: number;
  isAudio: number;
  desktop: number;
  mobile_app: number;
  mobile_web: number;
  ctv: number;
  rewarded: number;
  popRespXml: number;
  dailySpend: number;
  spendLimit: number;
  blockedDspList: number[];
  allowedDspList: number[];
  endpoint: string;
  apiLink: string;
  reportingApiLink: string;
  adstxtCount: number;
  useTotalSpendLimit: number;
  totalSpendLimit: number;
  connectionType: number;
  email: string;
  isSecure: number;
  vast: any;
  cookieSync: CookieSync;
  usePMPrebid: number;
  useBotman: number;
  pmIsFraud: number;
  useBcatFilter: number;
  has_badv_filter: number;
  min_bidfloor: number;
  allowedDspListTrigger: number;
  dspList: number[];
  gzipResponses: number;
  enable_prebid: boolean | number;
  enable_postbid: boolean | number;
  botmanPostBid: BotmanPostBidSSP[];
  geoedge_key: string;
  is_fraud: number;
  fixedPrice: boolean | number;
  isForTest: boolean | number;
  vastConnectionMode: number;
  deletedAt: string;
  tokenPreBid: string | number;
  hostPreBid: string | number;
  seatPreBid: string | number;
  prebid_params: PreBidParams;
  partnerName: string;
  js: JSTags;
  selectedSize: number;
  pixalate_pb_settings: EndpointPixalatePredidSettings;
  is_pixalate_postbid: number;
  writing_pub_ids: string;
  has_response_adomain: number;
  iab_categories: string[];
  has_demand_booster: number;
  filter_list_ids: number[];
  adapter_id: string | number;
  adapter_config: any;
  imp_triggering: string;
}

export interface EndpointPixalatePredid {
  ip: number;
  ip_percent: number;
  ifa: number;
  ifa_percent: number;
  ua: number;
  ua_percent: number;
  ott: number;
  ott_percent: number;
  def_app: number;
  def_app_percent: number;
  domain: number;
  domain_percent: number;
  hr_app: number;
  hr_app_percent: number;
  dc_subnet: number;
  dc_subnet_percent: number;
}

export interface JSTags {
  w: number;
  h: number;
  tagType: string;
  macrosList: any[];
  categories: any[];
  backfill: string | number;
  bidfloor: number;
}

export interface PreBidParams {
  id: number;
  partner_id: number;
  name: string;
  deletedAt?: string;
}

export interface SSPResponse {
  status: string;
  data: SSP[];
}

export interface SSPResponseSingle {
  data: SSP;
}

export interface SSPUpdateResponse {
  status: string;
  data: SSP;
}

export type ConnectionType = { rtb: number; vast: number; js: number; prebid: number; };

export interface VastData {
  adServer: string | number;
  adServerMacroses: any[];
  adtype: string;
  connection: {
    bidfloor: any;
    macrosList: any[];
  };
  connectionType: string;
  partnerNs: string;
  protocol: string;
  seat: string;
  sizeRequired: boolean;
  token: string;
  endpoint: any,
}
