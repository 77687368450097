import { NzIconModule } from 'ng-zorro-antd/icon';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NzSelectModule } from 'ng-zorro-antd/select';

import { SelectMultiAntdComponent } from './select-multi-antd.component';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [SelectMultiAntdComponent],
  exports: [SelectMultiAntdComponent],
  imports: [
    CommonModule,
    NzSelectModule,
    FormsModule,
    NzIconModule
  ]
})
export class SelectMultiAntdModule { }
