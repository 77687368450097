<div class="access-limitation-dialog">
  <div class="access-limitation-dialog__row">
    <div class="access-limitation-dialog__column">
      <div class="access-limitation-dialog__icon">
        <img src="assets/img/access_limitation_icon.svg" alt="access_limitation_icon">
      </div>
    </div>
    <div class="access-limitation-dialog__column">
      <h2 class="access-limitation-dialog__title">Dear Partner</h2>
    </div>
    <div class="access-limitation-dialog__column">
      <p class="access-limitation-dialog__subtitle">We wanted to bring to your attention that there are currently some
        outstanding invoices on your account.</p>
    </div>
    <div class="access-limitation-dialog__column">
      <p class="access-limitation-dialog__text">The total amount due is {{data.total}}.</p>
    </div>
    <div class="access-limitation-dialog__column">
      <div class="access-limitation-dialog__table">
        <nz-table [nzSize]="'small'" [nzFrontPagination]="false" [nzBordered]="true" [nzData]="data.tableData"
          [nzScroll]="{y: '160px'}">
          <thead>
            <tr>
              <th>INVOICE №</th>
              <th>SUM</th>
              <th>OVERDUE DAYS</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let data of data.tableData">
              <td>{{data.invoice_id}}</td>
              <td>$ {{data.amount}}</td>
              <td>{{data.overdue_days}}</td>
            </tr>
          </tbody>
        </nz-table>
      </div>
    </div>
    <div class="access-limitation-dialog__column">
      <p class="access-limitation-dialog__text"></p>
    </div>
    <div class="access-limitation-dialog__column">
      <p class="access-limitation-dialog__text">In order to regain access to the platform, we kindly ask you to settle
        these invoices at your earliest convenience and send a proof of payment to our finance team, which is available
        to assist you with any questions or concerns you may have about the payment process.</p>
    </div>
    <div class="access-limitation-dialog__column">
      <p class="access-limitation-dialog__text access-limitation-dialog__text--last">Please note that until the
        outstanding amount is paid and confirmed, you
        will not be able to access the platform.Thank you for your understanding and cooperation.</p>
    </div>
    <div class="access-limitation-dialog__button">
      <button nz-button nzType="primary" (click)="close()">Got it</button>
    </div>
  </div>
</div>