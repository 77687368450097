import { differenceInDays, subMonths, subDays, startOfDay } from 'date-fns';

import { DatePickerRanges, LabelRanges } from '../../enum';
import { PICKER_DATE_RANGES } from '../../constants';
import { PickerInterface } from '../../models';

function isSameDay(date1: Date, date2: Date): boolean {
  return date1.toDateString() === date2.toDateString();
}

export function isToday(date: Date): boolean {
  const today = startOfDay(new Date());
  return date.toDateString() === today.toDateString();
}

export function isPredefinedRange(dateFrom: Date, dateTo: Date): boolean {
  if (!dateFrom && !dateTo) {
    return false;
  }

  return Object.values(PICKER_DATE_RANGES).some(
    (predefinedRange) =>
      dateFrom.toDateString() === predefinedRange[0].toDateString() &&
      dateTo.toDateString() === predefinedRange[1].toDateString()
  );
}

function isThisMonth(date: Date): boolean {
  return date.getMonth() === new Date().getMonth() && date.getFullYear() === new Date().getFullYear();
}

function isLastMonth(date: Date): boolean {
  return (
    date.getMonth() === subMonths(new Date(), 1).getMonth() &&
    date.getFullYear() === subMonths(new Date(), 1).getFullYear()
  );
}

function getPredefinedRangeLabel(date_from: Date, daysDiff: number): string | null {
  if (daysDiff === 6) {
    return DatePickerRanges.last_7_days;
  } else if (daysDiff === 29) {
    return DatePickerRanges.last_30_days;
  } else if (isThisMonth(date_from)) {
    return DatePickerRanges.this_month;
  } else if (isLastMonth(date_from)) {
    return DatePickerRanges.last_month;
  }

  return null;
}

function getDifferentDayRangeLabel(date_from: Date, date_to: Date): string | number | null {
  const daysDiff = differenceInDays(date_to, date_from);

  if (!isPredefinedRange(date_from, date_to)) {
    return daysDiff + 1;
  } else {
    return getPredefinedRangeLabel(date_from, daysDiff);
  }
}

export function getRangeLabel(result: Date[]): string | number | null {
  if (!result.length) {
    return DatePickerRanges.today;
  }

  const [date_from, date_to] = result;

  if (isSameDay(date_from, date_to)) {
    if (isToday(date_from)) {
      return DatePickerRanges.today;
    } else {
      return DatePickerRanges.yesterday;
    }
  } else {
    return getDifferentDayRangeLabel(date_from, date_to);
  }
}

export function getDateRangeByNumberLabelToDateString(labelRange: number): Date[] {
  const today = new Date();
  const date_from = subDays(today, labelRange - 1);
  return [date_from, today];
}

export function getDateRangeByNumberLabel(labelRange: number): Date[] {
  const today = new Date();
  const date_from = subDays(today, labelRange - 1);
  return [date_from, today];
}

export function getDateRangeByLabel(label: string | number): Date[] {
  const labelRange = label === '' ? LabelRanges.today : label;

  if (typeof labelRange === 'string') {
    return PICKER_DATE_RANGES[labelRange as keyof PickerInterface.DateRanges];
  } else {
    return getDateRangeByNumberLabel(labelRange);
  }
}