import { NzModalModule } from 'ng-zorro-antd/modal';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { WarningDialogComponent } from './warning-dialog.component';
import { NzButtonModule } from 'ng-zorro-antd/button';

@NgModule({
  declarations: [WarningDialogComponent],
  exports: [WarningDialogComponent],
  imports: [
    CommonModule,
    NzModalModule,
    NzButtonModule
  ]
})
export class WarningDialogModule { }
