import { ErrorHandler, Injectable, NgZone } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, throwError as _throw } from 'rxjs';
import { catchError } from 'rxjs/operators';


import { Partner, User } from '../../interfaces';
import { environment } from '@environment/environment';
import { ErrorsHandlingService } from '../errors-handling';
@Injectable()
export class CustomErrorHandlerService implements ErrorHandler {

  apiLink = environment.apiForLogger;
  errorObject: {
    userEmail?: string;
    userId?: number;
    partnerId?: number;
    partnerName?: string;
    errorMessage?: string;
    errorPlace?: string;
  };

  constructor(
    private ngZone: NgZone,
    private http: HttpClient,
    private readonly errorsHandlingService: ErrorsHandlingService,
  ) { }

  handleError(error: Error) {
    this.ngZone.run(() => {
      if (error?.stack?.length) {
        const partner: Partner = JSON.parse(localStorage.getItem('partner'));
        const user: User = JSON.parse(localStorage.getItem('user-info'));
        const errorStacks = error.stack.split('\n');
        const placeError = errorStacks[1].split('(')[0];
        this.errorObject = {
          userEmail: user.email,
          userId: user.id,
          partnerId: partner.id,
          partnerName: partner.name,
          errorMessage: error.message,
          errorPlace: placeError.trim(),
        };

        const chunkErrorSubstring = 'Uncaught (in promise): ChunkLoadError: Loading chunk';
        const typeErrorSubstring = 'Uncaught (in promise): TypeError: Cannot read properties of undefined';
        const changeDetectionSubstring = 'NG0100: ExpressionChangedAfterItHasBeenCheckedError';

        if (error.message.includes(chunkErrorSubstring)) {
          return;
        } else if (error.message.includes(typeErrorSubstring)) {
          return;
        } else if (error.message.includes(changeDetectionSubstring)) {
          return;
        } else {
          this.sendError(this.errorObject)
            .subscribe(() => { },
              error => this.errorsHandlingService.handleError(error));
        }

      } else {
        this.errorObject = {};
        return;
      }
    });
  }

  private sendError(error: {
    userEmail?: string;
    userId?: number;
    partnerId?: number;
    partnerName?: string;
    errorMessage?: string;
    errorPlace?: string;
  }): Observable<any> {
    return this.http.post(this.apiLink, error)
      .pipe(catchError(this.errorsHandlingService.processError));
  }
}
