import { UserRole } from '@app/core/enum';
import { FilterListInterface, TrafficAnalyzerInterface } from '../../interfaces';

export function compareCompaniesArray(
  companiesArray: FilterListInterface.CompaniesDataGeneral[],
  inputIdsArray: number[], isAll: boolean): FilterListInterface.CompaniesDataGeneral[] {
  let outputArray: FilterListInterface.CompaniesDataGeneral[] = [];
  outputArray = companiesArray.map(company => {
    company.endpoints = company.endpoints.map(endpoint => {
      if (inputIdsArray.includes(endpoint.id)) {
        endpoint.checked = true;
        company.indeterminate = true;
      } else {
        endpoint.checked = false;
      }

      if (isAll) {
        endpoint.checked = true;
        endpoint.disabled = true;
      }

      if (inputIdsArray.length === 30 && !inputIdsArray.includes(endpoint.id)) {
        endpoint.disabled = true;
        company.disabled = true;
      }

      if (!isAll && !inputIdsArray.length) {
        endpoint.disabled = false;
      }

      return endpoint;
    });

    if (!inputIdsArray.length) {
      company.indeterminate = false;
    }

    if (company.endpoints.every(endpoint => !endpoint.checked)) {
      company.checked = false;
    } else if (company.endpoints.every(endpoint => endpoint.checked)) {
      company.checked = true;
      company.indeterminate = false;
    } else {
      company.checked = false;
    }

    if (company.endpoints.every(endpoint => endpoint.checked) && inputIdsArray.length === 30) {
      company.disabled = true;
      company.indeterminate = false;
      company.checked = true;
    }


    if (isAll) {
      company.checked = true;
      company.disabled = true;
    }

    if (!isAll && !inputIdsArray.length) {
      company.disabled = false;
    }

    return company;
  });

  return outputArray;
}

export function isOpenedCompanyState(
  inputCompaniesArray: FilterListInterface.CompaniesDataGeneral[],
  inputIds: number[], isAll: boolean
): { [key: number]: boolean; } {
  let state: { [key: number]: boolean } = {};


  if (isAll) {
    inputCompaniesArray.forEach(company => {
      state[company.id] = true;
    });
  } else {
    inputCompaniesArray.forEach(company => {
      state[company.id] = company.endpoints.some((endpoint) => inputIds.includes(endpoint.id));
    });
  }

  return state;
}

export function compareEndpointsArray(
  endpointsArray: FilterListInterface.EndpointsGeneral[],
  inputIdsArray: number[], isAll: boolean, userRole: UserRole): FilterListInterface.EndpointsGeneral[] {
  let outputArray: FilterListInterface.EndpointsGeneral[] = [];
  outputArray = endpointsArray.map(endpoint => {
    if (inputIdsArray.includes(endpoint.id)) {
      endpoint.checked = true;
    } else {
      endpoint.checked = false;
    }

    if (inputIdsArray.length === 30 && !inputIdsArray.includes(endpoint.id)) {
      endpoint.disabled = true;
    }

    if (!inputIdsArray.length) {
      endpoint.disabled = false;
    }

    if (isAll) {
      endpoint.checked = true;
      endpoint.disabled = true;
    }

    if (userRole === UserRole.viewer) {
      endpoint.disabled = true;
    }

    return endpoint;
  });

  return outputArray;
}

export function rearrangeArrayEndpoints(inputArray: FilterListInterface.EndpointsGeneral[], inputIdsArray: number[]):
  FilterListInterface.EndpointsGeneral[] {
  let outputEndpoints: FilterListInterface.EndpointsGeneral[] = [];

  const selectedItems = inputArray.filter(item => inputIdsArray.includes(item.id));
  const unselectedItems = inputArray.filter(item => !inputIdsArray.includes(item.id));

  outputEndpoints = [...selectedItems, ...unselectedItems];

  return outputEndpoints;
}

export function sortCompaniesArray(
  inputFilterListArray: FilterListInterface.CompaniesDataGeneral[],
  inputIdsArray: number[]): FilterListInterface.CompaniesDataGeneral[] {
  const inputIdsSet = new Set(inputIdsArray);

  inputFilterListArray.forEach((item) => {
    // Sort endpoints based on inputIdsArray order
    item.endpoints.sort((a, b) => {
      const aIdInInputIds = inputIdsSet.has(a.id);
      const bIdInInputIds = inputIdsSet.has(b.id);

      if (aIdInInputIds && !bIdInInputIds) {
        return -1;
      } else if (!aIdInInputIds && bIdInInputIds) {
        return 1;
      } else {
        const aIndex = inputIdsArray.indexOf(a.id);
        const bIndex = inputIdsArray.indexOf(b.id);
        return aIndex - bIndex;
      }
    });
  });

  // Sort inputFilterListArray based on whether any endpoint id is in inputIdsArray
  inputFilterListArray.sort((a, b) => {
    const aHasMatch = a.endpoints.some((endpoint) => inputIdsSet.has(endpoint.id));
    const bHasMatch = b.endpoints.some((endpoint) => inputIdsSet.has(endpoint.id));

    if (aHasMatch && !bHasMatch) {
      return -1;
    } else if (!aHasMatch && bHasMatch) {
      return 1;
    } else {
      return 0;
    }
  });

  return inputFilterListArray;
}

export function modifyEditCompanies(
  inputArray: FilterListInterface.CompaniesDataGeneral[],
  inputIdsArray: number[],
  isAll: boolean,
): FilterListInterface.CompaniesDataGeneral[] {
  let outputCompaniesArray: FilterListInterface.CompaniesDataGeneral[] = [];

  const comparedCompaniesArray = compareCompaniesArray(inputArray, inputIdsArray, isAll);

  outputCompaniesArray = sortCompaniesArray(comparedCompaniesArray, inputIdsArray);
  return outputCompaniesArray;
}

export function compareEventsArray(
  itemsArray: TrafficAnalyzerInterface.Rules[],
  resArray: number[],
  isAll: boolean): TrafficAnalyzerInterface.Rules[] {
  itemsArray.forEach(item => {
    if (resArray.includes(item.id)) {
      item.checked = true;
    }

    if (isAll) {
      item.checked = true;
      item.disabled = true;
    } else {
      item.disabled = false;
    }
  });

  return itemsArray;
}
