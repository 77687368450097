import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AdminGuard, AuthGuard, ScheduledReportGuard, TrafficAnalyzerGuard } from '@core/guards';

const routes: Routes = [
  {
    path: '',
    data: { selectedMenu: 'main' },
    loadChildren: () => import('./modules/main/main.module').then(m => m.MainModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'billing',
    data: { selectedMenu: 'billing' },
    loadChildren: () => import('./modules/billing/billing.module').then(m => m.BillingModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'companies',
    data: { selectedMenu: 'companies' },
    loadChildren: () => import('./modules/companies/companies.module').then(m => m.CompaniesModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'dropped-requests',
    data: { selectedMenu: 'dropped-requests' },
    loadChildren: () => import('./modules/drop-requests/drop-requests.module').then(m => m.DropRequestsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'list-manager',
    data: { selectedMenu: 'list-manager' },
    loadChildren: () => import('./modules/filter-list/filter-list.module').then(m => m.FilterListModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'traffic-logger',
    data: { selectedMenu: 'traffic-logger' },
    loadChildren: () => import('./modules/incoming-traffic-logger/incoming-traffic-logger.module').then(m => m.IncomingTrafficLoggerModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'samples-logger',
    data: { selectedMenu: 'samples-logger' },
    loadChildren: () => import('./modules/samples-logger/samples-logger.module').then(m => m.SamplesLoggerModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'statistics/reports',
    data: { selectedMenu: 'reports' },
    loadChildren: () => import('./modules/reports/reports.module').then(m => m.ReportsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'statistics/scanner-statistic',
    data: { selectedMenu: 'scanner-statistic' },
    loadChildren: () => import('./modules/scanner-statistics/scanner-statistics.module').then(m => m.ScannerStatisticsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'sellers-json',
    loadChildren: () => import('./modules/seller-json/seller-json.module').then(m => m.SellerJsonModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'dashboards',
    data: { selectedMenu: 'dashboards' },
    loadChildren: () => import('./modules/dashboards/dashboards.module').then(m => m.DashboardsModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'admin/partners',
    loadChildren: () => import('./modules/admin/admin.module').then(m => m.AdminModule),
    canActivate: [AuthGuard, AdminGuard]
  },
  {
    path: 'traffic-analyzer/dashboard',
    loadChildren: () => import('./modules/traffic-analyzer-dashboard/traffic-analyzer-dashboard.module')
      .then(m => m.TrafficAnalyzerDashboardModule),
    canActivate: [TrafficAnalyzerGuard]
  },
  {
    path: 'traffic-analyzer/rule-manager',
    loadChildren: () => import('./modules/traffic-analyzer-rule-manager/traffic-analyzer-rule-manager.module')
      .then(m => m.TrafficAnalyzerRuleManagerModule),
    canActivate: [TrafficAnalyzerGuard]
  },
  {
    path: 'mismatches',
    loadChildren: () => import('./modules/mismatches/mismatches.module').then(m => m.MismatchesModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'scanners',
    loadChildren: () => import('./modules/scanners/scanners.module').then(m => m.ScannersModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'settings',
    loadChildren: () => import('./modules/settings/settings.module').then(m => m.SettingsModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'frameworks',
    loadChildren: () => import('./modules/frameworks/frameworks.module').then(m => m.FrameworksModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'integrations',
    loadChildren: () => import('./modules/integrations/integrations.module').then(m => m.IntegrationsModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'users',
    loadChildren: () => import('./modules/users/users.module').then(m => m.UsersModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'suggest-feature',
    loadChildren: () => import('./modules/suggest-feature/suggest-feature.module').then(m => m.SuggestFeatureModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'alerts',
    loadChildren: () => import('./modules/alerts/alerts.module').then(m => m.AlertsModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'statistics/scheduled-reports',
    data: { selectedMenu: 'scheduled-reports' },
    loadChildren: () => import('./modules/scheduled-reports/scheduled-reports.module').then(m => m.ScheduledReportsModule),
    canActivate: [ScheduledReportGuard]
  },
  {
    path: 'login',
    loadChildren: () => import('./modules/login/login.module').then(m => m.LoginModule),
  },
  {
    path: 'reset',
    loadChildren: () => import('./modules/reset/reset.module').then(m => m.ResetModule)
  },
  {
    path: 'activate/:key',
    loadChildren: () => import('./modules/activate/activate.module').then(m => m.ActivateModule),
  },
  {
    path: 'restore/:key',
    loadChildren: () => import('./modules/activate/activate.module').then(m => m.ActivateModule),
  },
  {
    path: 'technical-works',
    loadChildren: () => import('./pages/technical-works/technical-works.module').then(m => m.TechnicalWorksModule),
  },
  {
    path: '404',
    loadChildren: () => import('./modules/page404/page404.module').then(m => m.Page404Module),
  },
  {
    path: '**', redirectTo: '/404'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
