import { Component, Inject } from '@angular/core';
import { NZ_MODAL_DATA, NzModalRef } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent {

  public errorMessage?: string;

  constructor(
    @Inject(NZ_MODAL_DATA) public data: { title: string, text: string, rules: any },
    private dialogRef: NzModalRef<ConfirmDialogComponent>
  ) { }

  onCancel() {
    this.dialogRef.close({ confirm: false, text: this.data.text });
  }

  onConfirm(): void {
    const message = this.data.rules();

    if (message != null) {
      this.errorMessage = message;
      return;
    }

    if (!this.data.text.length) {
      this.errorMessage = 'Please fill in a preset name!';
      return;
    }
    if (this.data.text.trim().toLocaleLowerCase() === 'default') {
      this.errorMessage = 'The name of preset is already taken';
      return;
    }
    const data = {
      confirm: true,
      text: this.data.text
    };
    this.dialogRef.close(data);
  }

}
