import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzIconModule } from 'ng-zorro-antd/icon';

import { SelectMultiAntdControlComponent } from './select-multi-antd-control.component';

@NgModule({
  declarations: [SelectMultiAntdControlComponent],
  exports: [SelectMultiAntdControlComponent],
  imports: [
    CommonModule,
    NzSelectModule,
    FormsModule,
    NzIconModule,
    ReactiveFormsModule,
  ]
})
export class SelectMultiAntdControlModule { }
