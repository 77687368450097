<nz-table nzSize="small" [nzFrontPagination]="isShowFrontPagination" [nzShowPagination]="isShowPagination"
  [nzPageSize]="pageSize" [nzPageIndex]="pageIndex" [nzTotal]="total" [nzPageSizeOptions]="pageSizeOptions"
  [nzShowSizeChanger]="isShowSizeChanger" [nzScroll]="{x: '1360px', y: tableHeight}" [nzData]="tableData"
  (nzPageIndexChange)="onChangePageIndex($event)" (nzPageSizeChange)="onChangePageSize($event)">
  <thead>
    <tr>
      <ng-container *ngFor="let columnHead of tableConfig.columns">
        <th *ngIf="columnHead.columnFn(partnerId)" (click)="columnHead.sortable && sort(columnHead.rowProperty)"
          [nzWidth]="columnHead.width">
          {{columnHead.label}}
          <span *ngIf="columnHead.sortable">
            <span class="sorting-indicator" *ngIf="sortBy === columnHead.rowProperty">
              <span class="sort-icon sort-icon-{{ sortOrder }}"></span>
            </span>
            <span class="sorting-indicator" *ngIf="sortBy !== columnHead.rowProperty">
              <span class="sort-icon sort-icon-normal"></span>
            </span>
          </span>
        </th>
      </ng-container>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let td of tableData">
      <ng-container *ngFor="let rowAction of td.config.rowActions">
        <ng-container *ngIf="!editCache[td.id].edit; else editTemplate">
          <td *ngIf="rowAction.checkPartnerId(partnerId)">
            <span *ngIf="rowAction.hasFunc">{{rowAction.rowFn(td)}}</span>
            <span *ngIf="!rowAction.hasFunc && !rowAction.hasControl">{{!rowAction.pipe ? td[rowAction.rowName] :
              rowAction.rowFn(td) }}</span>
            <span *ngIf="rowAction.hasControl" class="controls">
              <span *ngFor="let actionRow of rowAction.action; last as isLast">
                <button *ngIf="actionRow.element === 'button'" nz-button nzType="text"
                  [disabled]="userRole === userRoleEnum.viewer" (click)="onRowActionClicked(td, actionRow.actionType)"
                  class="control" [class.last]="isLast">
                  <i nz-icon [nzType]="actionRow.iconName" nzTheme="outline"></i>
                  {{actionRow.label}}
                </button>
                <label *ngIf="actionRow.element === 'checkbox'" nz-checkbox
                  [nzDisabled]="userRole === userRoleEnum.viewer" [(ngModel)]="!!td[rowAction.rowName]"></label>
              </span>
            </span>
          </td>
        </ng-container>
        <ng-template #editTemplate>
          <td *ngIf="rowAction.checkPartnerId(partnerId)">
            <span *ngIf="rowAction.rowName === 'type'">{{rowAction.rowFn(td)}}</span>
            <span *ngIf="rowAction.hasControl" class="controls">
              <span *ngFor="let action of rowAction.action; last as isLast">
                <a *ngIf="action.element === 'button'" nz-button nzType="text"
                  (click)="onRowActionClicked(td, action.actionType)" class="control">
                  {{action.label}}
                </a>
                <label *ngIf="action.element === 'checkbox'" nz-checkbox
                  [(ngModel)]="!!editCache[td.id].data[rowAction.rowName]"></label>
                <input *ngIf="action.element === 'input'" nz-input class="form-control" type="text"
                  [(ngModel)]="editCache[td.id].data[rowAction.rowName]" />
                <nz-select *ngIf="action.element === 'select'" nzShowSearch [nzPlaceHolder]="action.placeholder"
                  [(ngModel)]="editCache[td.id].data[rowAction.rowName]" [nzDisabled]="rowAction.rowFn(td)">
                  <nz-option *ngFor="let item of action.selectItems" [nzValue]="item.id" [nzLabel]="item.name">
                  </nz-option>
                </nz-select>
                <a *ngIf="action.element === 'popconfirm'" nz-popconfirm nzPopconfirmTitle="Sure to cancel?"
                  (nzOnConfirm)="onRowActionClicked(td, action.actionType)">{{action.label}}</a>
              </span>
            </span>
          </td>
        </ng-template>
      </ng-container>
    </tr>
  </tbody>
</nz-table>