import { Component, EventEmitter, Input, OnInit, Output, OnChanges, SimpleChanges } from '@angular/core';

import { NotificationService } from '@core/services';
import { ChartInterface as IChart } from '@core/interfaces';
import { generateCardColor } from '@core/helpers';

import { DashboardsService } from '@modules/dashboards/services';
import { NotificationComponent } from '../notification';

@Component({
  selector: 'app-chart-cards',
  templateUrl: './chart-cards.component.html',
  styleUrls: ['./chart-cards.component.scss']
})
export class ChartCardsComponent implements OnInit, OnChanges {
  public get colors(): {
    color: string;
    name: string;
  }[] {
    return this._colors;
  }

  public get items() {
    return this._items;
  }

  public get names() {
    return this._names;
  }

  @Input()
  public set items(value: any) {
    this._items = value;
  }

  @Input()
  public set colors(
    value: {
      color: string;
      name: string;
    }[],
  ) {
    this._colors = value;
  }

  @Input()
  public set names(value: any) {
    this._names = value;
  }

  @Input() type: string;

  @Output()
  public toggleCard = new EventEmitter();

  public enabled: string[] = [];

  public cards: IChart.ICard[] = [];

  public get _cards() {
    return this.cards.filter((card) => card.show);
  }

  private _items: any = {};

  private _names: any = {};

  private _colors: { color: string; name: string }[] = [];

  constructor(
    private notificationService: NotificationService,
    private notificationComponent: NotificationComponent,
    private dashboardsService: DashboardsService,
  ) { }

  ngOnInit() {
    this.cards = this.createCards();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.items) {
      this.cards = this.createCards();
    }
  }


  public createCards() {
    const keys = Object.keys(this.items);
    const firstThreeCardKeys = keys.slice(0, 3);

    if (Object.keys(this.names).length > 0) {
      return keys.map(key => {
        return {
          name: this.names[key].name,
          value: this.items[key],
          enabled: firstThreeCardKeys.includes(key),
          color: generateCardColor(key),
          textColor: this.dashboardsService.generateTextColor(key),
          show: true,
          column: key,
        };
      });
    }
    else {
      return [];
    }
  }

  public onToggleMetric(card: IChart.ICard) {
    const enabledMetrics = this.cards
      .filter((card) => card.enabled);
    if (enabledMetrics.length === 3 && !card.enabled) {
      this.showNotification('info', 'info', 'info', 'It’s possible to select up to three metrics simultaneously.', null);
      return;
    }

    if (enabledMetrics.length === 1 && card.enabled) {
      this.showNotification('info', 'info', 'info', 'At least one metric should be selected.', null);
      return;
    }

    card.enabled = !card.enabled;

    this.toggleCard.emit(this.cards);
  }

  private showNotification(
    iconType: string,
    color: string,
    notificationType: string,
    description: string,
    width: string,
  ): void {
    const data = {
      iconType,
      color,
      notificationType,
      description,
    };

    this.notificationComponent.data = data;
    this.notificationService.showTemplate({ nzData: data, nzStyle: { width: width ?? '385px' } });
  }
}
