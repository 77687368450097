import { Injectable } from '@angular/core';

@Injectable()
export class DspService {
  playerSizes() {
    return [
      { id: '0x250', name: 'X-small' },
      { id: '250x349', name: 'Small' },
      { id: '350x500', name: 'Medium' },
      { id: '501x799', name: 'Large' },
      { id: '800x9999', name: 'X-Large' }
    ];
  }
}
