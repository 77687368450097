import { IDataTableConfig } from '../table-config';

export interface ResponseData {
  json_file_exists: boolean;
  seller_data: {
    current_page: 1
    data: SellersJsonTableData[];
    per_page: number;
    total: number;
  }
}

export interface SellersJsonTableData {
  contact: string;
  cookie_storage: string;
  cookie_sync: number;
  createdAt: string;
  date: string;
  deletedAt: string;
  domain: string;
  dspApiUrl: string;
  email: string;
  endpoint_sync: string;
  id: number | string;
  invoice: string;
  is_confidentiality: boolean;
  is_hide: boolean;
  name: string;
  partnerId: number;
  partner_id: number;
  seller_id: number;
  seller_type: string;
  sspApiUrl: string;
  start: string;
  sync_url: string;
  terms: number;
  type: string;
  updatedAt: string;
  second_seller_id: number;
  config: IDataTableConfig
}

export interface SellersJsonResData {
  json_file_exists: boolean;
  seller_data: {
    current_page: 1
    data: SellersJsonTableData[];
    per_page: number;
    total: number;
  }
}