import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NzIconModule } from 'ng-zorro-antd/icon';

import { NotificationComponent } from './notification.component';

@NgModule({
  declarations: [NotificationComponent],
  exports: [NotificationComponent],
  imports: [
    CommonModule,
    NzIconModule
  ]
})
export class NotificationModule { }
