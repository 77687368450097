export class Preset {
  id: number;
  name: string;
  isAggregateByDay: number;
  timeRange: string;
  region: string;
  presetAttributes: Array<PresetAttribute>;
}

export interface PresetAttribute {
  tooltip: string;
  type: string;
  column: string;
  items: any[];
  active: boolean | number;
  select_disabled: boolean;
  name: any;
  value: any[];
  allowedMetrics: any;
  side: string | null;
  enabled: boolean;
}

export interface Metric {
  name: string;
  active: boolean;
  disabled: boolean;
  column: string;
  tooltip: string;
  value: number;
  conditions: object;
  condition: number | string;
  side: string;
}

export interface Preset {
  editable: boolean;
  id: number;
  partner_id: number;
  name: string;
  is_editable: boolean;
  selected: boolean;
  settings: {
    name: object;
    attributes: PresetAttribute[],
    metrics: Metric[],
    filter: {
      region: {
        items?: object,
        value?: string;
      },
      group: {
        items?: object,
        value?: string;
      },
      period: {
        value: {
          from?: string;
          to?: string;
        }
      },
      timezone: {
        items?: object,
        value?: string;
      }
    }
  };
  created_at: string;
  updated_at: string;
  temp: number;
  locked: string | null;
}

export interface FieldsData {
  attr: string;
  label: string;
  active: boolean;
  disabled: boolean;
  value: string;
}

export interface PresetTableResponse {
  preset: Preset;
  data: PresetTableData;
}


export interface PresetTableData {
  columns: { value: string; key: string }[];
  count: number;
  debug: string;
  rows: {
    date_export: string;
    dspId: number;
    dspName: string;
    responses: string;
    sspId: number;
    sspName: string;
    ssp_requests: string;
  }[];
  totals: object;
}

export const Tooltips = {
  ssp_requests: 'Number of requests received from SSP',
  requests: 'Number of requests sent to DSP',
  responses: 'Number of responses received from DSP',
  wins: 'Number of wins in the internal platform`s auction among all DSPs connected',
  dsp_win_rate: 'DSP Wins / DSP Responses * 100%',
  ssp_wins: 'Number of wins in SSP`s auction',
  ssp_win_rate: 'SSP Wins / DSP Wins * 100%',
  imps: 'Number of ads server to the user and counted by platform tracker',
  fill_rate: 'Impressions / Bid Requests * 100%',
  render_rate: 'Impressions / SSP Wins * 100%',
  ssp_price: 'Total SSP spend for the traffic bought by DSP',
  ssp_ecpm: 'Average price for 1000 impressions SSP earned. Formula: SSP Spend / Impressions * 1000',
  ssp_rcpm: 'SSP Spend / SSP Requests * 1000000',
  avg_ssp_bid_floor: 'Average bid floor that is received in the request from SSP',
  avg_ssp_bid_price: 'Average bid price for 1000 impressions that is sent in the response to SSP',
  dsp_price: 'Total DSP spend for the traffic',
  dsp_ecpm: 'Average price for 1000 impressions DSP paid. Formula: DSP Spend / Impressions * 1000',
  dsp_rcpm: 'DSP Spend / Bid Requests * 1000000',
  avg_dsp_bid_floor: 'Average bid floor that is sent in the request to DSP. It includes both SSP and DSP markups',
  avg_dsp_bid_price: 'Average bid price for 1000 impressions that DSP bid with',
  platform_revenue: 'Ad Exchange profit which is calculated as DSP Spend - SSP Spend',
  bid_rate: 'DSP Wins / SSP Requests * 100%',
  total_bid_rate: 'DSP Responses / Bid Requests * 100%',
  timeout_rate: 'Timeouts / Bid requests * 100%',
  sspName: 'SSP Endpoint',
  sspCompanyName: 'SSP Company',
  sspEndpointType: 'SSP Endpoint Type',
  sspEndpointUrl: 'SSP Endpoint URL',
  dspName: 'DSP Endpoint',
  dspCompanyName: 'DSP Company',
  dspEndpointType: 'DSP Endpoint Type',
  dspEndpointUrl: 'DSP Endpoint URL',
  geoName: 'GEO',
  platformName: 'Traffic Type',
  trafficName: 'Ad Format',
  size: 'Size',
  inventory_key: 'Domain/App Bundle',
  publisher_id: 'Publisher ID',
  crid: 'CRID',
  osName: 'OS',
  ssp_cookie_requests: 'Number of SSP requests that include user id',
  platform_cookies: 'Number of SSP requests with synced user ids',
  cookie_requests: 'Number of bid requests sent to the DSP that include user id',
  ctr_rate: 'Clicks/Impressions*100%',
  vcr_rate: 'Video complete/Impression*100%',
  video_events_complete: 'Video complete',
  clicks: 'Clicks',
};


export const tableWidths = {
  ssp_requests: '200px',
  requests: '200px',
  responses: '200px',
  wins: '200px',
  dsp_win_rate: '200px',
  ssp_wins: '200px',
  ssp_win_rate: '200px',
  imps: '200px',
  fill_rate: '200px',
  render_rate: '200px',
  ssp_price: '200px',
  ssp_ecpm: '200px',
  ssp_rcpm: '200px',
  avg_ssp_bid_floor: '200px',
  avg_ssp_bid_price: '200px',
  dsp_price: '200px',
  dsp_ecpm: '200px',
  dsp_rcpm: '200px',
  avg_dsp_bid_floor: '200px',
  avg_dsp_bid_price: '200px',
  platform_revenue: '200px',
  bid_rate: '200px',
  total_bid_rate: '200px',
  timeout_rate: '200px',
  sspName: '200px',
  sspCompanyName: '200px',
  sspEndpointType: '280px',
  sspEndpointUrl: '200px',
  dspName: '200px',
  dspCompanyName: '200px',
  dspEndpointType: '280px',
  dspEndpointUrl: '200px',
  geoName: '200px',
  platformName: '200px',
  trafficName: '200px',
  size: '200px',
  inventory_key: '280px',
  publisher_id: '200px',
  crid: '200px',
  osName: '200px',
  ssp_cookie_requests: '280px',
  platform_cookies: '280px',
  cookie_requests: '280px',
  date_export: '200px',
  video_events_complete: '200px',
  ctr_rate: '180px',
  vcr_rate: '180px',
  clicks: '180px',
};

export const tableFields = [
  { attr: 'ssp_requests', label: 'SSP Requests', active: true, disabled: false, value: 'metric' },
  { attr: 'requests', label: 'Bid Requests', active: true, disabled: false, value: 'metric' },
  { attr: 'responses', label: 'DSP Responses', active: true, disabled: false, value: 'metric' },
  { attr: 'wins', label: 'DSP Wins', active: true, disabled: false, value: 'metric' },
  { attr: 'dsp_win_rate', label: 'DSP Win Rate, %', active: true, disabled: false, value: 'metric' },
  { attr: 'ssp_wins', label: 'SSP Wins', active: true, disabled: false, value: 'metric' },
  { attr: 'ssp_win_rate', label: 'SSP Win Rate, %', active: true, disabled: false, value: 'metric' },
  { attr: 'imps', label: 'Impressions', active: true, disabled: false, value: 'metric' },
  { attr: 'fill_rate', label: 'Fill rate, %', active: true, disabled: false, value: 'metric' },
  { attr: 'render_rate', label: 'Render Rate, %', active: true, disabled: false, value: 'metric' },
  { attr: 'ssp_price', label: 'SSP Spend', active: true, disabled: false, value: 'metric' },
  { attr: 'ssp_ecpm', label: 'SSP eCPM', active: true, disabled: false, value: 'metric' },
  { attr: 'ssp_rcpm', label: 'SSP RCPM', active: true, disabled: false, value: 'metric' },
  { attr: 'avg_ssp_bid_floor', label: 'Avg. SSP Bidfloor', active: true, disabled: false, value: 'metric' },
  { attr: 'avg_ssp_bid_price', label: 'Avg. SSP Bid Price', active: true, disabled: false, value: 'metric' },
  { attr: 'dsp_price', label: 'DSP Spend', active: true, disabled: false, value: 'metric' },
  { attr: 'dsp_ecpm', label: 'DSP eCPM', active: true, disabled: false, value: 'metric' },
  { attr: 'dsp_rcpm', label: 'DSP RCPM', active: true, disabled: false, value: 'metric' },
  { attr: 'avg_dsp_bid_floor', label: 'Avg. DSP Bidfloor', active: true, disabled: false, value: 'metric' },
  { attr: 'avg_dsp_bid_price', label: 'Avg. DSP Bid Price', active: true, disabled: false, value: 'metric' },
  { attr: 'platform_revenue', label: 'Profit', active: true, disabled: false, value: 'metric' },
  { attr: 'bid_rate', label: 'Valid Bid Rate, %', active: true, disabled: false, value: 'metric' },
  { attr: 'total_bid_rate', label: 'Total Bid Rate, %', active: true, disabled: false, value: 'metric' },
  { attr: 'timeout_rate', label: 'Timeout rate, %', active: true, disabled: false, value: 'metric' },
  { attr: 'date_export', label: 'Date', active: true, disabled: false, value: 'attribute' },
  { attr: 'sspName', label: 'SSP Endpoint', active: true, disabled: false, value: 'attribute' },
  { attr: 'sspCompanyName', label: 'SSP Company', active: true, disabled: false, value: 'attribute' },
  { attr: 'sspEndpointType', label: 'SSP Endpoint Type', active: true, disabled: false, value: 'attribute' },
  { attr: 'sspEndpointUrl', label: 'SSP Endpoint URL', active: true, disabled: false, value: 'attribute' },
  { attr: 'dspName', label: 'DSP Endpoint', active: true, disabled: false, value: 'attribute' },
  { attr: 'dspCompanyName', label: 'DSP Company', active: true, disabled: false, value: 'attribute' },
  { attr: 'dspEndpointType', label: 'DSP Endpoint Type', active: true, disabled: false, value: 'attribute' },
  { attr: 'dspEndpointUrl', label: 'DSP Endpoint URL', active: true, disabled: false, value: 'attribute' },
  { attr: 'geoName', label: 'GEO', active: true, disabled: false, value: 'attribute' },
  { attr: 'platformName', label: 'Traffic Type', active: true, disabled: false, value: 'attribute' },
  { attr: 'trafficName', label: 'Ad Format', active: true, disabled: false, value: 'attribute' },
  { attr: 'size', label: 'Size', active: true, disabled: false, value: 'attribute' },
  { attr: 'inventory_key', label: 'Domain/App Bundle', active: true, disabled: false, value: 'attribute' },
  { attr: 'publisher_id', label: 'Publisher ID', active: true, disabled: false, value: 'attribute' },
  { attr: 'crid', label: 'CRID', active: true, disabled: false, value: 'attribute' },
  { attr: 'osName', label: 'OS', active: true, disabled: false, value: 'attribute' },
  { attr: 'ssp_cookie_requests', label: 'SSP requests with User ID', active: true, disabled: false, value: 'metric' },
  { attr: 'platform_cookies', label: 'Synced cookies with SSP', active: true, disabled: false, value: 'metric' },
  { attr: 'cookie_requests', label: 'Buyer ids', active: true, disabled: false, value: 'metric' },
];
