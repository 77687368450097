import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';

import { NgxSpinnerService } from 'ngx-spinner';

import { AppOptionsService, ErrorsHandlingService } from '@core/services';
import { calculatePercentages, filterNormalize, updateSeriesArray } from '@core/helpers';
import { generatedColors } from '@core/constants';

import { DashboardsInterface } from '../models';

@Injectable({
  providedIn: 'root'
})
export class DashboardSourcesService {

  public dashboardSourcesOverallSeries: any[] = [];
  public dashboardSourcesOverallLimitedSeries: any[] = [];
  public dashboardSourcesOverallChartOptions = null;
  public dashboardSourcesOverallLegends: any[] = [];
  public dashboardSourcesOverallTableData: DashboardsInterface.ISourceChartRes[] = [];

  public dashboardSourcesSspSeries: any[] = [];
  public dashboardSourcesSspLimitedSeries: any[] = [];
  public dashboardSourcesSspChartOptions = null;
  public dashboardSourcesSspLegends: any[] = [];
  public dashboardSourcesTableDataSSP: DashboardsInterface.IPieChartSourceTableData[] = [];

  public dashboardSourcesDspSeries: any[] = [];
  public dashboardSourcesDspLimitedSeries: any[] = [];
  public dashboardSourcesDspChartOptions = null;
  public dashboardSourcesDspLegends: any[] = [];
  public dashboardSourcesTableDataDSP: DashboardsInterface.IPieChartSourceTableData[] = [];

  public set sspSourcesSpinnerStatus(value: boolean) {
    this._sspSourcesSpinnerStatus = value;
  }

  public get sspSourcesSpinnerStatus(): boolean {
    return this._sspSourcesSpinnerStatus;
  }

  public set dspSourcesSpinnerStatus(value: boolean) {
    this._dspSourcesSpinnerStatus = value;
  }

  public get dspSourcesSpinnerStatus(): boolean {
    return this._dspSourcesSpinnerStatus;
  }

  public set overallSourcesSpinnerStatus(value: boolean) {
    this._overallSourcesSpinnerStatus = value;
  }

  public get overallSourcesSpinnerStatus(): boolean {
    return this._overallSourcesSpinnerStatus;
  }

  private _sspSourcesSpinnerStatus = false;
  private _dspSourcesSpinnerStatus = false;
  private _overallSourcesSpinnerStatus = false;

  private apiUrl = '';

  constructor(
    private http: HttpClient,
    private options: AppOptionsService,
    private errorsHandlingService: ErrorsHandlingService,
    private loadingService: NgxSpinnerService,
  ) {
    this.apiUrl = this.options.getApiUrl();
  }


  public generateSourceDashboardHandler(reqData: DashboardsInterface.ISourceChartReqData): void {
    this.nullificationSourceDashboard();
    this.getAllSourceCharts(reqData);
  }

  private getAllSourceCharts(reqData: DashboardsInterface.ISourceChartReqData): void {
    this.getSourceDashboardSSPChart(reqData);
    this.getSourceDashboardDSPChart(reqData);
    this.getSourceDashboardOverallChart(reqData);
  }

  private nullificationSourceDashboard(): void {
    this.dashboardSourcesTableDataDSP = [];
    this.dashboardSourcesTableDataSSP = [];
    this.dashboardSourcesOverallTableData = [];
    this.dashboardSourcesSspSeries = [];
    this.dashboardSourcesDspSeries = [];
    this.dashboardSourcesOverallSeries = [];
  }

  private getColorByIndex(index: number): string {
    const colorsArray = Array.from(generatedColors);
    return colorsArray[index];
  }

  private transformToSourcePieChart(chart: {
    name: string;
    value: any;
    color: string;
    enabled: boolean;
    endpoint?: string;
    dsp?: string;
    inventory_key?: string;
  }[]) {
    const newChart = chart.map(item => {
      item.name = item.name ? item.name : item.endpoint;
      delete item.endpoint;
      delete item.dsp;
      return item;
    });
    const sortedChart = [...newChart].sort((a, b) => b.value - a.value);
    const dynamicLabels = sortedChart.map(item => item['inventory_key']);

    const transformedData = sortedChart.map(item => item['value']);

    const transformedPercentageData = calculatePercentages(transformedData);

    const top5Values = transformedPercentageData.slice(0, 5);

    const chartOptions: Partial<DashboardsInterface.ChartOptions> = {
      chart: {
        type: 'donut',
        width: 450,
      },
      labels: dynamicLabels,
      tooltip: {
        enabled: true,
        y: {
          formatter: function (val) {
            if (val > 1000) {
              return `${(val / 1000).toFixed(2)} K$`;
            }
            return `${val.toFixed(2).toString()}$`;
          }
        }
      },
      responsive: [
        {
          breakpoint: 180,
          options: {
            chart: {
              width: 400
            },
            dataLabels: {
              enabled: true,
            },
          }
        }
      ],
      legend: {
        show: false,
      },
      dataLabels: {
        formatter: function (labelValue: number, opts) {
          const formattedVal = Math.round(labelValue);
          const label = top5Values.find((top5Value, idx) => top5Value === formattedVal && opts.seriesIndex === idx);
          if (label) {
            return `${label}%`;
          } else {
            return '';
          }
        },
      },
      plotOptions: {
        pie: {
          dataLabels: {
            minAngleToShowLabel: 0
          }
        }
      },
      colors: Array.from(generatedColors).map(color => color),
    };

    const newSortedChat = JSON.parse(JSON.stringify(sortedChart));

    const sortedSeries = [...newSortedChat].map((item, idx) => {
      item.color = chartOptions.colors[idx];
      item.enabled = true;
      return item;
    });

    let series = newChart.map((item, idx) => {
      item.color = this.getColorByIndex(idx);
      item.enabled = true;
      return item;
    });

    series = updateSeriesArray([...sortedSeries], [...series], 'inventory_key');

    return {
      series,
      chartOptions,
      transformedData
    };
  }

  private getSourceDashboardSSPChart(reqData: DashboardsInterface.ISourceChartReqData): void {
    this.loadingService.show('ssp');
    this.getSpinnerStatus('ssp', 'sspSourcesSpinnerStatus');
    this.getSourceChart<DashboardsInterface.IPieChartSourceTableData>(filterNormalize({ ...reqData, type: 'ssp' }))
      .pipe(
        finalize(() => this.loadingService.hide('ssp'))
      ).subscribe(res => {
        this.dashboardSourcesTableDataSSP = res.data;
        const sspChart = this.transformToSourcePieChart(res.data);
        this.dashboardSourcesSspSeries = sspChart.transformedData;
        this.dashboardSourcesSspLimitedSeries = JSON.parse(JSON.stringify(sspChart.transformedData));
        this.dashboardSourcesSspChartOptions = sspChart.chartOptions;
        this.dashboardSourcesSspLegends = sspChart.series;
      },
        error => this.errorsHandlingService.handleError(error));
  }

  private getSourceDashboardDSPChart(reqData: DashboardsInterface.ISourceChartReqData): void {
    this.loadingService.show('dsp');
    this.getSpinnerStatus('dsp', 'dspSourcesSpinnerStatus');
    this.getSourceChart<DashboardsInterface.IPieChartSourceTableData>(filterNormalize({ ...reqData, type: 'dsp' }))
      .pipe(
        finalize(() => this.loadingService.hide('dsp'))
      )
      .subscribe(res => {
        this.dashboardSourcesTableDataDSP = res.data;
        const dspChart = this.transformToSourcePieChart(res.data);
        this.dashboardSourcesDspSeries = dspChart.transformedData;
        this.dashboardSourcesDspLimitedSeries = JSON.parse(JSON.stringify(dspChart.transformedData));
        this.dashboardSourcesDspChartOptions = dspChart.chartOptions;
        this.dashboardSourcesDspLegends = dspChart.series;
      },
        error => this.errorsHandlingService.handleError(error));
  }

  private getSourceDashboardOverallChart(reqData: DashboardsInterface.ISourceChartReqData): void {
    this.loadingService.show('ssp_dsp');
    this.getSpinnerStatus('ssp_dsp', 'overallSourcesSpinnerStatus');
    this.getSourceChart<DashboardsInterface.ISourceChartRes>(filterNormalize({ ...reqData, type: 'ssp_dsp' }))
      .pipe(
        finalize(() => this.loadingService.hide('ssp_dsp'))
      )
      .subscribe(res => {
        this.dashboardSourcesOverallTableData = JSON.parse(JSON.stringify(res.data));
        const overallChart = this.transformToSourcePieChart(res.data);
        this.dashboardSourcesOverallSeries = overallChart.transformedData;
        this.dashboardSourcesOverallLimitedSeries = JSON.parse(JSON.stringify(overallChart.transformedData));
        this.dashboardSourcesOverallChartOptions = overallChart.chartOptions;
        this.dashboardSourcesOverallLegends = overallChart.series;
      },
        error => this.errorsHandlingService.handleError(error));
  }


  public getSpinnerStatus(spinnerName: string, name: string): void {
    this.loadingService.getSpinner(spinnerName).subscribe(res => {
      this[name] = res.show;
    });
  }

  private getSourceChart<T>(filter: DashboardsInterface.ISourceChartReqData): Observable<{ data: T[] }> {
    return this.http.post<{ data: T[] }>(`${this.apiUrl}/analytics-dashboards/sources`, { filter: filter })
      .pipe(catchError(this.errorsHandlingService.processError));
  }
}
