export interface AppOptions {
  ssp: { attr: string, label: string, tooltip: string, active: boolean }[];
  dsp: { attr: string, label: string, tooltip: string, active: boolean }[];
  screen?: string;
}

export interface StatisticOptions {
  ssp: { attr: string, label: string, active: boolean }[];
  dsp: { attr: string, label: string, active: boolean }[];
}

export interface AdminOptionsActivity {
  ssp: { id: string, name: string }[];
  dsp: { id: string, name: string }[];
  sspConnections: { id: number; name: string }[];
  dspConnections: { id: number, name: string }[];
  sspSelected: string;
  dspSelected: string;
  sspSelectedConnection: string;
  dspSelectedConnection: string;
}


export interface MainOptionActivity {
  ssp: { id: string, name: string }[],
  dsp: { id: string, name: string }[],
  sspConnections: { id: number, name: string }[],
  dspConnections: { id: number, name: string }[],
  ad_formats: { id: string, name: string }[],
  ad_formats_dsp: { id: string, name: string }[],
  sspSelected: string;
  dspSelected: string;
  sspSelectedConnection: string;
  dspSelectedConnection: string;
  sspSelectedFormats: string;
  dspSelectedFormats: string;
}

export interface Lists {
  bundles: string,
  sites: string,
  'blocked-publishers': string,
  'blocked-sites': string,
  'blocked-crids': string,
}

export interface MainLists {
  bundles: string;
  sites: string;
  'blocked-publishers': string;
  'blocked-sites': string;
  'blocked-crids': string;
  publishers: string;
  'blocked-domains': string;
}

