import { TMTSettings, BotmanSettings, PixalatePreBidSettings } from '../settings';

export class Partner {
  id: number;
  tmtSettings: TMTSettings;
  botman_settings: BotmanSettings;
  pixalate_pb_settings: PixalatePreBidSettings;
  dspSettings: object[];
  sspSettings: object[];

  constructor(
    public name: string = 'test2',
    public title: string = '',
    public ns: string = '',
    public prefix: string = '',
    public loginLogo: string = '/assets/img/logo.svg',
    public headerLogo: string = '/assets/img/logo.svg',
    public favicon: string = '',
    public useTMT: boolean = false,
    public useGeoedge: boolean = false,
    public useForensiq: boolean = false,
    public connectedPixalatePrebid: boolean = false,
    public connectedPMPrebid: boolean = false,
    public usePixalatePrebid: boolean = false,
    public usePM: boolean = false,
    public useTitle: boolean = false,
    public regions: boolean = false,
    public adstxtTagId: string = '',
    public overQps: boolean = false,
    public isServiceDomain: boolean = false,
    public useSsl: boolean = false,
    public frontendAppDomain: string = '',
    public usePMPreBid: boolean = false,
    public useBotman: boolean = false,
    public revshare: number = null,
    public smarthub_account: boolean = false,
    public hasAccessToAudio: boolean = false,
    public is_traffic_analyzer = 0,
  ) { }
}

export interface CheckDomainResponse {
  success: boolean;
  data: Partner;
}

export interface PartnerInt {
  id: number;
  tmtSettings: TMTSettings;
  botman_settings: BotmanSettings;
  pixalate_pb_settings: PixalatePreBidSettings;
  dspSettings: object[];
  sspSettings: object[];
  name: string;
  title: string;
  ns: string;
  prefix: string;
  loginLogo: string;
  headerLogo: string;
  favicon: string;
  useTMT: boolean;
  useGeoedge: boolean;
  useForensiq: boolean;
  connectedPixalatePrebid: boolean;
  connectedPMPrebid: boolean;
  usePixalatePrebid: boolean;
  usePM: boolean;
  useTitle: boolean;
  regions: boolean;
  adstxtTagId: string;
  overQps: boolean;
  isServiceDomain: boolean;
  useSsl: boolean;
  frontendAppDomain: string;
  usePMPreBid: boolean;
  useBotman: boolean;
  revshare: number;
  smarthub_account: boolean;
  hasAccessToAudio: boolean;
  is_traffic_analyzer: number;
}
