
export class TodoItemNode {
  id: number;
  children: TodoItemNode[];
  name: string;
  parentId: number;
  order: number;
}

/** Flat to-do item node with expandable and level information */
export class TodoItemFlatNode {
  id: number;
  name: string;
  level: number;
  order: number;
  expandable: boolean;
}

export const TREE_DATA = {
  Groceries: {
    'Almond Meal flour': null,
    'Organic eggs': null,
    'Protein Powder': null,
    Fruits: {
      Apple: null,
      Berries: ['Blueberry', 'Raspberry'],
      Orange: null,
    },
  },
  Reminders: [
    'Cook dinner',
    'Read the Material Design spec',
    'Upgrade Application to Angular',
  ],
};
