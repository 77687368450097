import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { StatHeaderComponent } from './stat-header.component';
import { QpsHeadItemModule } from '../qps-head-item';
import { ClickOutsideModule } from '@shared/directives';

@NgModule({
  declarations: [StatHeaderComponent],
  exports: [StatHeaderComponent],
  imports: [
    CommonModule,
    QpsHeadItemModule,
    ClickOutsideModule
  ]
})
export class StatHeaderModule { }
