import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';

import { timer, Subscription } from 'rxjs';

import { DataManagerService, ErrorsHandlingService } from '@core/services';
import { statistic } from './stat-header.const';

const toggleArrow = trigger('toggleArrow', [
  state('none', style({ transform: 'rotate(90deg)' })),
  state('visible', style({ transform: 'rotate(270deg)' })),
  transition('visible => none', animate('300ms ease-in')),
  transition('none => visible', animate('300ms ease-out')),
]);

@Component({
  selector: 'app-stat-header',
  templateUrl: './stat-header.component.html',
  styleUrls: ['./stat-header.component.scss'],
  animations: [toggleArrow]
})
export class StatHeaderComponent implements OnInit {
  @Output() selectedEndpoint = new EventEmitter<{ id: number, active: boolean }>();
  partnerQps = {
    incomingReal: 0,
    outgoingReal: 0,
    incomingStandart: 10000,
    outgoingStandart: 10000,
  };

  sspEndpoints = [];
  dspEndpoints = [];

  headerInfoState: { [key: string]: boolean } = {};

  public subscriptionAlert: Subscription;

  public netAlert: any;

  statistic = statistic;

  overQps = false;
  dspHeadOpen = false;
  sspHeadOpen = false;
  public limits: any[] = [];

  constructor(
    private dataManagerService: DataManagerService,
    private readonly errorsHandlingService: ErrorsHandlingService,
  ) { }

  ngOnInit(): void {
    this.subscriptionAlert = timer(1000, 300000).subscribe(() => {
      this.dataManagerService.checkAlerts()
        .subscribe(data => {
          Object.values(data).forEach(item => {
            this.netAlert = item;
          });
        });
    });

    this.dataManagerService.getPartnerQpsOverLimit()
      .subscribe(data => {
        this.overQps = data.isOverQps;
      },
        error => this.errorsHandlingService.handleError(error));

    this.dataManagerService.getPartnerQpsStatistic()
      .subscribe(data => {

        Object.values(data).forEach((item: any) => {

          this.partnerQps = item.partperQps;

          const dspEndpoints = [];
          for (let i = 0; i < item.dspQps.length; i++) {
            dspEndpoints.push({ attr: item.dspQps[i].name, label: item.dspQps[i].realQPS, active: false, id: item.dspQps[i].id });
          }

          const sspEndpoints = [];
          for (let i = 0; i < item.sspQps.length; i++) {
            sspEndpoints.push({ attr: item.sspQps[i].name, label: item.sspQps[i].qps, active: false, id: item.sspQps[i].id });
          }

          this.limits.push({
            region: item.region,
            partnerQps: item.partperQps,
            sspEndpoints: sspEndpoints,
            dspEndpoints: dspEndpoints,
            dspHeadOpen: false,
            sspHeadOpen: false
          });
        });
      },
        error => this.errorsHandlingService.handleError(error));

    this.dataManagerService.getRevenue()
      .subscribe(data => {
        for (const type in data.revenue) {
          this.statistic[type].today.total = data.revenue[type].todayTotals.dspSpend - data.revenue[type].todayTotals.sspSpend;
          this.statistic[type].yesterday.total = data.revenue[type].yesterdayTotals.dspSpend - data.revenue[type].yesterdayTotals.sspSpend;

          for (const day in this.statistic[type]) {

            if (this.statistic[type].hasOwnProperty(day)) {
              for (let i = 0; i < data.revenue[type][day].length; i++) {

                const typeValue = data.revenue[type][day][i][type];
                if (this.statistic[type][day][typeValue]) {
                  this.statistic[type][day][typeValue].revenue = data.revenue[type][day][i].dspSpend - data.revenue[type][day][i].sspSpend;
                  // eslint-disable-next-line max-len
                  this.statistic[type][day][typeValue].percent = (this.statistic[type][day][typeValue].revenue * 100) / this.statistic[type][day].total;
                }
              }
            }
          }
        }
      },
        error => this.errorsHandlingService.handleError(error));


  }

  toggleHeaderInfoState(id: string): void {
    this.headerInfoState[id] = !this.headerInfoState[id];
    for (const i in this.headerInfoState) {
      if (i !== id) {
        this.headerInfoState[i] = false;
      }
    }
  }

  isVisibleInfoContent(id: string): string {
    return this.headerInfoState[id] ? 'visible' : 'none';
  }

  translateEndpoint(target): void {
    this.selectedEndpoint.emit(target);
  }

}
