export enum DayByFilter {
  '2_days' = 2,
  '3_days' = 3,
  '4_days' = 4,
  '5_days' = 5,
  '6_days' = 6,
  'last_7_days' = 7,
}

export enum HoursByFilter {
  '2_days' = 4,
  '3_days' = 6,
  '4_days' = 8,
  '5_days' = 12,
  '6_days' = 12,
  'last_7_days' = 24,
}

export enum DayByFilterForMetric {
  'today' = 1,
  'yesterday' = 2,
  'last_3_days' = 3,
  'last_7_days' = 7,
  'last_30_days' = 30,
}

export enum HoursByFilterForMetric {
  'today' = 24,
  'yesterday' = 24,
  'last_3_days' = 6,
  'last_7_days' = 12,
  'last_30_days' = 24,
}

export enum GroupBy {
  'today' = 'today',
  'yesterday' = 'yesterday',
  'last_3_days' = 'last_3_days',
  'last_7_days' = 'last_7_days',
}