<div class="content-list__top">
  <nz-input-group [nzPrefix]="prefixTemplateUser">
    <input [(ngModel)]="searchValue" name="bundles" id="bundles" (input)="onSearchInput($event, true)" type="text"
      nz-input placeholder="Search..." />
  </nz-input-group>
  <ng-template #prefixTemplateUser>
    <i nz-icon nzType="search" nzTheme="outline"></i>
  </ng-template>
</div>
<div appScrollTrigger (scrolled)="onScroll()" class="checkboxes-list" [style.height]="listHeight"
  [style.max-height]="listMaxHeight">
  <div class="checkboxes-list__all">
    <label nz-checkbox [nzDisabled]="isDisabledAll" [(ngModel)]="allChecked" (ngModelChange)="updateAllChecked()">
      All Endpoints
    </label>
  </div>
  <div class="checkboxes" *ngFor="let endpoint of endpoints; let isLast=last">
    <label class="item"
      [ngStyle]="{ 'border-bottom': isLast ? 'none' : '1px solid #a8b7c7', 'color': !endpoint.active ? '#A8B7C7' : '#000' }"
      nz-checkbox [nzDisabled]="endpoint.disabled" [(ngModel)]="endpoint.checked"
      (ngModelChange)="updateSingleChecked($event, endpoint)" nz-tooltip nzTooltipOverlayClassName="item-tooltip"
      [nzTooltipTitle]="endpoint.label" nzTooltipPlacement="top">{{endpoint.label | trunc:[25]}}</label>
  </div>
</div>
