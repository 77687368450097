import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SectionSspComponent } from './section-ssp.component';

@NgModule({
  declarations: [SectionSspComponent],
  exports: [SectionSspComponent],
  imports: [
    CommonModule
  ]
})
export class SectionSspModule { }
