import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { NzSelectModule } from 'ng-zorro-antd/select';

import { TimezoneControlComponent } from './timezone-control.component';



@NgModule({
  declarations: [TimezoneControlComponent],
  exports: [TimezoneControlComponent],
  imports: [
    CommonModule,
    NzSelectModule,
    ReactiveFormsModule,
  ]
})
export class TimezoneControlModule { }
