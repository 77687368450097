import { NzModalModule } from 'ng-zorro-antd/modal';
import { FormsModule } from '@angular/forms';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DeleteEndpointConfirmComponent } from './delete-endpoint-confirm.component';

@NgModule({
  declarations: [DeleteEndpointConfirmComponent],
  exports: [DeleteEndpointConfirmComponent],
  imports: [
    CommonModule,
    FormsModule,
    NzButtonModule,
    NzModalModule,
  ]
})
export class DeleteEndpointConfirmModule { }
