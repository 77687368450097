export interface Scanners {
  id: string;
  name: string;
  active?: boolean;
}
export interface ScannerEndpoints {
  id: number;
  name: string;
}
export interface SelectItem {
  id: string;
  name: string;
  active?: boolean;
}
export interface BlockReason {
  id: string | number;
  name: string;
  active?: boolean;
}

export interface ScannerStatisticFilters {
  logEvents: BlockReasons[];
}

export interface BlockReasons {
  id: number;
  name: string;
}


export interface ResponsePixalataReport {
  count: any;
  data: TableDataPixalateReport[];
  dates?: {
    base: string[];
    compare: string[]
  };
  pageCount: number;
  perPage: number;
  type: string;
  columns: { name: string; key: string }[];
}

export interface TableDataPixalateReport {
  base?: {
    blocked: string;
    date_export: string;
    dsp_name: string;
    inventory_key: string;
    ivt: number;
    reason_name: string;
    ssp_name: string;
  };
  compare?: {
    blocked: string;
    date_export: string;
    dsp_name: string;
    inventory_key: string;
    ivt: number;
    reason_name: string;
    ssp_name: string;
  },
  blocked?: string;
  date_export?: string;
  dsp_name?: string;
  inventory_key?: string;
  ivt?: number;
  reason_name?: string;
  ssp_name?: string;
}


export interface RequestData {
  groupByDate: any;
  period: {
    base: {
      from: string;
      to: string;
    };
    compare: {
      from: string;
      to: string;
    };
  };
  filter: {
    endpoint_id: any;
    dsp_id: any;
    inventory_key: any;
    reason: any;
  };
  type: string;
  columns: string[];
  sort: {
    sortBy: string;
    sortOrder: number;
  };
  limit: number;
  offset: number;
}