export const whitelistedDomains: (string | RegExp)[] = ['localhost:4200',
  'vitalya.smartkernel.public.testsmartyads.com',
  'smartkernel.public.localhost',
  'smartkernelapi.public.localhost',
  // RegExp("^http:\/\/smartkernelapi\..*$"),
  '88.214.194.25:8000',
  '88.214.224.3:8080',
  'apismarthub.smartyads.com', 'smartkernel.localhost', 'api.smartkernel.local',
  'new-api.smart-hub.io'
];

export const blacklistedRoutes: (string | RegExp)[] = ['localhost:4200/login',
  'localhost:4200/register',
  'vitalya.smartkernel.public.testsmartyads.com/api/refresh',
  'smartkernel.public.localhost'];
