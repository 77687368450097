import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';

import { NgxSpinnerService } from 'ngx-spinner';

import { AppOptionsService, ErrorsHandlingService } from '@core/services';
import { filterNormalize, calculatePercentages, updateSeriesArray } from '@core/helpers';
import { generatedColors } from '@core/constants';

import { DashboardsInterface } from '../models';

@Injectable({
  providedIn: 'root'
})
export class DashboardPerformanceService {

  public dashboardPerformanceTotalProfitTableData: DashboardsInterface.IPerformanceTotalData[] = [];

  public dashboardPerformanceSspSeries: any[] = [];
  public dashboardPerformanceSspLimitedSeries: any[] = [];
  public dashboardPerformanceSspChartOptions = null;
  public dashboardPerformanceSspLegends: any[] = [];
  public dashboardPerformanceTableDataSSP: DashboardsInterface.IPieChartBaseTableData[] = [];

  public dashboardPerformanceDspSeries: any[] = [];
  public dashboardPerformanceDspLimitedSeries: any[] = [];
  public dashboardPerformanceDspChartOptions = null;
  public dashboardPerformanceDspLegends: any[] = [];
  public dashboardPerformanceTableDataDSP: DashboardsInterface.IPieChartBaseTableData[] = [];

  public dashboardPerformanceTrafficTypeSeries: any[] = [];
  public dashboardPerformanceTrafficTypeLimitedSeries: any[] = [];
  public dashboardPerformanceTrafficTypeChartOptions = null;
  public dashboardPerformanceTrafficTypeLegends: any[] = [];

  public dashboardPerformanceAdFormatSeries: any[] = [];
  public dashboardPerformanceAdFormatLimitedSeries: any[] = [];
  public dashboardPerformanceAdFormatChartOptions = null;
  public dashboardPerformanceAdFormatLegends: any[] = [];

  public dashboardPerformanceOsSeries: any[] = [];
  public dashboardPerformanceOsLimitedSeries: any[] = [];
  public dashboardPerformanceOsChartOptions = null;
  public dashboardPerformanceOsLegends: any[] = [];

  public dashboardPerformanceGeoSeries: any[] = [];
  public dashboardPerformanceGeoLimitedSeries: any[] = [];
  public dashboardPerformanceGeoChartOptions = null;
  public dashboardPerformanceGeoLegends: any[] = [];

  public set sspPerformanceSpinnerStatus(value: boolean) {
    this._sspPerformanceSpinnerStatus = value;
  }

  public get sspPerformanceSpinnerStatus(): boolean {
    return this._sspPerformanceSpinnerStatus;
  }

  public set dspPerformanceSpinnerStatus(value: boolean) {
    this._dspPerformanceSpinnerStatus = value;
  }

  public get dspPerformanceSpinnerStatus(): boolean {
    return this._dspPerformanceSpinnerStatus;
  }

  public set totalPerformanceSpinnerStatus(value: boolean) {
    this._totalPerformanceSpinnerStatus = value;
  }

  public get totalPerformanceSpinnerStatus(): boolean {
    return this._totalPerformanceSpinnerStatus;
  }

  public set trafficTypePerformanceSpinnerStatus(value: boolean) {
    this._trafficTypePerformanceSpinnerStatus = value;
  }

  public get trafficTypePerformanceSpinnerStatus(): boolean {
    return this._trafficTypePerformanceSpinnerStatus;
  }

  public set adFormatPerformanceSpinnerStatus(value: boolean) {
    this._adFormatPerformanceSpinnerStatus = value;
  }

  public get adFormatPerformanceSpinnerStatus(): boolean {
    return this._adFormatPerformanceSpinnerStatus;
  }

  public set osPerformanceSpinnerStatus(value: boolean) {
    this._osPerformanceSpinnerStatus = value;
  }

  public get osPerformanceSpinnerStatus(): boolean {
    return this._osPerformanceSpinnerStatus;
  }

  public set geoPerformanceSpinnerStatus(value: boolean) {
    this._geoPerformanceSpinnerStatus = value;
  }

  public get geoPerformanceSpinnerStatus(): boolean {
    return this._geoPerformanceSpinnerStatus;
  }

  private _sspPerformanceSpinnerStatus = false;
  private _dspPerformanceSpinnerStatus = false;
  private _totalPerformanceSpinnerStatus = false;
  private _adFormatPerformanceSpinnerStatus = false;
  private _trafficTypePerformanceSpinnerStatus = false;
  private _osPerformanceSpinnerStatus = false;
  private _geoPerformanceSpinnerStatus = false;

  private apiUrl = '';

  constructor(
    private http: HttpClient,
    private options: AppOptionsService,
    private errorsHandlingService: ErrorsHandlingService,
    private loadingService: NgxSpinnerService,
  ) {
    this.apiUrl = this.options.getApiUrl();
  }

  public generatePerformanceDashboardHandler(reqData: DashboardsInterface.IPerformanceChartReqData): void {
    this.nullificationPerformanceDashboard();
    this.getAllPerformanceCharts(reqData);
  }

  private getAllPerformanceCharts(reqData: DashboardsInterface.IPerformanceChartReqData): void {
    this.getDashboardPErformanceSSPChart(reqData);
    this.getDashboardPErformanceDSPChart(reqData);
    this.getPerformanceTotalProfit(reqData);
    this.getDashboardPErformanceTrafficTypeChart(reqData);
    this.getDashboardPErformanceAdFormatChart(reqData);
    this.getDashboardPErformanceOsChart(reqData);
    this.getDashboardPErformanceGeoChart(reqData);
  }

  private nullificationPerformanceDashboard(): void {
    this.dashboardPerformanceTableDataSSP = [];
    this.dashboardPerformanceTableDataDSP = [];
    this.dashboardPerformanceTotalProfitTableData = [];
    this.dashboardPerformanceSspSeries = [];
    this.dashboardPerformanceDspSeries = [];
    this.dashboardPerformanceTrafficTypeSeries = [];
    this.dashboardPerformanceAdFormatSeries = [];
    this.dashboardPerformanceOsSeries = [];
    this.dashboardPerformanceGeoSeries = [];
  }

  private getColorByIndex(index: number): string {
    const colorsArray = Array.from(generatedColors);
    return colorsArray[index];
  }

  private getDashboardPErformanceSSPChart(reqData: DashboardsInterface.IPerformanceChartReqData): void {
    this.loadingService.show('ssp');
    this.getSpinnerStatus('ssp', 'sspPerformanceSpinnerStatus');
    this.getPerformanceChart(filterNormalize({ ...reqData, type: 'ssp' }))
      .pipe(
        finalize(() => this.loadingService.hide('ssp'))
      )
      .subscribe((res) => {
        this.dashboardPerformanceTableDataSSP = res.data;
        const sspChart = this.transformToPieChart(res.data);
        this.dashboardPerformanceSspSeries = sspChart.transformedData;
        this.dashboardPerformanceSspLimitedSeries = JSON.parse(JSON.stringify(sspChart.transformedData));
        this.dashboardPerformanceSspChartOptions = sspChart.chartOptions;
        this.dashboardPerformanceSspLegends = sspChart.series;
      },
        error => this.errorsHandlingService.handleError(error));
  }

  private getDashboardPErformanceDSPChart(reqData: DashboardsInterface.IPerformanceChartReqData): void {
    this.loadingService.show('dsp');
    this.getSpinnerStatus('dsp', 'dspPerformanceSpinnerStatus');
    this.getPerformanceChart(filterNormalize({ ...reqData, type: 'dsp' }))
      .pipe(
        finalize(() => this.loadingService.hide('dsp'))
      )
      .subscribe((res) => {
        this.dashboardPerformanceTableDataDSP = res.data;
        const dspChart = this.transformToPieChart(res.data);
        this.dashboardPerformanceDspSeries = dspChart.transformedData;
        this.dashboardPerformanceDspLimitedSeries = JSON.parse(JSON.stringify(dspChart.transformedData));
        this.dashboardPerformanceDspChartOptions = dspChart.chartOptions;
        this.dashboardPerformanceDspLegends = dspChart.series;
      },
        error => this.errorsHandlingService.handleError(error));
  }

  private getPerformanceTotalProfit(reqData: DashboardsInterface.IPerformanceChartReqData): void {
    this.loadingService.show('ssp_dsp');
    this.getSpinnerStatus('ssp_dsp', 'totalPerformanceSpinnerStatus');
    this.getPerformanceChart(filterNormalize({ ...reqData, type: 'ssp_dsp' }))
      .pipe(
        finalize(() => this.loadingService.hide('ssp_dsp'))
      )
      .subscribe((res) => {
        this.dashboardPerformanceTotalProfitTableData = res.data;
      },
        error => this.errorsHandlingService.handleError(error));
  }

  private getDashboardPErformanceTrafficTypeChart(reqData: DashboardsInterface.IPerformanceChartReqData): void {
    this.loadingService.show('trafficType');
    this.getSpinnerStatus('trafficType', 'trafficTypePerformanceSpinnerStatus');
    this.getPerformanceChart(filterNormalize({ ...reqData, type: 'traffic_type' }))
      .pipe(
        finalize(() => this.loadingService.hide('trafficType'))
      )
      .subscribe((res) => {
        const trafficTypeChart = this.transformToPieChart(res.data);
        this.dashboardPerformanceTrafficTypeSeries = trafficTypeChart.transformedData;
        this.dashboardPerformanceTrafficTypeLimitedSeries = JSON.parse(JSON.stringify(trafficTypeChart.transformedData));
        this.dashboardPerformanceTrafficTypeChartOptions = trafficTypeChart.chartOptions;
        this.dashboardPerformanceTrafficTypeLegends = trafficTypeChart.series;
      },
        error => this.errorsHandlingService.handleError(error));
  }

  private getDashboardPErformanceAdFormatChart(reqData: DashboardsInterface.IPerformanceChartReqData): void {
    this.loadingService.show('ad_format');
    this.getSpinnerStatus('ad_format', 'adFormatPerformanceSpinnerStatus');
    this.getPerformanceChart(filterNormalize({ ...reqData, type: 'ad_format' }))
      .pipe(
        finalize(() => this.loadingService.hide('ad_format'))
      )
      .subscribe((res) => {
        const adFormatChart = this.transformToPieChart(res.data);
        this.dashboardPerformanceAdFormatSeries = adFormatChart.transformedData;
        this.dashboardPerformanceAdFormatLimitedSeries = JSON.parse(JSON.stringify(adFormatChart.transformedData));
        this.dashboardPerformanceAdFormatChartOptions = adFormatChart.chartOptions;
        this.dashboardPerformanceAdFormatLegends = adFormatChart.series;
      },
        error => this.errorsHandlingService.handleError(error));
  }

  private getDashboardPErformanceOsChart(reqData: DashboardsInterface.IPerformanceChartReqData): void {
    this.loadingService.show('os');
    this.getSpinnerStatus('os', 'osPerformanceSpinnerStatus');
    this.getPerformanceChart(filterNormalize({ ...reqData, type: 'os' }))
      .pipe(
        finalize(() => this.loadingService.hide('os'))
      )
      .subscribe((res) => {
        const osChart = this.transformToPieChart(res.data);
        this.dashboardPerformanceOsSeries = osChart.transformedData;
        this.dashboardPerformanceOsLimitedSeries = JSON.parse(JSON.stringify(osChart.transformedData));
        this.dashboardPerformanceOsChartOptions = osChart.chartOptions;
        this.dashboardPerformanceOsLegends = osChart.series;
      },
        error => this.errorsHandlingService.handleError(error));
  }

  private getDashboardPErformanceGeoChart(reqData: DashboardsInterface.IPerformanceChartReqData): void {
    this.loadingService.show('geo');
    this.getSpinnerStatus('geo', 'geoPerformanceSpinnerStatus');
    this.getPerformanceChart(filterNormalize({ ...reqData, type: 'geo' }))
      .pipe(
        finalize(() => this.loadingService.hide('geo'))
      )
      .subscribe((res) => {
        const geoChart = this.transformToPieChart(res.data);
        this.dashboardPerformanceGeoSeries = geoChart.transformedData;
        this.dashboardPerformanceGeoLimitedSeries = JSON.parse(JSON.stringify(geoChart.transformedData));
        this.dashboardPerformanceGeoChartOptions = geoChart.chartOptions;
        this.dashboardPerformanceGeoLegends = geoChart.series;
      },
        error => this.errorsHandlingService.handleError(error));
  }

  private transformToPieChart(chart: {
    name: string;
    value: any;
    color: string;
    enabled: boolean;
    endpoint?: string;
  }[]) {
    const sortedChart = [...chart].sort((a, b) => b.value - a.value);
    const dynamicLabels = sortedChart.map(item => item['name']);

    const transformedData = sortedChart.map(item => item['value']);

    const transformedPercentageData = calculatePercentages(transformedData);

    const top5Values = transformedPercentageData.slice(0, 5);

    const chartOptions: Partial<DashboardsInterface.ChartOptions> = {
      chart: {
        type: 'donut',
        width: 450,
      },
      labels: dynamicLabels,
      tooltip: {
        enabled: true,
        y: {
          formatter: function (val) {
            if (val > 1000) {
              return `${(val / 1000).toFixed(2)} K$`;
            }
            return `${val.toFixed(2).toString()}$`;
          }
        }
      },
      responsive: [
        {
          breakpoint: 180,
          options: {
            chart: {
              width: 400
            },
            dataLabels: {
              enabled: true,
            },
          }
        }
      ],
      legend: {
        show: false,
      },
      dataLabels: {
        formatter: function (labelValue: number, opts) {
          const formattedVal = Math.round(labelValue);
          const label = top5Values.find((top5Value, idx) => top5Value === formattedVal && opts.seriesIndex === idx);
          if (label) {
            return `${label}%`;
          } else {
            return '';
          }
        },
      },
      plotOptions: {
        pie: {
          dataLabels: {
            minAngleToShowLabel: 0
          }
        }
      },
      colors: Array.from(generatedColors).map(color => color),
    };

    const newSortedChat = JSON.parse(JSON.stringify(sortedChart));

    const sortedSeries = [...newSortedChat].map((item, idx) => {
      item.color = chartOptions.colors[idx];
      item.enabled = true;
      return item;
    });

    let series = chart.map((item, idx) => {
      item.color = this.getColorByIndex(idx);
      item.enabled = true;
      return item;
    });

    series = updateSeriesArray([...sortedSeries], [...series], 'name');

    return {
      series,
      chartOptions,
      transformedData
    };
  }

  public getSpinnerStatus(spinnerName: string, name: string): void {
    this.loadingService.getSpinner(spinnerName).subscribe(res => {
      this[name] = res.show;
    });
  }

  private getPerformanceChart(filter: DashboardsInterface.IPerformanceChartReqData): Observable<DashboardsInterface.IPieChartRes> {
    return this.http.post<DashboardsInterface.IPieChartRes>(`${this.apiUrl}/analytics-dashboards/profit`, { filter: filter })
      .pipe(catchError(this.errorsHandlingService.processError));
  }
}
