<div>
  <div class="custom-select" [class.disabled]="disabled" [class.focus]="opened" [class.multiple]="multiple"
    (click)="toggleState($event)">
    <div class="custom-select__value">
      <span>
        <ng-container *ngIf="!multiple && value !== undefined">{{ getSelectedItemLabel() }}</ng-container>
      </span>
      <img src="assets/img/icon_down.svg" alt="icon-down">
    </div>
    <div class="search-container" *ngIf="!multiple">
      <input type="text" #search autocomplete="asdasdadadadcvsc313123" [(ngModel)]="filter"
        class="custom-select__search" (click)="searchClicked($event)" (keypress)="handleEnter($event)">
      <i [class]="icon"></i>
    </div>
    <div class="custom-select__dropdown">
      <div class="custom-select__dropdown__search" *ngIf="multiple && hasSearch">
        <div class="shadow-overlay"></div>
        <!-- kill stupid chrome autocomplete -->
        <input type="text" #search autocomplete="asdasdadadadcvsc313123" [(ngModel)]="filter"
          (click)="searchClicked($event)" class="custom-select__search custom-select__search_multiple"
          (keypress)="handleEnter($event)">
      </div>
      <div class="custom-select__items">
        <div class="item" *ngFor="let item of items | selectSearch : filter; let i = index"
          (click)="selectItem($event,item)">
          <div nz-tooltip nzTooltipOverlayClassName="item-tooltip" [nzTooltipTitle]="item.tooltip"
            nzTooltipPlacement="top">{{item.name}}</div>
          <img *ngIf="item.checked" src="assets/img/ic_done.svg" alt="">
        </div>
        <ng-container *ngIf="!(items | selectSearch : filter).length">
          <div class="item custom" (click)="addCustom(); $event.preventDefault(); $event.stopPropagation();">
            No results
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>