import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AppStateService {

  constructor() { }

  isRefresh = false;
  refreshApiLinks$ = new BehaviorSubject(this.isRefresh);

  set isRefreshApilinks(val: boolean) {
    this.isRefresh = val;
  }

  updateRefreshApiLinks() {
    this.refreshApiLinks$.next(this.isRefresh);
  }
}
