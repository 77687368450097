import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzButtonModule } from 'ng-zorro-antd/button';

import { ChangeCategoryDialogComponent } from './change-category-dialog.component';

@NgModule({
  declarations: [ChangeCategoryDialogComponent],
  exports: [ChangeCategoryDialogComponent],
  imports: [
    CommonModule,
    FormsModule,
    NzModalModule,
    NzButtonModule
  ]
})
export class ChangeCategoryDialogModule { }
