import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from '../../services';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  constructor(public auth: AuthService, public router: Router) { }
  canActivate(): boolean | void {
    if (!this.auth.isAuthenticated()) {
      this.auth.logout();
      this.router.navigate(['login']);
      return false;
    }

    return true;
  }
}
