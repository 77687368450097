export interface IAlert {
  id: number;
  name: string;
  is_read: number;
  date: string;
  type: string;
}

export interface IAlertRes {
  data: IAlert[];
  totals: number;
  firstAlert: IAlert;
}

export interface IAlertFilter {
  from: string;
  to: string;
  type: string;
  is_read: boolean;
  page: number;
  per_page: number;
}

export interface IAlertType {
  id: string | number;
  name: string;
}