export enum LabelRanges {
  today = 'Today',
  yesterday = 'Yesterday',
  last_7_days = 'Last 7 Days',
  last_30_days = 'Last 30 Days',
  this_month = 'This Month',
  last_month = 'Last Month',
}

export enum DatePickerRanges {
  today = 'today',
  yesterday = 'yesterday',
  last_7_days = 'last_7_days',
  last_30_days = 'last_30_days',
  this_month = 'this_month',
  last_month = 'last_month',
}
