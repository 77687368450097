import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { UserRole } from '../../enum';
import { AuthService } from '../../services';

@Injectable({
  providedIn: 'root',
})
export class AdminGuard {
  constructor(public auth: AuthService, public router: Router) {
  }

  canActivate(): boolean {
    if (this.auth.userRole !== UserRole.support) {
      void this.router.navigate(['']);

      return false;
    }

    return true;
  }
}
