import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[numericInput]',
})
export class NumericInputDirective {
  @Input('decimals') decimals = 0;

  private check(value: string, decimals: number) {
    if (decimals <= 0) {
      return new RegExp(/^\d+$/).exec(String(value));
    } else {
      const regExpString = '^\\s*((\\d+(\\.\\d{0,' + decimals + '})?)|((\\d*(\\.\\d{1,' + decimals + '}))))\\s*$';
      return new RegExp(regExpString).exec(String(value));
    }
  }

  private specialKeys = [
    'Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Delete',
  ];

  constructor(private el: ElementRef) {
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }
    const current: string = this.el.nativeElement.value;
    const next: string = current.concat(event.key);
    if (next && !this.check(next, this.decimals)) {
      event.preventDefault();
    }
  }
}
