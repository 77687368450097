import { Component, Inject } from '@angular/core';
import { NZ_MODAL_DATA, NzModalRef } from 'ng-zorro-antd/modal';

interface DeleteDialog {
  title: string,
  message: string,
  buttonSaveText: string;
  buttonCancelText: string;
  isWarningMessage?: boolean,
  endpointsLength?: number
  warningMessage?: string,
  fontWeight?: string,
}

@Component({
  selector: 'app-delete-dialog',
  templateUrl: './delete-dialog.component.html',
  styleUrls: ['./delete-dialog.component.scss']
})
export class DeleteDialogComponent {

  constructor(
    @Inject(NZ_MODAL_DATA) public data: DeleteDialog,
    private dialogRef: NzModalRef<DeleteDialogComponent>
  ) { }

  onCancel(): void {
    this.dialogRef.close(false);
  }

  onConfirm(): void {
    this.dialogRef.close(true);
  }
}
