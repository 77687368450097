import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';

import { ColumnBuilderComponent } from './column-builder.component';

@NgModule({
  declarations: [
    ColumnBuilderComponent
  ],
  exports: [ColumnBuilderComponent],
  imports: [
    CommonModule,
    NzCheckboxModule,
    FormsModule
  ]
})
export class ColumnBuilderModule { }
