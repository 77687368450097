export const statistic = {
  adFormat: {
    today: {
      unknown: {
        revenue: 0,
        percent: 0,
      },
      banner: {
        revenue: 0,
        percent: 0,
      },
      video: {
        revenue: 0,
        percent: 0,
      },
      native: {
        revenue: 0,
        percent: 0,
      },
      pop: {
        revenue: 0,
        percent: 0,
      },
      push: {
        revenue: 0,
        percent: 0,
      },
      audio: {
        revenue: 0,
        percent: 0,
      },
      rewarded: {
        revenue: 0,
        percent: 0,
      },
      total: 0,
    },
    yesterday: {
      unknown: {
        revenue: 0,
        percent: 0,
      },
      banner: {
        revenue: 0,
        percent: 0,
      },
      video: {
        revenue: 0,
        percent: 0,
      },
      native: {
        revenue: 0,
        percent: 0,
      },
      pop: {
        revenue: 0,
        percent: 0,
      },
      push: {
        revenue: 0,
        percent: 0,
      },
      audio: {
        revenue: 0,
        percent: 0,
      },
      rewarded: {
        revenue: 0,
        percent: 0,
      },
      total: 0,
    },
  },
  trafficType: {
    today: {
      unknown: {
        revenue: 0,
        percent: 0,
      },
      web: {
        revenue: 0,
        percent: 0,
      },
      mobile: {
        revenue: 0,
        percent: 0,
      },
      mobileWeb: {
        revenue: 0,
        percent: 0,
      },
      ctv: {
        revenue: 0,
        percent: 0,
      },
      total: 0,
    },
    yesterday: {
      unknown: {
        revenue: 0,
        percent: 0,
      },
      web: {
        revenue: 0,
        percent: 0,
      },
      mobile: {
        revenue: 0,
        percent: 0,
      },
      mobileWeb: {
        revenue: 0,
        percent: 0,
      },
      ctv: {
        revenue: 0,
        percent: 0,
      },
      total: 0,
    },
  }
};
