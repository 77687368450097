import { User, FilterListInterface, TableDataResponse, AdminsInterface } from '..';

export interface IDataTableConfig {
  columns: ITableColumn[];
  rowActions: ITableAction[];
  type: string;
}

export interface ITableColumn {
  label: string;
  rowProperty: string;
  sortable: boolean;
  width: string;
  hasFunc?: boolean;
  columnFn?: (item: any) => any;
}

export interface ITableAction {
  rowName: string;
  rowFn?: (item: any) => any;
  getClass?: (item: any) => string;
  checkPartnerId?: (partnerId: number) => boolean;
  hasFunc: boolean;
  hasControl: boolean;
  pipe?: string;
  width: string;
  action: {
    actionType: string;
    actionFn?: (item: any) => any;
    label: string;
    element: string;
    iconName: string;
    placeholder?: string;
    selectItems?: {
      id: string | boolean;
      name: string;
    }[]
  }[]
}

export interface IRowNames {
  financial_name: string;
  incomingQPS: number;
  outgoingQPS: number;
}

export type ValidRowNames = 'financial_name' | 'incomingQPS' | 'outgoingQPS' | 'name';

export type ITableItem = FilterListInterface.FilterList | TableDataResponse | AdminsInterface.IAdminPartnerData | User;
