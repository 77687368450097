export interface MainSspCompany {
  id: number;
  name: string;
  active: number;
  qps: number;
  bidqps: number;
  dailySpend: number;
  spendLimit: number;
  defaultTmax: number;
  defaultBidfloor: number;
  comments?: string;
}

export interface MainDspCompany {
  id: number;
  name: string;
  active: number;
  banner: number;
  native: number;
  video: number;
  nurl: number;
  bidQPS: number;
  dailySpend: number;
  limitQPS: number;
  realQPS: number;
  spendLimit: number;
}

export interface ResponseForCompaniesMainTable {
  current_page: number;
  data: CompaniesDataForMainTable[];
  from: number;
  last_page: number;
  per_page: number;
  to: number;
  total: number;
}

export interface ResponseForEndpointsMainTable {
  data: EndpointsDataForMainTable[];
  last_page: number;
  per_page: number;
  status: string;
  total: number;
  current_page: number;
}

export interface CompaniesDataForMainTable {
  active: number | boolean;
  bidqps: number;
  dailySpend: string;
  daylySpend: string;
  defaultBidfloor: number;
  defaultTmax: string;
  fill_rate: string;
  id: number;
  name: string;
  incoming_qps: number;
  qps: number;
  ssp_rcpm: number;
  dsp_rcpm: number;
  render_rate: string;
  spendLimit: string;
  valid_bid_rate: string;
  win_rate: string;
  limitQPS: number;
  realQPS: number;
  bidQPS: number;
  total_bid_rate: number;
  ssp_settings?: EndpointsDataForMainTable[];
  dsp_settings?: EndpointsDataForMainTable[];
}

export interface EndpointsDataForMainTable {
  active: number;
  allowedDspListTrigger: number;
  apiLink: string;
  bidqps: number;
  comments: string;
  companyId: number;
  connectionType: number;
  cookieSync: {
    dsp: any;
    endpointId: number;
    matchedUsersOnly: any;
  };
  cpc: any;
  createdAt: string;
  ctv: number;
  dailySpend: number;
  dataStructureParser: string;
  daylySpend: number;
  defaultBidfloor: number;
  defaultTmax: number;
  deletedAt: string;
  desktop: number;
  email: string;
  endpoint: string;
  externalReportPattern: string;
  externalReportPatternId: number;
  fill_rate: number;
  fixedPrice: number;
  formats: {
    audio: number;
    banner: number;
    native: number;
    pop: number;
    push: number;
    video: number;
  };
  geoedge_key: string;
  gzipResponses: number;
  id: number;
  isAudio: number;
  isBanner: number;
  isForTest: number;
  isNative: number;
  isPop: number;
  isPush: number;
  isSecure: number;
  isVideo: number;
  is_pixalate_postbid: number;
  margin: number;
  margin_type: string;
  min_margin: number;
  max_margin: number;
  min_bidfloor: number;
  mobile_app: number;
  mobile_web: number;
  name: string;
  partnerId: number;
  pmIsFraud: number;
  popRespXml: number;
  priceFromNurl: number;
  incoming_qps: number;
  qps: number;
  ssp_rcpm: number;
  dsp_rcpm: number;
  region: string;
  render_rate: number;
  rewarded: number;
  seat: string;
  secretKey: string;
  spendLimit: number;
  totalSpend: string;
  totalSpendLimit: number;
  updatedAt: string;
  useBcatFilter: number;
  useBotman: number;
  useForensiq: number;
  useGeoedge: number;
  usePMKey: number;
  usePMPrebid: number;
  useTMT: number;
  useTotalSpendLimit: number;
  valid_bid_rate: number;
  win_rate: number;
  winrate: number;
  limitQPS: number;
  realQPS: number;
  bidQPS: number;
  total_bid_rate: number;
  enable_postbid: number;
  enable_prebid: number;
  nurl: number;
  scanners: string[];
}

export interface ListTypeApiEndpointsMain {
  ssp: {
    'blocked-crids': string;
    'blocked-domains': string;
  };
  dsp: {
    bundles: string;
    sites: string;
    publishers: string;
    'blocked-publishers': string;
    'blocked-bundles': string;
    'blocked-sites': string;
    'blocked-ips': string;
  }
}

export interface ListTypeApiEndpointsBlockedOrAllow {
  ssp: {
    'crid': string;
    'adm_pattern': string;
  };
  dsp: {
    bundle: string;
    sites: string;
    publisher_id: string;
    'blocked-publishers': string;
    'blocked-bundles': string;
    'blocked-sites': string;
    'blocked-ips': string;
  }
}

export interface ListTypeParamBlockedOrAllow {
  ssp: {
    'crid': string;
    'adm_pattern': string;
  };
  dsp: {
    bundle: string;
    sites: string;
    publisher_id: string;
    'blocked-publishers': string;
    'blocked-bundles': string;
    'blocked-sites': string;
    'blocked-ips': string;
  };
}

export type ListTypeMain = { 'blocked-crids': string; 'blocked-domains': string; } | {
  bundles: string;
  sites: string;
  publishers: string;
  'blocked-publishers': string;
  'blocked-bundles': string;
  'blocked-sites': string;
  'blocked-ips': string;
};

export interface ListTypeApiEndpointsDSP {
  dsp: {
    bundles: string;
    sites: string;
    publishers: string;
  };
}

export type ListTypeDSP = { bundles: string; sites: string; publishers: string; };

export interface ListTypeParamMain {
  ssp: {
    'blocked-crids': string;
    'blocked-domains': string;
  };
  dsp: {
    bundles: string;
    sites: string;
    publishers: string;
    'blocked-publishers': string;
    'blocked-bundles': string;
    'blocked-sites': string;
    'blocked-ips': string;
  };
}

export type TypeParamMain = { 'blocked-crids': string; 'blocked-domains': string; } | {
  bundles: string;
  sites: string;
  publishers: string;
  'blocked-publishers': string;
  'blocked-bundles': string;
  'blocked-sites': string;
  'blocked-ips': string;
};

export type AppOptionsMainType = string |
{ attr: string; label: string; tooltip: string; active: boolean; } |
{ attr: string; label: string; tooltip: string; active: boolean; };

export type MainFilters = { dsp: object; ssp: object; };

export type CompanyIds = {
  filters: object;
  ad_format: string;
  search: never;
  activity: never;
  connectionType: never;
  sortBy: never;
  sortOrder: never;
  currentPage: number;
};

export type SspDspCompanies = { ssp: object, dsp: object };

// export type IndexSspDspCompanies = {  };
