<div class="bid-example">
  <h3>Warning</h3>
  <p *ngIf="data.type === 'company'" class="text-center">
    Are you sure you want to delete {{data.entity}} <b>{{data.name}}</b>?<br>
    This operation can't be undone. Please note that once you delete the company, the information about this
    company will be deleted from Sellers.json file as well.
  </p>
  <p *ngIf="data.type !== 'company'" class="text-center">
    Are you sure you want to delete {{data.entity}} <b>{{data.name}}</b>?<br>
    This operation can't be undone
  </p>
  <div *nzModalFooter class="button-footer">
    <button nz-button nzType="default" nzDanger (click)="confirmClicked(true)">Delete</button>
    <button nz-button nzType="default" class="right" (click)="closeDialog(false)">Cancel</button>
  </div>
</div>