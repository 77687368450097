export interface LoggerFilters {
  adFormats: TrafficTypesLoggers[];
  dspConnectionTypes: ConnectionTypes[];
  sspConnectionTypes: ConnectionTypes[];
  logEvents: LogEvents[];
  trafficTypes: TrafficTypesLoggers[];
}

export interface FilterState {
  content: number | string;
  event_id: number | string[] | number[];
  ssp_id: string | string[] | number[];
  dsp_id: string | string[] | number[];
  ad_format_id: number | string[] | number[];
  traffic_id: number | string[] | number[];
  ssp_endpoint_type: number | string[] | number[];
  dsp_endpoint_type: number | string[] | number[];
}

export interface SortStateLogger {
  sortBy: string;
  sortOrder: number | string;
}

export interface TrafficTypesLoggers {
  id: number;
  name: string;
}

export interface AdFormatsLoggers {
  id: number;
  name: string;
}

export interface ConnectionTypes {
  id: number;
  name: string;
}

export interface LogEvents {
  id: number;
  name: string;
}

export interface EndpointsLoggers {
  id: string;
  name: string;
}

export interface TableData {
  rows: TableRows[];
  columns: TableColumns[];
}


export interface TableColumns {
  key: string;
  value: string;
}

export interface TableFields {
  attr: string;
  label: string;
  active: boolean;
  disabled: boolean;
}

export interface TableRows {
  log_time: string;
  ssp_name: number;
  dsp_name: number;
  log_event: string;
  ssp_request: string;
  dsp_request: string;
  ssp_response: string;
  dsp_response: string;
}

export interface TableResponse {
  count: number;
  data: TableDataResponse[];
  pageCount: number;
  perPage: number;
}

export interface TableDataResponse {
  ad_format_id: number;
  dsp_endpoint_type: string;
  dsp_id: string;
  dsp_name: string;
  dsp_request: string;
  dsp_response: string;
  event_id: number;
  log_event: string;
  log_time: string;
  ssp_endpoint_type: string;
  ssp_id: string;
  ssp_name: string;
  ssp_request: string;
  ssp_response: string;
  traffic_id: number;
  ad_request: string;
}
