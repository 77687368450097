import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'bigNumber',
})
export class BigNumberPipe implements PipeTransform {

  transform(value: any): any {
    if (!value || Number.isNaN(parseInt(value)) || value < 1000)
      return value;

    const k = value / 1000;
    let suffix = 'K';
    if (k < 1000) {
      return Math.floor(k) + suffix;
    }
    suffix = 'M';
    return Math.floor(k / 1000) + suffix;

  }

}
