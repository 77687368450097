export interface Billing {
  id: number;
  name: string;
  date: string;
  imps: number;
  spend: number;
  cost: number;
  impsExt: number;
  costExt: number;
  impsDiscrep: number;
  costDiscrep: number;
  isEditedImps: boolean;
  isEditedCost: boolean;
  hasApiLink: boolean;
  reasons: object;
  expand?: boolean;
}

export interface DataRevShare {
  dspSpend?: number;
  profit?: number;
  revshare_percent?: number;
  revshare_value?: number;
  sspSpend?: number;
}
export class BillingData implements Billing {
  children: Billing[] = [];
  constructor(
    public id,
    public name,
    public date,
    public imps,
    public cost,
    public spend,
    public terms: number,
    public impsExt: number,
    public costExt: number,
    public impsDiscrep: number,
    public costDiscrep: number,
    public isEditedImps: boolean,
    public isEditedCost: boolean,
    public hasApiLink: boolean,
    public reasons: { key: string },
    public companyName?: string,
    public expand?: boolean,
  ) { }
}

export interface BillingTableEndpointsData {
  companyId: number;
  companyName: string;
  costDiscrep: number;
  costExt: number;
  hasApiLink: boolean;
  id: number;
  imps: string;
  impsDiscrep: number;
  impsExt: number;
  name: string;
  reasons: any[];
  spend: number;
  sspId: number;
  terms: number;
  expand: boolean;
}

export interface BillingTableCompaniesData {
  companyId: number;
  companyName: string;
  costExt: number;
  imps: number;
  impsExt: number;
  reasons: any[];
  spend: number;
  terms: number;
  children: BillingTableEndpointsData[];
  expand: boolean;
}

export interface BillingResponse {
  totals?: {
    imps: string;
    name: string;
    spend: number;
    sspId: number;
  };
  data?: BillingTableCompaniesData[]
  dspSpend?: number;
  profit?: number;
  revshare_percent?: number;
  revshare_value?: number;
  sspSpend?: number;
}