import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';

import {
  ApexAxisChartSeries,
  ApexChart,
  ApexXAxis,
  ApexDataLabels,
  ApexTooltip,
  ApexFill,
  ApexLegend,
  ApexYAxis,
  ApexStroke,
  ApexTitleSubtitle,
  ApexAnnotations,
  ApexPlotOptions
} from 'ng-apexcharts';

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  dataLabels: ApexDataLabels;
  tooltip: ApexTooltip;
  fill: ApexFill;
  legend: ApexLegend;
  yaxis: ApexYAxis;
  stroke: ApexStroke;
  title: ApexTitleSubtitle;
  annotations: ApexAnnotations;
  plotOptions: ApexPlotOptions;
  colors: string[];
};

@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChartComponent implements OnChanges {

  @ViewChild('chart') chart: any;

  @Input() public set series(value: ApexAxisChartSeries) {
    this._series = value;
  }

  public get series(): ApexAxisChartSeries {
    return this._series;
  }

  @Input() public set maxValue(value: number) {
    this._maxValue = value;
  }

  public get maxValue(): number {
    return this._maxValue;
  }

  @Input() public set xAxisTickAmount(value: number) {
    this._xAxisTickAmount = value;
  }

  public get xAxisTickAmount(): number {
    return this._xAxisTickAmount;
  }

  @Input() public set qpsPlan(value: number) {
    this._qpsPlan = value;
  }

  public get qpsPlan(): number {
    return this._qpsPlan;
  }

  @Input() public set labels(value: string[]) {
    this._labels = value;
  }

  public get labels(): string[] {
    return this._labels;
  }

  @Input() public set chartOptions(value: Partial<ChartOptions>) {
    this._chartOptions = value;
  }

  public get chartOptions(): Partial<ChartOptions> {
    return this._chartOptions;
  }

  annotations: ApexAnnotations | null = null;

  private _chartOptions: Partial<ChartOptions>;
  private _series: ApexAxisChartSeries = [];
  private _maxValue: number;
  private _xAxisTickAmount: number;
  private _qpsPlan: number;
  private _labels: string[];

  constructor() { }


  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      if (this.chartOptions && this.chartOptions.annotations && this.chartOptions.annotations.yaxis && this.chartOptions.annotations.yaxis[0]) {
        this.annotations = {
          yaxis: [{
            ...this.chartOptions.annotations.yaxis[0],
            y: this.qpsPlan,
          }]
        };
      }
      this.chartOptions = {
        ...this.chartOptions,
        yaxis: {
          ...this.chartOptions.yaxis,
          // max: this.maxValue
        },
        xaxis: {
          ...this.chartOptions.xaxis,
          tickAmount: changes.xAxisTickAmount?.currentValue,
        },
        annotations: this.annotations
      };
    }
  }

}
