import { Component, Inject, OnInit } from '@angular/core';
import { NZ_MODAL_DATA, NzModalRef } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-change-category-dialog',
  templateUrl: './change-category-dialog.component.html',
  styleUrls: ['./change-category-dialog.component.scss']
})
export class ChangeCategoryDialogComponent implements OnInit {
  categoryType: string;
  isChangeCategory = false;

  constructor(
    @Inject(NZ_MODAL_DATA) public data: { type: string },
    private dialogRef: NzModalRef<ChangeCategoryDialogComponent>,
  ) {}

  ngOnInit(): void {
    this.categoryType = this.data.type;
  }

  onCloseDialog(): void {
    this.isChangeCategory = false;
    this.dialogRef.close(this.isChangeCategory);
  }

  resetCategory(): void {
    this.isChangeCategory = true;
    this.dialogRef.close(this.isChangeCategory);
  }
}
